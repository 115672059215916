import {createPortal} from "react-dom";
import React, {Fragment} from "react";
import invariant from 'invariant';

var isChrome = /chrome/gi.test(window.navigator.userAgent);

function getRangeClientRectsChrome(range) {
    var tempRange = range.cloneRange();
    var clientRects = [];

    for (var ancestor = range.endContainer; ancestor != null; ancestor = ancestor.parentNode) {
        // If we've climbed up to the common ancestor, we can now use the
        // original start point and stop climbing the tree.
        var atCommonAncestor = ancestor === range.commonAncestorContainer;
        if (atCommonAncestor) {
            tempRange.setStart(range.startContainer, range.startOffset);
        } else {
            tempRange.setStart(tempRange.endContainer, 0);
        }
        var rects = Array.from(tempRange.getClientRects());
        clientRects.push(rects);
        if (atCommonAncestor) {
            var _ref;

            clientRects.reverse();
            return (_ref = []).concat.apply(_ref, clientRects);
        }
        tempRange.setEndBefore(ancestor);
    }

    invariant(false, "Found an unexpected detached subtree when getting range client rects.");
}

var getRangeClientRects = isChrome ? getRangeClientRectsChrome : function (range) {
    return Array.from(range.getClientRects());
};



function getRangeBoundingClientRect(range) {
    // "Return a DOMRect object describing the smallest rectangle that includes
    // the first rectangle in list and all of the remaining rectangles of which
    // the height or width is not zero."
    // http://www.w3.org/TR/cssom-view/#dom-range-getboundingclientrect
    var rects = getRangeClientRects(range);
    var top = 0;
    var right = 0;
    var bottom = 0;
    var left = 0;

    if (rects.length) {
        // If the first rectangle has 0 width, we use the second, this is needed
        // because Chrome renders a 0 width rectangle when the selection contains
        // a line break.
        if (rects.length > 1 && rects[0].width === 0) {
            var _rects$ = rects[1];
            top = _rects$.top;
            right = _rects$.right;
            bottom = _rects$.bottom;
            left = _rects$.left;
        } else {
            var _rects$2 = rects[0];
            top = _rects$2.top;
            right = _rects$2.right;
            bottom = _rects$2.bottom;
            left = _rects$2.left;
        }

        for (var ii = 1; ii < rects.length; ii++) {
            var rect = rects[ii];
            if (rect.height !== 0 && rect.width !== 0) {
                top = Math.min(top, rect.top);
                right = Math.max(right, rect.right);
                bottom = Math.max(bottom, rect.bottom);
                left = Math.min(left, rect.left);
            }
        }
    }

    return {
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        width: right - left,
        height: bottom - top
    };
}

export function getVisibleSelectionRect(global) {
    var selection = global.getSelection();
    if (!selection.rangeCount) {
        return null;
    }

    var range = selection.getRangeAt(0);
    var boundingRect = getRangeBoundingClientRect(range);
    var top = boundingRect.top,
        right = boundingRect.right,
        bottom = boundingRect.bottom,
        left = boundingRect.left;

    // When a re-render leads to a node being removed, the DOM selection will
    // temporarily be placed on an ancestor node, which leads to an invalid
    // bounding rect. Discard this state.

    if (top === 0 && right === 0 && bottom === 0 && left === 0) {
        return null;
    }

    return boundingRect;
}

var objectWithoutProperties = function (obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
};

var wrapPortal = function wrapPortal(Comp) {
    return function (_ref2) {
        var children = _ref2.children,
            props = objectWithoutProperties(_ref2, ["children"]);
        return createPortal(React.createElement(
            Comp,
            props,
            React.createElement(
                Fragment,
                null,
                children
            )
        ), props.containerNode || document.body);
    };
};

export default wrapPortal;