import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'


import {
  auth,
  checkLoggedIn
} from '../redux/actionsAuth'


import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import LoginForm from "../interfaceParts/LoginForm"


class IndexPage extends Component {
  state = {
      username: "",
      password: "",
      email: "",
    }

  
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLoginSubmit = e => {
    e.preventDefault();
    this.props.dispatch(auth (this.state.username, this.state.password))
  };

  componentWillMount() {
    this.props.dispatch(checkLoggedIn())
  }

  render() {
    if (this.props.loggedIn)
      return (<Redirect to={'/projects' + this.props.location.search} />)
    
    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    var contents = <LoginForm registrationRequestSent={this.props.registrationRequestSent} state={this.state} handleLoginSubmit={this.handleLoginSubmit} handleInputChange={this.handleChange} />
    
    return (
      <div className="App">
          
          {error}
          
          { contents }
          
          <LoadingSpinner show={this.props.loading} />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    username: state.authParams.username,
    loggedIn: state.authParams.loggedIn,
    error: state.authParams.error,
    loading: state.globalInterface.loading,
    registrationRequestSent: state.authParams.registrationRequestSent,


  }
}


export default connect(mapStateToProps)(IndexPage)


