import config from 'react-global-configuration'

import {
    setLoading,
    getAccessToken
} from './actions'

import {
    authRefresh,
} from './actionsAuth'

export const CODES_TREE_LOADED = 'CODES_TREE_LOADED'
export const CODE_LOADED = 'CODE_LOADED'
export const SHOW_INDEX = 'SHOW_INDEX'
export const FLAT_INDEX_LOADED = 'FLAT_INDEX_LOADED'
export const NET_INDEX_LOADED = 'NET_INDEX_LOADED'
export const SET_FLAT_INDEX_READ_TYPE = 'SET_FLAT_INDEX_READ_TYPE'


export function onShowCodeViewer (visible) {
    return {
        type: SHOW_INDEX,
        visible
    }
}

export function onCodesTreeLoaded (codesTree) {
    return {
        type: CODES_TREE_LOADED,
        codesTree
    }
}

export function onCodeLoaded (code) {
    return {
        type: CODE_LOADED,
        code
    }
}

export function onFlatIndexLoaded (index) {
    return {
        type: FLAT_INDEX_LOADED,
        index
    }
}

export function setFlatIndexReadType (readType) {
    return {
        type: SET_FLAT_INDEX_READ_TYPE,
        readType
    }
}


export function getFlatIndex (path, readType) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "path": path, "readFilter": readType, 'endpoint': 'getFlatIndex'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "api/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'getFlatIndex', p1:path}))
                return res.json()})
            .then(
                (result) => {
                    console.log (result)
                    dispatch(onFlatIndexLoaded(result.index))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                }
            )
    }
}


export function getCode (ref) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "docID": ref, 'endpoint': 'getCodeByRef'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "api/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'getCode', p1:ref}))
                return res.json()})
            .then(
                (result) => {
                    dispatch(onCodeLoaded(result.code))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                }
            )
    }
}

export function getCodesTree () {
    const conf = {
      method: "get",
      body: JSON.stringify({"endpoint": 'codeTree'}),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return dispatch => {
        dispatch(setLoading(true))
        return fetch(config.get('globalUrl') + "api/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'getCodesTree'}))
                return res.json()})
            .then(
                (result) => {
                    dispatch(onCodesTreeLoaded(result.codeTree))
                    dispatch(setLoading(false))
                },
                (error) => {
                    console.log (error)
                    dispatch(setLoading(false))
                }
            )
    }
}

