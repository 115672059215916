// react
import React, {Component} from "react";

// redux
import {saveComment, saveCommentForNewRange, updateComment} from "../redux/actionsProject";

// styles
import '../css/DocComments.scss';

// app staff
import {DocViewerEventFactory} from "../utils/DocViewerEventFactory";
import ReactDOM from "react-dom";
import {connect} from "react-redux";


class DocCommentForm extends Component {

    state = {
        comment: '',
        notifyTeam: false,
    }

    componentDidMount() {
        if (this.props.text !== undefined && this.props.text !== null) {
            this.setState({
                comment: this.props.text
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.text != null) {
            this.setState({
                comment: nextProps.text
            })
        }
    }

    closeDialog(e) {
        DocViewerEventFactory(this.props.containerRef).createCloseCommentDialogEvent(ReactDOM.findDOMNode(this));
    }

    handleSubmit = e => {
        if (this.state.comment === '') {
            alert("Comment text required.")
            return false
        }

        // update existing highlight comment or annotation
        if (this.props.highlightId !== null && this.props.comment != null) {
            this.props.dispatch(updateComment(this.props.highlightId, this.props.comment.id, this.state.comment, this.props.query, this.state.notifyTeam))
        }
        // save regular comment for existing highlight
        else if ((this.props.highlightId !== null) /*&& (this.props.isAnnotation === undefined || !this.props.isAnnotation)*/) {
            this.props.dispatch(saveComment(this.state.comment, this.props.highlightId, this.props.query, this.state.notifyTeam))
        }
        // create new highlight+comment or annotation
        else {
            var savedDocsIds = []
            if (this.props.savedDocs)
                savedDocsIds = this.props.savedDocs.map(function (d) { return d.id })

            var withSave = 1
            if ((this.props.loadedDoc) && (savedDocsIds.includes(this.props.loadedDoc.id)))
                withSave = 0

            let selectionText;
            if (this.props.isAnnotation !== undefined && this.props.isAnnotation) {
                selectionText = null;
                if (this.props.color === undefined || this.props.color === '') {
                    alert("Error: can not save annotation because it's color is not set.");
                    return ;
                }
            }
            else {
                selectionText = this.props.selectionText;
            }

            // let selectionText = (this.props.isAnnotation === undefined || !this.props.isAnnotation ? this.props.selectionText : "None");
            this.props.dispatch (saveCommentForNewRange (selectionText, this.props.loadedDoc, this.props.projectId, withSave, this.state.comment, this.props.color, this.props.query, this.state.notifyTeam))
        }

        this.setState({comment: ''})
        this.closeDialog(e)
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleCancel = e => {
        this.closeDialog(e)
    }

    render() {
        var that = this
        let saveBtnDisabled = this.state.comment.trim() === "";

        let formClassName = "doc-comment-form";
        if (this.props.isVisible !== undefined && !this.props.isVisible) {
            formClassName += " d-none";
        }
        if (this.props.color !== undefined) {
            formClassName += " color-" + this.props.color;
        }

        return (
            <div className={formClassName}>
                <form method="post" onSubmit={this.handleSubmit}>
                    <div className="field">
                        <div className="control">
                            <textarea
                                name="comment"
                                rows={5}
                                required
                                placeholder="Type your comment..."
                                value={this.state.comment}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <label htmlFor="notifyTeamCB" className="cbcontainer" onClick={(e) => {e.stopPropagation(); that.setState ({notifyTeam:!that.state.notifyTeam}) }}>Notify project members
                                <input type="checkbox" className="" name="notifyTeamCB" checked={that.state.notifyTeam} readOnly={true}></input>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="control actions">
                        <button key="btn-save" type="button" onClick={this.handleSubmit} disabled={saveBtnDisabled} className={saveBtnDisabled ? "disabled" : ""}> SAVE </button>
                        {this.props.hasCancel &&
                            <button key="btn-cancel" type="button" className="lightBtn" onClick={this.handleCancel}> CANCEL </button>
                        }
                    </div>
                </form>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        loadedDoc: state.projects.loadedDoc,
        savedDocs: state.projects.savedDocs,
        query: state.query
    }
}

export default connect(mapStateToProps)(DocCommentForm);
