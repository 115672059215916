import React from 'react';

import Loader from 'react-loader-spinner'
import '../css/LoadingSpinner.scss';

const LoadingSpinner = ({show}) => {
  const componentClasses = ['loadingSpinnerContainer'];
  if (show) { componentClasses.push('show'); }
  
  return (
    <div className={componentClasses.join(' ')}><Loader type="MutatingDots" color="#26458a" secondaryColor="#26458a"/></div>
  );
};


export default LoadingSpinner;
