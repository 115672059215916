import React, { Component } from 'react';

import '../css/LoginForm.scss';
import localConfig from '../config/config'


class LoginForm extends Component
{
    state = {
      isRegisterPage: false,
    }

    render() {
        return (
            <div className="loginForm">
                {localConfig.LOGIN_WINDOW_IMAGE ? <div style={{'backgroundImage': 'url(' + localConfig.LOGIN_WINDOW_IMAGE + ')'}} className="illustration"></div> : null}
                <div className="contentsContainer"><div className="contents">
                    <h3>{localConfig.LOGIN_HEADER}</h3>
                    <p dangerouslySetInnerHTML={{ __html: localConfig.LOGIN_SUBHEADER}} className="sub"></p>
                    <div className="vspace"></div>
                    
                    
                    {this.state.isRegisterPage ?
                        <div>
                            <span className="captionSpan">
                                
                                <button className="tabBtn" onClick={e => {this.setState ({isRegisterPage:false})}}><h3>Sign in</h3></button>
                                <h3 className="caption">Register</h3>
                            </span>
                            <div>
                                <h4>To know more about our products and subscribe, please visit <a href="https://hub.eisphoria.com/" target="_blank" rel="noreferrer noopener">EisphorIA Data Hub</a></h4>
                                
                            </div>
                        </div>
                    
                        :
                        <div>
                            <span className="captionSpan">
                                <h3 className="caption">Sign in</h3>
                                { localConfig.HIDE_FORGET_PASS ? null : <button className="tabBtn" onClick={e => {this.setState ({isRegisterPage:true})}}><h3>Register</h3></button>}
                            </span>
                            <form method="post" onSubmit={this.props.handleLoginSubmit}>
                            { localConfig.HIDE_FORGET_PASS ? null : <div className="mb-2">Use the same login/password as at <a href="https://hub.eisphoria.com/" target="_blank" rel="noreferrer noopener">EisphorIA Data Hub</a></div>}
                                <div className="field">
                                    <div className="control">
                                    <input
                                        className="input"
                                        name="username"
                                        required
                                        onChange={this.props.handleInputChange}
                                        value={this.props.state.username}
                                        placeholder="Login"
                                    />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                    <input
                                        className="input"
                                        name="password"
                                        required
                                        onChange={this.props.handleInputChange}
                                        value={this.props.state.password}
                                        type="password"
                                        placeholder="Password"
                                    />
                                    </div>
                                </div>
                                { localConfig.HIDE_FORGET_PASS ? null : <p className="forgotPassword"><a href="https://hub.eisphoria.com/login?forget"  target="_blank" rel="noreferrer noopener">Forgot password</a></p>}
                                
                                <div className="control">
                                    <button type="submit" className="button is-info">Login</button>
                                </div>
                            </form>
                        
                        </div>

                    }


                        

                </div></div>


            
            </div>
        );
    }

}

export default LoginForm;
