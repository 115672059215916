import ReactDOM from "react-dom";

export function getContainer(containerRef) {
    let container = null
    if (containerRef != null && containerRef.current != null) {
        container = ReactDOM.findDOMNode(containerRef.current)
    }
    return container
}

export function getScroller(container) {
    let parentNode = container.parentNode
    while (parentNode !== undefined && parentNode !== null && parentNode.className.indexOf('ScrollbarsCustom-Scroller') < 0) {
        parentNode = parentNode.parentNode
    }
    return parentNode
}