import config from 'react-global-configuration'
import Cookies from 'universal-cookie'

import {
    setLoading,
    getAccessToken
} from './actions'

import * as actionsProjects from './actionsProjects'
import * as actionsProject from './actionsProject'
import * as actionsCodes from './actionsCodes'

export const REGISTER_DEMAND_SENT = 'REGISTER_DEMAND_SENT'

export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOG_OUT = 'LOG_OUT'
export const CHECK_LOGGED_IN = 'CHECK_LOGGED_IN'

export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
export const SHOULD_REDIRECT_TO_HOME = 'SHOULD_REDIRECT_TO_HOME'

export function redirectToHome () {
    return {
        type: SHOULD_REDIRECT_TO_HOME
    }
}

export function registerDemandSent () {
    return {
        type: REGISTER_DEMAND_SENT
    }
}

export function loggedIn (username, auth) {
    return {
        type: LOGGED_IN,
        username,
        auth
    }
}

export function setLogout () {
    return {
        type: LOG_OUT
    }
}

export function refreshedToken (auth) {
    return {
        type: REFRESH_TOKEN,
        auth
    }
}


export function loginFailed (error) {
    return {
        type: LOGIN_FAILED,
        error
    }
}

export function checkLoggedIn () {
    return {
        type: CHECK_LOGGED_IN
    }
}

export function logout () {
    var mAuth = {
        clientId: 'aXjbNL5xtUT7T5G4SAmA9eY2tRN3r2dyPJk80HaX',
        clientSecret: 'iRfkZZm3gCW8MSrBHNfmjpPRuNOJuPiUysxbTXsqhVe3UmA1zOOWvK7w3xXwvY0qKVdHCsiCIeIG10AYugGwiFaN2ct6dLk9ezp9WPEtF5niTzvJkytHlRLd7T0mI22J',
        accessTokenUri: config.get('globalUrl') + 'o/revoke_token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("token", getAccessToken())

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(res => { 
                return res.json()})
            .then(
                (result) => {
                    dispatch(setLogout())
                    dispatch(setLoading(false))
                    
                },
                (error) => {
                    console.log ("error")
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                    
                }
            )
    }
}


export function auth (username, pass) {
    var mAuth = {
        clientId: 'aXjbNL5xtUT7T5G4SAmA9eY2tRN3r2dyPJk80HaX',
        clientSecret: 'iRfkZZm3gCW8MSrBHNfmjpPRuNOJuPiUysxbTXsqhVe3UmA1zOOWvK7w3xXwvY0qKVdHCsiCIeIG10AYugGwiFaN2ct6dLk9ezp9WPEtF5niTzvJkytHlRLd7T0mI22J',
        accessTokenUri: config.get('globalUrl') + 'o/token/',
    }

    var cUser = username.toLowerCase()
    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "password")
    data.append("username", cUser)
    data.append("password", pass)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };

    return dispatch => {
        dispatch(setLoading(true))
        return fetch(mAuth.accessTokenUri, conf)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed(result.error_description))
                        dispatch(setLoading(false))
                    }
                    else
                    {
                        dispatch(loggedIn(cUser, result))
                        dispatch(setLoading(false))
                    }
                },
                (error) => {
                    console.log ("error")
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
                }
            )
    }

}
export function refreshCallback (dispatch, desiredAction)
{
    switch (desiredAction.module) {
        case 'actionsProject':
            dispatch(actionsProject[desiredAction.name](...desiredAction.params))
            break
        case 'actionsProjects':
            dispatch(actionsProjects[desiredAction.name](...desiredAction.params))
            break
        case 'actionsCodes':
            dispatch(actionsCodes[desiredAction.name](...desiredAction.params))
            break
        
        default:
            console.log ('Unknown callback action: ' + desiredAction.name)

    }
}

export function authRefresh (desiredAction) {
    const cookies = new Cookies()
    var vauth = cookies.get("auth")
    var r_token = ""
    if ((vauth !== null) && (vauth !== undefined))
        r_token = vauth.refresh_token
    console.log ("refreshing token")
    var mAuth = {
        clientId: 'aXjbNL5xtUT7T5G4SAmA9eY2tRN3r2dyPJk80HaX',
        clientSecret: 'iRfkZZm3gCW8MSrBHNfmjpPRuNOJuPiUysxbTXsqhVe3UmA1zOOWvK7w3xXwvY0qKVdHCsiCIeIG10AYugGwiFaN2ct6dLk9ezp9WPEtF5niTzvJkytHlRLd7T0mI22J',
        accessTokenUri: config.get('globalUrl') + 'o/token/',
    }

    const data = new URLSearchParams()
    data.append("client_id", mAuth.clientId)
    data.append("client_secret", mAuth.clientSecret)
    data.append("grant_type", "refresh_token")
    data.append("refresh_token", r_token)

    const conf = {
        method: "post",
        body: data,
        headers: new Headers({ 'Accept': 'application/json, application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    return dispatch => {
        dispatch(setLoading(true))
        fetch(mAuth.accessTokenUri, conf)
            .then(
                res => { 
                    if (res.status !== 200) 
                    {
                        dispatch(loginFailed("Need to login"))
                        dispatch(setLoading(false))
                    }
                    return res.json()})
            .then(
                (result) => {
                    if (result.error)
                    {
                        dispatch(loginFailed(result.error_description))
                        dispatch(setLoading(false))
                    }
                    else {
                        dispatch(refreshedToken(result))
                        console.log ("got new token, running callback")
                        refreshCallback (dispatch, desiredAction)

                    }
                },
                (error) => {
                    console.log ("error")
                    console.log (error)
                    dispatch(loginFailed("Unknown error"))
                    dispatch(setLoading(false))
            
                }
            )
    }
}
