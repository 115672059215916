import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'


import BrowserApp from "../interfaceParts/BrowserApp"
import NavHeader from "../interfaceParts/NavHeader"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import TermsAccept from "../interfaceParts/TermsAccept"

import {
  checkLoggedIn
} from '../redux/actionsAuth'

import ProjectPageDispatcher from "../interfaceParts/ProjectPageDispatcher";


class ProjectPage extends Component {
    componentDidMount() {
        this.props.dispatch(checkLoggedIn())
        ProjectPageDispatcher.dispatch(this.props);
    }

  render() {
    //console.log (this.props.match.params)
    if (this.props.shouldRedirectToLogin)
      return (<Redirect to='/' />)

    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    
    return (
      <div className="App">
          <NavHeader projectId={this.props.match.params.pid}/>
          {error}
          
          <BrowserApp projectId={this.props.match.params.pid} />
          
          
          <TermsAccept/>
          <LoadingSpinner show={this.props.loading} />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.authParams.error,
    loggedIn: state.authParams.loggedIn,
    loading: state.globalInterface.loading,
    shouldRedirectToLogin: state.authParams.shouldRedirectToLogin
  }
}


export default connect(mapStateToProps)(ProjectPage)


