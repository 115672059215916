import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Dropdown, Popover, OverlayTrigger} from "react-bootstrap";

import Scrollbar from 'react-scrollbars-custom';

//import {Line} from 'react-chartjs-2';


import DocViewer from "./DocViewer"
import QueryForm from "./QueryForm"
import SavedDocsBrowser from "./SavedDocsBrowser"
import ActivityBrowser from "./ActivityBrowser"
import WatchesBrowser from "./WatchesBrowser"
import '../css/BrowserApp.scss'

import StringUtils from "../utils/StringUtils";

import { ReactComponent as ListImg} from '../img/ic_listview.svg'
import { ReactComponent as TimelineImg} from '../img/ic_timeline.svg'

import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";
import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";

import { ReactComponent as PrintIcon} from '../img/ic_print.svg'
import { ReactComponent as ExpandIcon} from '../img/ic_expand.svg'
import { ReactComponent as GroupIcon} from '../img/ic_group.svg'



import { ReactComponent as SortImg} from '../img/ic_sort.svg'

import { ReactComponent as SavedListItem} from '../img/ic_card-saved.svg'
import { ReactComponent as ReadListItem} from '../img/ic_card-viewed.svg'

import { ReactComponent as GvImg} from '../img/ic_cb_empty.svg'
import { ReactComponent as GvImgActive} from '../img/ic_cb_active.svg'


import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'

import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './SliderComponents' 
  

import {
  onResultsShouldRedraw,
  getProjectParams,
  setBrowsingDoc,
  onShowResultsMode,
  setGroupVersions,
  postSearch,
  postTimelineOverview,
  shouldPostSearch,
  setStartPosition,
  setExpandTimeline
} from '../redux/actionsProject'


class BrowserApp extends Component {
    constructorName = "BrowserApp"
    maxDocsUpperBound = 40

    inRangeDocScores = []

    state = {
        maxDocs : 40,
        hoveredID: -1,
        mouseX: 0,
        mouseY: 0,
        filtersVisible: false,
        sortByMenuShown: false,
        sortBy: 'Relevance',
        timelineXScale: 1.0,
        timelineMouseDownPosition: null,
        timelineActiveRange: [0,1],
        timelineActiveRangeMinStr: -1,
        timelineActiveRangeMaxStr: -1,
        overviewDocsNumber: 40,
        timelineCursorPosition: 0

    }

    constructor(props) {
        super(props)
        this.listRef = React.createRef()
    }

    timestampToCleanDate (ts) {    
        if (!ts)
            return ""
        var d = new Date(ts);
        return d.getFullYear() +  ("0"+(d.getMonth()+1)).slice(-2) +  ("0" + d.getDate()).slice(-2)
    }

  
    formatDateMS (dateMS) {
        var date = new Date(dateMS)
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        if (day < 10)
            day = '0' + day
        if (monthIndex < 9)
            return year + '-0' + (monthIndex + 1) + '-' + day;
        else
            return year + '-' + (monthIndex + 1) + '-' + day;
        
        }

    dateToPosition (d, startDate, endDate, defValue=0) {
        return (endDate - startDate) > 0 ? (d - startDate) / (endDate - startDate) : defValue 
    }

	docScore (doc) {
        return doc.score
    }
    
	isDocInRange (doc, seenGrouppedRefs) {
		var res = true;
        
        if (this.props.query.groupVersions && seenGrouppedRefs.includes(doc.ref))
            res = false
        
        if (res)
            Array.prototype.push.apply(seenGrouppedRefs, doc.similarDocsRefs)
        
      	return res
    } 

    card(doc) {
      return <div>
                <div className="card-col-left">
                    {(doc.dateString) ? <p><b>Document date:<br/></b>{doc.dateString}</p> : null}
                    {(doc.language) ? <p><b>Language:<br/></b>{doc.language}</p> : null}
                </div>
                <div className="card-col-right">
                    {(doc.caption) ? <p><b>{doc.caption}</b></p> : null }
                    {(doc.summary) ? <p>{doc.summary}</p> : null }
                </div>
                {((doc.annotations) && (doc.annotations.length > 0)) ? 
                    <div className="card-bottom">
                        { doc.annotations.map(function (ann) 
                            { 
                                return  <div key={"ann" + ann.id} className={"doc-annotation"}>
                                            { ann.comments.map(function (com) 
                                                { 
                                                    return  <div key={"com" + com.id} className={"annotation-text-container color-" + ann.color}>
                                                                <b>{com.author} ({com.modified}):</b>{com.text}
                                                            </div> 
                                                }) 
                                            }
                                        </div> 
                            }) 
                        }
                    </div>: null
                }
            </div>
    }

    

    componentDidMount() {
        if (!this.props.project) 
            this.props.dispatch (getProjectParams(this.props.projectId, false, false, true))

        document.addEventListener('mousemove', this.handleCursorMove)
    }

    componentDidUnmount() {
        document.removeEventListener('mousemove', this.handleCursorMove)
    }

    componentDidUpdate(){   
        if (this.props.query.shouldPostSearch)
        {
            this.props.dispatch (shouldPostSearch(false))
            this.props.dispatch (postSearch (this.props.query, this.props.project ? this.props.project.id : this.props.projectId, this.props.startPosition, (this.props.interface.showResultsMode === 'overview') ? this.state.overviewDocsNumber : 0))
        }
        if (this.props.shouldRedrawChart)
        {
            var tActiveRangeMin =this.state.timelineActiveRange[0]
            var tActiveRangeMax =this.state.timelineActiveRange[1]
            if ((this.state.timelineActiveRange[0] > 0) || (this.state.timelineActiveRange[1] < 1))
            {
                var netDocs = [...this.props.docs]
                var allCDates = [].concat.apply([], netDocs.map(function (d, index) { return d.inSelection < 0 ? [] : d.dates }));
                allCDates.sort()
                
                var startCDate = new Date(allCDates.length > 0 ? allCDates[0][0] : '2020-01-01').getTime(),
                    endCDate = new Date(allCDates.length > 0 ? allCDates[allCDates.length-1][0] : '2020-01-01').getTime();
                
                var cTimeFrame = endCDate - startCDate
                if (cTimeFrame < 1)
                    cTimeFrame = 1
                
            }
            if ((this.state.timelineActiveRange[0] > 0) && (this.state.timelineActiveRangeMinStr !== -1))
            {
                var cnd = new Date(this.state.timelineActiveRangeMinStr).getTime()
                if ((cnd >= startCDate) && (cnd <= endCDate))
                    tActiveRangeMin = (cnd - startCDate) / cTimeFrame
                else
                    tActiveRangeMin = 0
            }
            if ((this.state.timelineActiveRange[1] < 1) && (this.state.timelineActiveRangeMaxStr !== -1))
            {
                var cnd = new Date(this.state.timelineActiveRangeMaxStr).getTime()
                if ((cnd >= startCDate) && (cnd <= endCDate))
                    tActiveRangeMax = (cnd - startCDate) / cTimeFrame
                else
                    tActiveRangeMax = 1
            }
            //console.log (this.state.timelineActiveRange, this.state.timelineActiveRangeMinStr, this.state.timelineActiveRangeMaxStr, )


            this.setState ({
                maxDocs : 40,
                timelineXScale: 1.0,
                timelineActiveRange: [tActiveRangeMin,tActiveRangeMax],
                //timelineActiveRangeMinStr: -1,
                //timelineActiveRangeMaxStr: -1,

            })
            this.props.dispatch (onResultsShouldRedraw(false))
        }
    }

    render() {
        var that = this
        
        if ((! this.props.docs) || (this.props.docs.length === 0))
        {
            var hint = <div className="hint"><h4>No results found</h4></div>
            if (this.props.query.selectionMust.length > 0)
                hint = <div className="hint"><h4>You have a must include condition which is not present in documents. Remove it or cut into smaller parts</h4></div>
            else if (this.props.query.selectionExclude.length > 0)
                hint = <div className="hint"><h4>You have a must exclude condition. You might want to remove it</h4></div>
        }
   
        if (this.props.params)
        {
            var colorPalete = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'];
            var netDocs = [...this.props.docs]
            netDocs.sort((a,b) => (a.score > b.score) ? -1 : 1);
            
            var selectedDocs = 0
            this.inRangeDocScores = []
            var seenGrouppedRefs = []
            for (var i = 0; i < netDocs.length; i++)
            {
                if (that.isDocInRange(netDocs[i], seenGrouppedRefs))
                {
                    this.inRangeDocScores.push ([netDocs[i].score])
                    if (selectedDocs < parseInt(this.state.maxDocs))
                    {
                        netDocs[i].inSelection = selectedDocs
                        selectedDocs += 1
                    }
                    else
                        netDocs[i].inSelection = -1
                }
                else
                    netDocs[i].inSelection = -1
            }

            if (this.state.sortBy === 'Name')
                netDocs.sort((a,b) => (a.caption > b.caption) ? 1 : -1);
            else if (this.state.sortBy === 'Date descending')
                netDocs.sort((a,b) => (a.dateString > b.dateString) ? -1 : 1);
            else if (this.state.sortBy === 'Date ascending')
                netDocs.sort((a,b) => (a.dateString > b.dateString) ? 1 : -1);
            else if (this.state.sortBy === 'Extracted min date ascending')
                netDocs.sort((a,b) => { 
                    if ((!a.dates) || (a.dates.length === 0)) 
                        return -1
                    if ((!b.dates) || (b.dates.length === 0)) 
                        return 1
                    return (a.dates[0] > b.dates[0]) ? 1 : -1 
                });
            else if (this.state.sortBy === 'Extracted max date descending')
                netDocs.sort((a,b) => { 
                    if ((!a.dates) || (a.dates.length === 0)) 
                        return -1
                    if ((!b.dates) || (b.dates.length === 0)) 
                        return 1
                    return (a.dates[a.dates.length-1] > b.dates[b.dates.length-1]) ? -1 : 1 
                });
            
            var selectedDocsRefs = []
            for (i = 0; i < netDocs.length; i++)
                if (netDocs[i].inSelection >= 0)
                    selectedDocsRefs.push ([netDocs[i].ref])

            if (this.props.interface.showResultsMode === 'list')
            {
                var docsCards = netDocs.map(function (d) { 
                    const cClasses = ['docCard'];
                    if (d.inSelection < 0)
                        cClasses.push ('hidden')
                    if (that.props.sessionSeenDocs.includes (d.ref))
                        cClasses.push ('sessionSeen')
                    if (that.props.browsingDocId === d.ref) { cClasses.push('opened'); }
                    else if (that.props.browsingDocId !== null) { cClasses.push('notopened'); }


                    var scoreIndicatorsNumber = 5
                    var scoreIndicators = []
                    for (var i = 0; i < scoreIndicatorsNumber; i += 1)
                        scoreIndicators.push (<span className={"scoreIndicator " + ((d.score * scoreIndicatorsNumber) > i ? "active":"")} key={"si"+d.id + 'p'+i}></span>  )

                    return (
                        <Col className={cClasses.join(' ')}  md={12} key={"listcard"+d.id} id={"doc-" + d.id} onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language, selectedDocsRefs)); }}>
                            {that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <div className="docCardGroupMarker"></div> : null}
                            {that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <div className="docCardGroupMarker2"></div> : null}
                            <div >
                                <div className="contents">
                                    <Row>
                                        <Col md={12}>
                                            <div className="relevancy">{scoreIndicators}</div>
                                            <div className="flags">        
                                                {
                                                    (d.annotations && (d.annotations.length > 0)) ?
                                                        <span>
                                                            { d.annotations.map(function (ann) 
                                                                { 
                                                                    return <span key={'annitem' + ann.id} className={"annItem flag" + StringUtils.capitalize(ann.color)}><ImgFlag /> {ann.comments.length > 1 ? ("" + ann.comments.length):null} </span>
                                                                }) 
                                                            }
                                                        </span>
                                                        
                                                        :
                                                        null
                                                }
                                                { d.commentsNum > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                { d.highlightsNum > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <h4 className="projectTitle">{ (d.read && localConfig.VIEWED_ENABLED) ? <ReadListItem className="listItemReadIcon"/> : null} {(that.props.savedDocs && (that.props.savedDocs.map(function (d) { return d.id }).includes(d.id)) ? <SavedListItem className="listItemSavedIcon"/> : null)} {d.caption}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fields">
                                            <Col md={12}>
                                                <span className="value">{d.source.name}</span>
                                                <span className="value">{d.dateString}</span>
                                                <span className="value">{d.language}</span>
                                            </Col>
                                    </Row>
                                    {(d.summary) && (d.summary.length > 3) ? <p className="description">{d.summary}</p> : null}
                                </div>
                            </div>
                        </Col>);
                })
            }
            if (this.props.interface.showResultsMode === 'timeline')
            {
                var allCDates = [].concat.apply([], netDocs.map(function (d, index) { return d.inSelection < 0 ? [] : d.dates }));
                allCDates.sort()
                
                var startCDate = new Date(allCDates.length > 0 ? allCDates[0][0] : '2020-01-01').getTime(),
                    endCDate = new Date(allCDates.length > 0 ? allCDates[allCDates.length-1][0] : '2020-01-01').getTime();
                
                var cTimeFrame = endCDate - startCDate
                if (cTimeFrame < 1)
                    cTimeFrame = 1
                

                var guidesNum = Math.floor (6 * that.state.timelineXScale)
                var guidesPositions = [...Array(guidesNum).keys()].map (function (tick) {
                    return [10 + Math.floor (that.state.timelineXScale * (600.0 * tick / (guidesNum - 1))),   that.formatDateMS((startCDate + (endCDate - startCDate) * tick / (guidesNum - 1)))]
                })
                var timelineCursorPosition = that.state.timelineCursorPosition
                if (timelineCursorPosition < 10)
                    timelineCursorPosition = 10
                var posInTimeframe = (timelineCursorPosition - 10) / 600 / that.state.timelineXScale
                if (posInTimeframe <= 1)
                    guidesPositions.splice (1, 0, [timelineCursorPosition-1, that.formatDateMS((startCDate + (endCDate - startCDate) * posInTimeframe)) + ' — ' + that.formatDateMS((startCDate + (endCDate - startCDate) * (timelineCursorPosition - 10+1) / 600 / that.state.timelineXScale)) ])
                
                var guides = guidesPositions.map (function (gp, ind) {
                    return <div style={{ left:gp[0] }} className={((ind===1) && (posInTimeframe <= 1)) ? "guideCursor guide" : "guide"}></div>
                })

                var guidesCaptions = guidesPositions.map (function (gp, ind) {
                    return <div style={{ left:((ind===1) && (posInTimeframe <= 1)) ? ((gp[0] > 70) ? gp[0] - 70 : 0) : gp[0] }} className={((ind===1) && (posInTimeframe <= 1)) ? "guideCursor guideCaption" : "guideCaption"}>{gp[1]}</div>
                })

                if (that.props.params && that.props.params.allContDates)
                {
                    var shiftedDates = []
                    var currentPosDates = []
                    var lastPos = -1
                    
                    for (var cdate of that.props.params.allContDates) {
                        var ratio = (new Date(cdate[0]).getTime() - startCDate) / cTimeFrame; 
                        var pos = Math.floor (that.state.timelineXScale * (600 * ratio))
                        if (pos - lastPos >= 2)
                        {
                            if (currentPosDates.length > 0)
                            {
                                shiftedDates.push ([lastPos, currentPosDates, (ratio >= that.state.timelineActiveRange[0]) && (ratio <= that.state.timelineActiveRange[1])])
                                currentPosDates = []
                            }
                            lastPos = pos
                        }

                        currentPosDates.push (...cdate[1])
                        
                    }
                    if (currentPosDates.length > 0)
                        shiftedDates.push ([pos, currentPosDates, (ratio >= that.state.timelineActiveRange[0]) && (ratio <= that.state.timelineActiveRange[1])])
                }
                var gTimelineHeight = 0
                var sticks = shiftedDates.map (function(sdate, ind) {
                    var uniqueRefs = new Set()
                    var parts = sdate[1].map (function (dateRefPair, ind2) {
                        var cdate = dateRefPair[1]
                        uniqueRefs.add (dateRefPair[0])
                        return <div className="timelineTickExtract" key={'gt_' + ind + '_' + ind2} onClick={(e) => { that.props.dispatch (setBrowsingDoc(dateRefPair[0], null, selectedDocsRefs, ['did' + cdate[5],cdate[2]])); }}  >
                                    {((ind2 > 0) && (sdate[1][ind2 - 1][0] === dateRefPair[0])) ? null : <h4 onClick={(e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(dateRefPair[0], null, selectedDocsRefs)); }}>{dateRefPair[2]}</h4>}
                                      ...{cdate[1]}
                                      <span className="theDate">{cdate[2]}</span>
                                      {cdate[3]}...
                                    </div>
                    })
                    var cHeight = 5 * (1 + Math.log2 (uniqueRefs.size))
                    if (cHeight > gTimelineHeight)
                        gTimelineHeight = cHeight
                    return <OverlayTrigger trigger="click" rootClose={true} 
                            overlay={
                                <Popover key={'popover__'  + ind} className="tickPopover">
                                    <div className="tickPopoverContainer" onClick={(e)=>{document.body.click()}}>
                                        <div className="contents">
                                            <Popover.Title as="h4">Occurrences: {sdate[1].length}; documents: {uniqueRefs.size}</Popover.Title>
                                            <Popover.Content>
                                                <span>{parts}</span>
                                            </Popover.Content>
                                        </div>
                                    </div>
                                </Popover>
                            }>
                        <div className={"globalTimelineTick timelineTick ticks-" + uniqueRefs.size} key={'gttick_' + ind}  style={{left:10+sdate[0], height:cHeight, top:-cHeight, background:(sdate[2] ? '#00a5ff':'#999')  }} ></div>
                    </OverlayTrigger>
                })
                var timelineLegendHeight = gTimelineHeight + 65

                var docsCards = netDocs.map(function (d) { 
                    const cClasses = ['timelineCard'];
                    if (d.inSelection < 0)
                        cClasses.push ('hidden')
                    if (that.props.sessionSeenDocs.includes (d.ref))
                        cClasses.push ('sessionSeen')
                    if (that.props.browsingDocId === d.ref) { cClasses.push('opened'); }
                    else if (that.props.browsingDocId !== null) { cClasses.push('notopened'); }

                    var cscore = that.docScore (d)
                    
                    var hasDateInActiveRange = false
                    var shiftedDates = [[0,[]]]
                    var currentPosDates = []
                    if (d.dates.length > 0)
                    {
                        shiftedDates = []
                        var lastPos = -1
                        for (var cdate of d.dates) {
                            var ratio = (new Date(cdate[0]).getTime() - startCDate) / cTimeFrame; 
                            if ((ratio >= that.state.timelineActiveRange[0]) && (ratio <= that.state.timelineActiveRange[1])) 
                                hasDateInActiveRange=true;  

                            var pos = Math.floor (that.state.timelineXScale * (600 * ratio))
                            if (pos - lastPos >= 2)
                            {
                                if (currentPosDates.length > 0) {
                                    shiftedDates.push ([lastPos, currentPosDates])
                                    currentPosDates = []
                                }
                                lastPos = pos
                            }

                            currentPosDates.push (cdate)
                            
                        }
                        if (currentPosDates.length > 0)
                           shiftedDates.push ([pos, currentPosDates])
                        
                    } 
                    
                    //var cscoreColor = hasDateInActiveRange ? "rgb(" + Math.floor(255*(1-cscore)) + ',' + Math.floor(255*cscore) + ',0)' : '#bec8d2'
                    var cscoreColor = hasDateInActiveRange ? ['#ED531A','#FF5F2D','#F8802F','#F7A63F','#FEC540','#F8D845','#D6E137','#B8E62E','#8CD436','#1EA450','#1EA450'][Math.floor (10*cscore)]: '#bec8d2'
                    var ticks = shiftedDates.map (function (sdate, ind) {
                        var parts = sdate[1].map (function (cdate, ind2) {
                            return <div className="timelineTickExtract" key={d.ref + '_' + ind + '_' + ind2} onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language, selectedDocsRefs, ['did' + cdate[5], cdate[2]])); }}  >...{cdate[1]}<span className="theDate">{cdate[2]}</span>{cdate[3]}...</div>
                        })
                        return <OverlayTrigger trigger="click" rootClose={true} 
                                    overlay={
                                        <Popover key={'popover' + d.ref + '_' + ind} className="tickPopover">
                                            <div className="tickPopoverContainer" onClick={(e)=>{document.body.click()}}>
                                                <div className="contents">
                                                    <Popover.Title as="h4">{d.caption}</Popover.Title>
                                                    <Popover.Content>
                                                        <span>{parts}</span>
                                                    </Popover.Content>
                                                </div>
                                            </div>
                                        </Popover>
                                    }>
                                <div key={'tick' + d.ref + '_' + ind}   className={"timelineTick ticks-" + sdate[1].length} style={{left:sdate[0], background:cscoreColor  }} ></div>
                            </OverlayTrigger>
                    })
                    
                    return (
                        <div className={cClasses.join(' ')}  key={"listcard"+d.id} id={"doc-" + d.id} >
                                            <div className="lineContainer" style={{width:shiftedDates[shiftedDates.length - 1][0]}}>
                                                {ticks}
                                                <div className="line" style={{left: shiftedDates[0][0], width:shiftedDates[shiftedDates.length - 1][0]-shiftedDates[0][0], background:cscoreColor}}></div>
                                            </div><span className="projectTitle" onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language, selectedDocsRefs)); }}>
                                                {
                                                    (d.annotations && (d.annotations.length > 0)) ?
                                                        <span>
                                                            { d.annotations.map(function (ann) 
                                                                { 
                                                                    return <span key={'annitem' + ann.id} className={"annItem flag" + StringUtils.capitalize(ann.color)}><ImgFlag /> {ann.comments.length > 1 ? ("" + ann.comments.length):null} </span>
                                                                }) 
                                                            }
                                                        </span>
                                                        
                                                        :
                                                        null
                                                }

                                                
                                                { d.commentsNum > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                { d.highlightsNum > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                                { that.props.query.groupVersions && (d.similarDocsRefs.length > 1) ? <span className="annItem flagNeutral"><GroupIcon/></span>:null}
                                                
                                                { (d.read && localConfig.VIEWED_ENABLED) ? <ReadListItem className="listItemReadIcon"/> : null} {(that.props.savedDocs && (that.props.savedDocs.map(function (d) { return d.id }).includes(d.id)) ? <SavedListItem className="listItemSavedIcon"/> : null)} 
                                                <span className="caption" style={hasDateInActiveRange ? {}: {color:'#bec8d2'}}>{d.caption}</span>
                                            </span>
                                    
                        </div>);
                })
            }
            if (this.props.interface.showResultsMode === 'overview')
            {
                var allContDates = (that.props.params && that.props.params.overviewContDates) ? that.props.params.overviewContDates : []
                    
                var startCDate = new Date(allContDates.length > 0 ? allContDates[0][0] : '2020-01-01').getTime(),
                    endCDate = new Date(allContDates.length > 0 ? allContDates[allContDates.length-1][0] : '2020-01-01').getTime();
                
                var cTimeFrame = endCDate - startCDate
                if (cTimeFrame < 1)
                    cTimeFrame = 1
                

                var guidesNum = Math.floor (6 * that.state.timelineXScale)
                var guidesPositions = [...Array(guidesNum).keys()].map (function (tick) {
                    return [10 + Math.floor (that.state.timelineXScale * (600.0 * tick / (guidesNum - 1))),   that.formatDateMS((startCDate + (endCDate - startCDate) * tick / (guidesNum - 1)))]
                })
                
                var guides = guidesPositions.map (function (gp, ind) {
                    return <div style={{ left:gp[0] }} className="guide"></div>
                })

                var guidesCaptions = guidesPositions.map (function (gp, ind) {
                    return <div style={{ left:gp[0] }} className="guideCaption">{gp[1]}</div>
                })

                var shiftedDates = []
                var currentPosDates = []
                var lastPos = -1
                
                for (var cdate of allContDates) {
                    var ratio = (new Date(cdate[0]).getTime() - startCDate) / cTimeFrame; 
                    var pos = Math.floor (that.state.timelineXScale * (600 * ratio))
                    if (pos - lastPos >= 2)
                    {
                        if (currentPosDates.length > 0)
                        {
                            shiftedDates.push ([lastPos, currentPosDates, (ratio >= that.state.timelineActiveRange[0]) && (ratio <= that.state.timelineActiveRange[1])])
                            currentPosDates = []
                        }
                        lastPos = pos
                    }

                    currentPosDates.push (...cdate[1])
                    
                }
                if (currentPosDates.length > 0)
                    shiftedDates.push ([pos, currentPosDates, (ratio >= that.state.timelineActiveRange[0]) && (ratio <= that.state.timelineActiveRange[1])])
            
                var sticks = shiftedDates.map (function(sdate, ind) {
                    var uniqueRefs = new Set()
                    var parts = sdate[1].map (function (dateRefPair, ind2) {
                        var cdate = dateRefPair[1]
                        uniqueRefs.add (dateRefPair[0])
                        return <div className="timelineTickExtract" key={'gt_' + ind + '_' + ind2} onClick={(e) => { that.props.dispatch (setBrowsingDoc(dateRefPair[0], null, selectedDocsRefs, ['did' + cdate[5], cdate[2]])); }}>
                                    {((ind2 > 0) && (sdate[1][ind2 - 1][0] === dateRefPair[0])) ? null : <h4 onClick={(e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(dateRefPair[0], null, selectedDocsRefs)); }}>{dateRefPair[2]}</h4>}
                                      ...{cdate[1]}
                                      <span className="theDate">{cdate[2]}</span>
                                      {cdate[3]}...
                                    </div>
                    })
                    var cHeight = 5 * (1 + Math.log2 (uniqueRefs.size))

                    return <OverlayTrigger trigger="click" rootClose={true} 
                            overlay={
                                <Popover key={'popover__'  + ind} className="tickPopover">
                                    <div className="tickPopoverContainer" onClick={(e)=>{document.body.click()}}>
                                        <div className="contents">
                                            <Popover.Title as="h4">Occurrences: {sdate[1].length}; documents: {uniqueRefs.size}</Popover.Title>
                                            <Popover.Content>
                                                <span>{parts}</span>
                                            </Popover.Content>
                                        </div>
                                    </div>
                                </Popover>
                            }>
                        <div className={"globalTimelineTick timelineTick ticks-" + uniqueRefs.size} key={'gttick_' + ind}  style={{left:10+sdate[0], height:(2*cHeight), top:(-cHeight-50), background:(sdate[2] ? '#00a5ff':'#999')  }} ></div>
                    </OverlayTrigger>

                })
            }
            
        }
        else
            hint = <div className="hint"><h4>Please enter a search query to find relevant documents here</h4></div>
        
        
        var subHeader = <div className="subheader">
                            <Container fluid>
                                <Row>
                                    <Col md={12}>
                                        <span className="float-right">
                                            <Dropdown drop={"down"} className="filterDropdown">
                                                <Dropdown.Toggle variant="light" className="sortByBtn">
                                                    <span className="weightRegular"><SortImg/></span> {this.state.sortBy}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu alignRight="true" flip={false} className="gvOptionsMenu optionsMenu withShadow">
                                                    {['Relevance', 'Name', 'Date descending', 'Date ascending', 'Extracted min date ascending', 'Extracted max date descending'].map(function (d) { return <button key={'sortBy_' + d} className={that.state.sortBy === d ? 'active' : ''} onClick={(e) => { that.setState ({sortBy:d, sortByMenuShown:false, shownOptionsPanelID:null})}}>{d}</button> }) }
                                                    <button className="gvButton" title={that.props.query.groupVersions ? "Ungroup document versions":"Group document versions"} onClick={(e) => {that.props.dispatch(setGroupVersions(!that.props.query.groupVersions)); }}>
                                                        {that.props.query.groupVersions ? 
                                                            <GvImgActive/>:
                                                            <GvImg/>}
                                                        Group documents
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            
                                        </span>
                                        <span>
                                            <button className={this.props.interface.showResultsMode === 'list' ? 'resultsViewTypeBtn active':'resultsViewTypeBtn'} onClick={(e) => {this.props.dispatch (onShowResultsMode('list')); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===0)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}><ListImg/>List</button>
                                            {localConfig.timelineEnabled ? <button className={this.props.interface.showResultsMode === 'timeline' ? 'resultsViewTypeBtn active':'resultsViewTypeBtn'} onClick={(e) => {this.props.dispatch (onShowResultsMode('timeline')); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===0)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}><TimelineImg/>Timeline</button>:null}
                                            {localConfig.timelineEnabled ? <button className={this.props.interface.showResultsMode === 'overview' ? 'resultsViewTypeBtn active':'resultsViewTypeBtn'} onClick={(e) => {this.props.dispatch (onShowResultsMode('overview')); this.props.dispatch (postTimelineOverview (this.props.query, this.props.project ? this.props.project.id : this.props.projectId, this.state.overviewDocsNumber)); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===0)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}><TimelineImg/>Overview</button>:null}

                                        </span>
                                        
                                        
                                    </Col>
                                    
                                </Row>
                            </Container>
                        </div>

        var resultsView = this.props.params ? <div className={"resultsBrowser" + (this.props.interface.timelineExpanded ? " expanded":"")}>
                {subHeader}
                {hint}
                        
                {this.props.interface.showResultsMode === 'list' ? 
                        <div className="cardsContainer">
                            <Scrollbar ref={this.listRef} noScrollX={true}>
                                <Container fluid>
                                    {docsCards.length > 0 ?
                                        <Row className="listNavTop">
                                            <span className="position">Browsing documents {this.props.startPosition + 1} - {this.props.docs.length + this.props.startPosition} among {this.props.params.ffNum} following filters.</span>
                                            <div className="alignCenter">
                                                {(this.props.startPosition > 40) ? <button className="toFirstPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(0)); this.props.dispatch(shouldPostSearch(true))}}>First page</button>:null}
                                                {(this.props.startPosition > 0) ? <button className="toPreviousPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition - 40)); this.props.dispatch(shouldPostSearch(true))}}>Previous page</button>:null}
                                            </div>
                                            
                                        </Row> : null}

                                    <Row className="docCardsInternalContainer">
                                        {docsCards}
                                    </Row>

                                    {(this.props.startPosition + 40 < this.props.params.ffNum) ?
                                        <Row className="listNavBottom">
                                            <button className="toNextPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition + 40)); this.props.dispatch(shouldPostSearch(true)); this.listRef.current.scrollTo(0,0)}}>To the next page with less relevant results</button>
                                            
                                        </Row> : null}
                                </Container>
                            </Scrollbar>
                        
                        </div>
                    : null}
                {this.props.interface.showResultsMode === 'timeline' ? 
                    <div className="timelineCardsContainer">
                            <div className="topBar">
                                <button className="expandTimelineButton" onClick={(e)=>{
                                    if ((!that.props.interface.timelineExpanded) && (that.state.timelineXScale === 1.0))
                                        that.setState ({timelineXScale: (document.getElementsByClassName("BrowserApp")[0].offsetWidth - 400) / 600 })
                                        
                                    that.props.dispatch(setExpandTimeline(!that.props.interface.timelineExpanded))
                                        
                                    }}><ExpandIcon/> {this.props.interface.timelineExpanded ? "Open compact view":"Open fullscreen view"} </button>
                                <button className="printTimelineButton" onClick={(e)=>{
                                        var pri = document.getElementById("contentstoprint");
                                        var queryHeader = '<div class="query"><h4>Query:</h4>' + this.props.query.query + '</div>'
                                        for (var el of this.props.query.selectionMust)
                                            queryHeader = queryHeader + "<div><h5>Must include:</h5>" + el + "</div>"
                                        for (var el of this.props.query.selectionExclude)
                                            queryHeader = queryHeader + "<div><h5>Must exclude:</h5>" + el + "</div>"
                                        if (this.props.query.minDate)
                                            queryHeader = queryHeader + "<div><h5>Min date:</h5>" + this.timestampToCleanDate (this.props.query.minDate) + "</div>"
                                        if (this.props.query.maxDate)
                                            queryHeader = queryHeader + "<div><h5>Max date:</h5>" + this.timestampToCleanDate (this.props.query.maxDate) + "</div>"
                                        if (this.props.query.minCDate)
                                            queryHeader = queryHeader + "<div><h5>Min extracted date:</h5>" + this.timestampToCleanDate (this.props.query.minCDate) + "</div>"
                                        if (this.props.query.maxCDate)
                                            queryHeader = queryHeader + "<div><h5>Max extracted date:</h5>" + this.timestampToCleanDate (this.props.query.maxCDate) + "</div>"

                                        pri.innerHTML = queryHeader + this.listRef.current.contentElement.innerHTML;
                                        //pri.innerHTML = document.getElementsByClassName("timelineCardsContainer")[0].innerHTML
                                        window.print()
                                        
                                    }}><PrintIcon/> Print timeline</button>
                                <div className="scaleControlButtons">
                                    <button className={"scaleControlButton minusButton" + (that.state.timelineXScale <= 1 ? " disabled":"")} onClick={(e)=>{e.stopPropagation(); if (that.state.timelineXScale > 1) that.setState({timelineXScale:that.state.timelineXScale / 2})}}>-</button>
                                    <button className="scaleControlButton plusButton" onClick={(e)=>{e.stopPropagation(); that.setState({timelineXScale:that.state.timelineXScale * 2})}}>+</button>
                                </div>
                            
                            </div>
                            
                            <Scrollbar ref={this.listRef} onMouseMove={(e)=>{ that.setState ({timelineCursorPosition: e.clientX + that.listRef.current.scrollLeft})}}>
                                <div className="outZoneRight outZone" style={{left: 10+that.state.timelineXScale * (600.0 * that.state.timelineActiveRange[1])}}></div>
                                <div className="outZoneLeft outZone" style={{width: 10+that.state.timelineXScale * (600.0 * that.state.timelineActiveRange[0])}}></div>
                                {guides}
                                
                                    
                                <div className="timelineLegend" style={{ top: `calc(100% - ${timelineLegendHeight}px)`, height: timelineLegendHeight  }}>
                                    <div style={{height: gTimelineHeight, marginTop:gTimelineHeight}}  className="gTimeline">{sticks}</div>
                                    <div style={{marginTop: gTimelineHeight}} className="guidesCaptions">{guidesCaptions}</div>
                                    <div className="timelineActiveSliderContainer" style={{"left":guidesPositions[0][0] + 'px', "width":(guidesPositions[guidesPositions.length-1][0] - guidesPositions[0][0]) + 'px', top:gTimelineHeight }}>
                                        <Slider
                                            className="timelineActiveSlider"
                                            mode={2}
                                            step={0.00001}
                                            domain={[0,1]}
                                            reversed={false}
                                            
                                            //onChange
                                            onUpdate={(newRange)=>{that.setState({timelineActiveRange:newRange, timelineActiveRangeMinStr:that.formatDateMS((startCDate + (endCDate - startCDate) * newRange[0])), timelineActiveRangeMaxStr:that.formatDateMS((startCDate + (endCDate - startCDate) * newRange[1])) })}}
                                            values={that.state.timelineActiveRange}
                                            >
                                            <Rail>
                                                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                            </Rail>
                                            <Handles>
                                                {({ handles, getHandleProps }) => (
                                                <div className="slider-handles">
                                                    {handles.map(handle => (
                                                    <Handle
                                                        key={handle.id}
                                                        handle={handle}
                                                        domain={[0,1]}
                                                        getHandleProps={getHandleProps}
                                                    />
                                                    ))}
                                                </div>
                                                )}
                                            </Handles>
                                            <Tracks left={false} right={false}>
                                                {({ tracks, getTrackProps }) => (
                                                <div className="slider-tracks">
                                                    {tracks.map(({ id, source, target }) => (
                                                    <Track
                                                        key={id}
                                                        source={source}
                                                        target={target}
                                                        getTrackProps={getTrackProps}
                                                    />
                                                    ))}
                                                </div>
                                                )}
                                            </Tracks>
                                            </Slider>
                                            <div className="timelineActiveRangeVisual">
                                                <input className="timelineActiveMin" onChange={(v)=>{ 
                                                        that.setState({timelineActiveRangeMinStr: v.target.value}); 
                                                        if (v.target.value.length === 10)
                                                        {
                                                            var cnd = new Date(v.target.value).getTime()
                                                            if ((cnd >= startCDate) && (cnd <= endCDate))
                                                                that.setState({timelineActiveRange:[(cnd - startCDate) / cTimeFrame,  that.state.timelineActiveRange[1]] })
                                                            else
                                                                that.setState({timelineActiveRange:[0,  that.state.timelineActiveRange[1]] })
                                                        }
                                                    }} value={(that.state.timelineActiveRangeMinStr == -1) ? that.formatDateMS((startCDate + (endCDate - startCDate) * that.state.timelineActiveRange[0]  )) : that.state.timelineActiveRangeMinStr}/>
                                                <input className="timelineActiveMax" onChange={(v)=>{ 
                                                        that.setState({timelineActiveRangeMaxStr: v.target.value}); 
                                                        if (v.target.value.length === 10)
                                                        {
                                                            var cnd = new Date(v.target.value).getTime()
                                                            if ((cnd >= startCDate) && (cnd <= endCDate))
                                                                that.setState({timelineActiveRange:[that.state.timelineActiveRange[0], (cnd - startCDate) / cTimeFrame] })
                                                            else
                                                                that.setState({timelineActiveRange:[that.state.timelineActiveRange[0], 1] })
                                                        }
                                                    }} value={(that.state.timelineActiveRangeMaxStr == -1) ? that.formatDateMS((startCDate + (endCDate - startCDate) * that.state.timelineActiveRange[1]  )) : that.state.timelineActiveRangeMaxStr}/>
                                                
                                            </div>
                                        </div>
                                </div>
                                <div className="insideScrollBar">
                                    {docsCards}
                                    
                                </div>
                            </Scrollbar>
                            
                            <div className="controlsBar">
                                <span className="buttons">
                                {(this.props.startPosition > 0) ? 
                                    <button className="toPreviousPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition - 40)); this.props.dispatch(shouldPostSearch(true))}}>&lt;</button>:
                                    <button className="disabled">&lt;</button>
                                }

                                    {(this.props.startPosition + 40 < this.props.params.ffNum) ?
                                        <button className="toNextPage lightBtn" onClick={(e) => {this.props.dispatch(setStartPosition(this.props.startPosition + 40)); this.props.dispatch(shouldPostSearch(true)); this.listRef.current.scrollTo(0,0)}}>&gt;</button>:
                                        <button className="disabled">&gt;</button>}
                                </span>
                                <span className="position">{this.props.startPosition + 1} - {this.props.docs.length + this.props.startPosition} documents among {this.props.params.ffNum} following filters.</span>
                                
                            </div>
                        
                    </div> : null
                }
                {this.props.interface.showResultsMode === 'overview' ? 
                    <div className="timelineCardsContainer overview">
                            <div className="topBar">
                                <button className="expandTimelineButton" onClick={(e)=>{
                                    if ((!that.props.interface.timelineExpanded) && (that.state.timelineXScale === 1.0))
                                        that.setState ({timelineXScale: (document.getElementsByClassName("BrowserApp")[0].offsetWidth - 400) / 600 })
                                        
                                    that.props.dispatch(setExpandTimeline(!that.props.interface.timelineExpanded))
                                        
                                    }}><ExpandIcon/> {this.props.interface.timelineExpanded ? "Open compact view":"Open fullscreen view"} </button>
                                <button className="printTimelineButton" onClick={(e)=>{
                                        var pri = document.getElementById("contentstoprint");
                                        pri.innerHTML = this.listRef.current.contentElement.innerHTML;
                                        //pri.innerHTML = document.getElementsByClassName("timelineCardsContainer")[0].innerHTML
                                        window.print()
                                        
                                    }}><PrintIcon/> Print overview</button>
                                <div className="scaleControlButtons">
                                    <button className={"scaleControlButton minusButton" + (that.state.timelineXScale <= 1 ? " disabled":"")} onClick={(e)=>{e.stopPropagation(); if (that.state.timelineXScale > 1) that.setState({timelineXScale:that.state.timelineXScale / 2})}}>-</button>
                                    <button className="scaleControlButton plusButton" onClick={(e)=>{e.stopPropagation(); that.setState({timelineXScale:that.state.timelineXScale * 2})}}>+</button>
                                </div>
                            
                            </div>
                            
                            <Scrollbar ref={this.listRef}>
                                <div className="outZoneRight outZone" style={{left: 10+that.state.timelineXScale * (600.0 * that.state.timelineActiveRange[1])}}></div>
                                <div className="outZoneLeft outZone" style={{width: 10+that.state.timelineXScale * (600.0 * that.state.timelineActiveRange[0])}}></div>
                                {guides}
                                
                                    
                                <div className="timelineLegend">
                                    <div className="gTimeline">{sticks}</div>
                                    <div className="guidesCaptions">{guidesCaptions}</div>
                                    <div className="timelineActiveSliderContainer" style={{"left":guidesPositions[0][0] + 'px', "width":(guidesPositions[guidesPositions.length-1][0] - guidesPositions[0][0]) + 'px' }}>
                                        <Slider
                                            className="timelineActiveSlider"
                                            mode={2}
                                            step={0.00001}
                                            domain={[0,1]}
                                            reversed={false}
                                            
                                            //onChange
                                            onUpdate={(newRange)=>{that.setState({timelineActiveRange:newRange, timelineActiveRangeMinStr:that.formatDateMS((startCDate + (endCDate - startCDate) * newRange[0])), timelineActiveRangeMaxStr:that.formatDateMS((startCDate + (endCDate - startCDate) * newRange[1])) })}}
                                            values={that.state.timelineActiveRange}
                                            >
                                            <Rail>
                                                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                            </Rail>
                                            <Handles>
                                                {({ handles, getHandleProps }) => (
                                                <div className="slider-handles">
                                                    {handles.map(handle => (
                                                    <Handle
                                                        key={handle.id}
                                                        handle={handle}
                                                        domain={[0,1]}
                                                        getHandleProps={getHandleProps}
                                                    />
                                                    ))}
                                                </div>
                                                )}
                                            </Handles>
                                            <Tracks left={false} right={false}>
                                                {({ tracks, getTrackProps }) => (
                                                <div className="slider-tracks">
                                                    {tracks.map(({ id, source, target }) => (
                                                    <Track
                                                        key={id}
                                                        source={source}
                                                        target={target}
                                                        getTrackProps={getTrackProps}
                                                    />
                                                    ))}
                                                </div>
                                                )}
                                            </Tracks>
                                            </Slider>
                                            <div className="timelineActiveRangeVisual">
                                                <input className="timelineActiveMin" onChange={(v)=>{ 
                                                        that.setState({timelineActiveRangeMinStr: v.target.value}); 
                                                        if (v.target.value.length === 10)
                                                        {
                                                            var cnd = new Date(v.target.value).getTime()
                                                            if ((cnd >= startCDate) && (cnd <= endCDate))
                                                                that.setState({timelineActiveRange:[(cnd - startCDate) / cTimeFrame,  that.state.timelineActiveRange[1]] })
                                                            else
                                                                that.setState({timelineActiveRange:[0,  that.state.timelineActiveRange[1]] })
                                                        }
                                                    }} value={(that.state.timelineActiveRangeMinStr == -1) ? that.formatDateMS((startCDate + (endCDate - startCDate) * that.state.timelineActiveRange[0]  )) : that.state.timelineActiveRangeMinStr}/>
                                                <input className="timelineActiveMax" onChange={(v)=>{ 
                                                        that.setState({timelineActiveRangeMaxStr: v.target.value}); 
                                                        if (v.target.value.length === 10)
                                                        {
                                                            var cnd = new Date(v.target.value).getTime()
                                                            if ((cnd >= startCDate) && (cnd <= endCDate))
                                                                that.setState({timelineActiveRange:[that.state.timelineActiveRange[0], (cnd - startCDate) / cTimeFrame] })
                                                            else
                                                                that.setState({timelineActiveRange:[that.state.timelineActiveRange[0], 1] })
                                                        }
                                                    }} value={(that.state.timelineActiveRangeMaxStr == -1) ? that.formatDateMS((startCDate + (endCDate - startCDate) * that.state.timelineActiveRange[1]  )) : that.state.timelineActiveRangeMaxStr}/>
                                                
                                            </div>
                                        </div>
                                </div>
                                
                            </Scrollbar>
                            
                            <div className="controlsBar">
                                <span className="position">Documents number: <input type="number" min={1} max={500}  value={this.state.overviewDocsNumber} onChange={(e)=>{that.setState({overviewDocsNumber:(e.target.value <= 500) ? e.target.value : 500 })}} onKeyDown={(e) => {if (e.key === 'Enter') { e.target.blur() }}}  onBlur={()=>{ that.props.dispatch (postTimelineOverview (that.props.query, that.props.project ? that.props.project.id : that.props.projectId, that.state.overviewDocsNumber));   }} /> among {this.props.params.ffNum} following filters.</span>
                                
                            </div>
                        
                    </div> : null
                }
                
            </div>:null
            

        return (
        <div className="BrowserApp" onMouseUp={(e)=>{that.setState({timelineMouseDownPosition:null})}}>
                { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                    <Joyride
                        tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                        styles={{options: globalConfig.JOYRIDE.options}}
                        disableCloseOnEsc={true}
                        disableOverlayClose={true}
                        disableOverlay={true}
                        disableScrolling={true}
                        showSkipButton={true}
                        hideBackButton={true}
                        run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                        locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                        continuous={false}
                        stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                        callback = { data => {
                                const { action, type } = data;
                                if (action === ACTIONS.SKIP)
                                    this.props.dispatch (setTutorialElements(null))
                                if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                {
                                    //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                    //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                    this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                }
                            
                                
                                
                            }
                        }
                    />
                    :null
                }
                
                { (!this.props.interface.savedDocsVisible && !this.props.interface.activityVisible && !this.props.interface.watchesVisible) ? <QueryForm projectId={this.props.project ? this.props.project.id : this.props.projectId} lastQueries={this.props.lastQueries}  /> : null}
                { this.props.interface.activityVisible ? 
                    <ActivityBrowser projectId={this.props.project ? this.props.project.id : this.props.projectId} /> :
                    (
                        this.props.interface.watchesVisible ? <WatchesBrowser /> : 
                        <div className="Browser">
                            { this.props.interface.savedDocsVisible ? <SavedDocsBrowser projectId={this.props.project ? this.props.project.id : this.props.projectId} /> :
                            resultsView}
                            <DocViewer projectId={this.props.project ? this.props.project.id : this.props.projectId} topButtons={{close:true, save:true, print:true, searchWith:false, previous:false, next:false}} />
                        </div>)
                }
                {(this.props.params && this.props.interface.showResultsMode === 'list') || this.props.interface.savedDocsVisible ? <GHelper hParams={this.props.interface.savedDocsVisible ? {'SavedDocsBrowser':0}:{'BrowserApp':0}}/> : null}
                
        </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    interface: state.globalInterface,

    shouldRedrawChart: state.globalInterface.resultsShouldRedraw,
    showSavedDocs: state.globalInterface.showSavedDocs,
    showQS: state.globalInterface.showQS,


    docs: state.projects.docs,
    net: state.projects.net,
    params: state.projects.params,
    lastQueries: state.projects.lastQueries,
    savedDocs: state.projects.savedDocs,

    browsingDocId: state.projects.browsingDocId,
    loadedDoc: state.projects.loadedDoc,
    username: state.authParams.username,
    query: state.query,
    sessionSeenDocs: state.projects.sessionSeenDocs,
    project: state.projects.currentProject,
    startPosition: state.projects.startPosition
  }
}

export default connect(mapStateToProps)(BrowserApp)



