import 'core-js';
import 'react-app-polyfill/ie9';


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './css/bootstrap.min.css'
import * as serviceWorker from './serviceWorker';

import IndexPage from './pages/IndexPage'
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import DocPage from './pages/DocPage';
import HomePage from './pages/HomePage';
import CompanyPage from './pages/CompanyPage';
import FlatIndexPage from './pages/FlatIndexPage'

import config from 'react-global-configuration'

import localConfig from './config/config'
//const localConfig = require("config/config.js")

config.set({ globalUrl: localConfig.API_URL, 'localConfig':localConfig });

const loggerMiddleware = createLogger()
function configureStore(preloadedState) {
  if (localConfig.DEBUG)
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware, loggerMiddleware)
    )
  else
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware)
    )
}



const store = configureStore ()

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/projects" component={ProjectsPage} />
                <Route exact path="/project/:pid/:mode/:docRef?" component={ProjectPage} />
                <Route path="/project/:pid?" component={ProjectPage} />
                <Route path="/doc/:docRef?" component={DocPage} />
                <Route path="/index/:path?" component={FlatIndexPage} />
                
                <Route exact path="/" component={IndexPage} />
                <Route exact path="/home" component={HomePage} />
                <Route exact path="/company" component={CompanyPage} />
                
                
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
