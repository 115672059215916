var Enum = require('node-enumjs');


export var DocViewerEventType = Enum.define("DocViewerEventType", {
    constants: {
        SHOW_COMMENTS: {
            eventName: "docviewer.show-comments"
        },
        ADD_COMMENT: {
            eventName: "docviewer.add-comment"
        },
        CLOSE_OTHERS: {
            eventName: "docviewer.close-others"
        },
        CLOSE_COMMENT_DIALOG: {
            eventName: "docviewer.close-comment-dialog"
        },
        HIGHLIGHT_MARK: { // marks highlight when it clicked and/or selected via menu
            eventName: "docviewer.highlight-mark"
        },
        HIGHLIGHT_PAINT: { // paint highlight with different color when mouse over highlight selection button
            eventName: "docviewer.highlight-paint"
        },
        HIGHLIGHT_CLEAR: { // restore default highlight color
            eventName: "docviewer.highlight-clear"
        },
        HIGHLIGHT_MENU_INVALIDATE: {
            eventName: "docviewer.highlight-menu-invalidate"
        },
        DOC_ADD_ANNOTATION: {
            eventName: "docviewer.doc-add-annotation"
        }
    }
});


(function () {
    if ( typeof window.CustomEvent === "function" ) return false;

    function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    window.CustomEvent = CustomEvent;
})();


export function DocViewerEventFactory(target) {

    function createEvent(eventType, data = null) {
        let event = new CustomEvent(eventType.eventName, {'detail': data != null ? data : {}});
        event.initEvent(eventType.eventName, true, true);
        target.dispatchEvent(event);
    }

    function createShowCommentsEvent(highlightId) {
        createEvent(DocViewerEventType.SHOW_COMMENTS, {highlightId: highlightId});
    }

    function createAddCommentEvent(highlightId = null, rect = null) {
        createEvent(DocViewerEventType.ADD_COMMENT, {highlightId: highlightId, rect: rect});
    }

    function createHighlightMarkEvent(highlightId) {
        createEvent(DocViewerEventType.HIGHLIGHT_MARK, {highlightId: highlightId});
    }

    function createCloseOthersEvent(sender) {
        createEvent(DocViewerEventType.CLOSE_OTHERS, {sender: sender});
    }

    function createCloseCommentDialogEvent(sender) {
        createEvent(DocViewerEventType.CLOSE_COMMENT_DIALOG, {sender: sender});
    }

    return Object.freeze({
        createEvent,
        createShowCommentsEvent,
        createAddCommentEvent,
        createHighlightMarkEvent,
        createCloseOthersEvent,
        createCloseCommentDialogEvent
    })
}


export function DocViewerListenerFactory(target) {
    function createListener(eventType, listener) {
        target.addEventListener(eventType.eventName, listener);
    }

    function createShowCommentsListener(listener) {
        createListener(DocViewerEventType.SHOW_COMMENTS, listener);
    }

    function createAddCommentListener(listener) {
        createListener(DocViewerEventType.ADD_COMMENT, listener);
    }

    function createMarkHighlightListener(listener) {
        createListener(DocViewerEventType.HIGHLIGHT_MARK, listener);
    }

    function createCloseOthersListener(listener) {
        createListener(DocViewerEventType.CLOSE_OTHERS, listener);
    }

    function createCloseCommentDialogListener(listener) {
        createListener(DocViewerEventType.CLOSE_COMMENT_DIALOG, listener);
    }

    return Object.freeze({
        createListener,
        createShowCommentsListener,
        createAddCommentListener,
        createMarkHighlightListener,
        createCloseOthersListener,
        createCloseCommentDialogListener
    })
}
