export default (function StringUtils() {

    function capitalize (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return Object.freeze({
        capitalize
    })
})()
