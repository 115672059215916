var Enum = require('node-enumjs');

const MetaTagType = Enum.define("MetaTagType", ["HIGHLIGHT", "HEATMAP_MARKER", "MUST_INCLUDE", "DATE_MARKER"]);
const MetaTagKind = Enum.define("MetaTagKind", ["PAIRED", "UNPAIRED"]);
const MetaTagListDescriptors = Object.freeze({
    "highlights": {
        kind: MetaTagKind.PAIRED,
        type: MetaTagType.HIGHLIGHT,
        cssClass: 'highlight'
    },
    "miMarkers": {
        kind: MetaTagKind.PAIRED,
        type: MetaTagType.MUST_INCLUDE,
        cssClass: 'must-include'
    },
    "heatmapMarkers": {
        kind: MetaTagKind.UNPAIRED,
        type: MetaTagType.HEATMAP_MARKER,
        cssClass: 'heatmap-marker'
    },
    "dateMarkers": {
        kind: MetaTagKind.UNPAIRED,
        type: MetaTagType.DATE_MARKER,
        cssClass: 'date-marker'
    }
});

function MetaTagFactory() {
    function create(element, descriptor) {
        if (descriptor.kind === MetaTagKind.PAIRED) {
            if (element.id === undefined || element.id === null) {
                throw 'Meta Tag Creation Exception: paired tag have to have "id" defined.'
            }

            if (element.start === undefined || element.start === null || element.end === undefined || element.end === null) {
                throw 'Meta Tag Creation Exception: paired tag have to have interval (both, "start" and "end") defined.'
            }
        }

        if (descriptor.kind === MetaTagKind.UNPAIRED) {
            if (element.position === undefined || element.position === null) {
                throw 'Meta Tag Creation Exception: unpaired tag have to have "position" defined.'
            }
        }

        return {
            id:         element.id,
            start:      element.start,
            end:        element.end,
            position:   element.position,
            value:      element.value,
            element:    element,
            type:       descriptor.type,
            kind:       descriptor.kind,
            cssClass:   descriptor.cssClass
        }
    }

    return Object.freeze({
        create
    });
}


export
function MetaRenderer() {

    let metaTagElement = 'div';
    let basicMetaTagCssClass = 'meta-tag';

    let renderedMetaTagMap = new Map();
    const HIGHLIGHT_CSS_CLASS_NAME = MetaTagListDescriptors["highlights"].cssClass;


    function renderPairedMetaTag(range, selections) {
        if (range.count > 0) {
            let cssClassList = [];
            let cssClassCountList = {};
            let startedMarkerSet = new Set();

            for (let i = 0; i < range.cssClass.length; i++) {
                const clazz = range.cssClass[i];
                const id = range.id[i];

                if (!cssClassCountList.hasOwnProperty(clazz)) {
                    cssClassCountList[clazz] = 1;
                    cssClassList.push(clazz);
                }
                else {
                    cssClassCountList[clazz]++;
                }

                if (clazz === HIGHLIGHT_CSS_CLASS_NAME) {
                    const metaTag = range.element[i];
                    if (metaTag !== undefined && metaTag.comments !== undefined && metaTag.comments.length > 0) {
                        startedMarkerSet.add(clazz + "-with-comments");
                    }
                }

                const started = clazz + "-started-" + id;
                if (!renderedMetaTagMap.has(started)) {
                    renderedMetaTagMap.set(started, true);
                    startedMarkerSet.add(started);
                    startedMarkerSet.add(clazz + "-started");

                    if (clazz === HIGHLIGHT_CSS_CLASS_NAME) {
                        const metaTag = range.element[i];
                        if (metaTag !== undefined && metaTag.comments !== undefined && metaTag.comments.length > 0) {
                            startedMarkerSet.add(clazz + "-has-comments");
                            startedMarkerSet.add(clazz + "-has-comments-" + id);
                        }
                    }
                }
            }

            for (var ci = 0 ; ci < range.id.length; ci+=1)
                cssClassList.push ('meta-'+range.id[ci])

            let cssClass  = cssClassList.join(' ')
            let cssClassCounts = '';
            for (var clazz in cssClassCountList) {
                if (cssClassCountList.hasOwnProperty(clazz)) {
                    let count = cssClassCountList[clazz];
                    cssClassCounts += ` ${clazz}-${count}`;
                }
            }

            if (range.type[0] === MetaTagType.HIGHLIGHT &&
                selections != null && selections.highlightToPaint !== null && selections.highlightToPaint !== undefined &&
                range.id.indexOf(Number(selections.highlightToPaint)) > -1
            ) {
                cssClassCounts += " highlight-painted";
            }

            return `<${metaTagElement} class="${basicMetaTagCssClass} ${cssClass} ${cssClassCounts} ${Array.from(startedMarkerSet).join(" ")}" data-metatag="${range.id.join(',')}" ${renderTooltip(range)}>${range.text}</${metaTagElement}>`
        }
        else {
            return range.text
        }
    }

    function renderUnpairedMetaTag(tag) {
        let valAttr;
        var tsid = '';
        if (tag.value !== undefined && tag.value !== null && tag.value !== '') {
            valAttr = `data-metatag-value=${tag.value}`;
            if ((typeof(tag.value) === 'string') && (tag.value.includes ('id')))
                tsid = tag.value
        }
        else {
            valAttr = '';
        }

        let startedMarker = "";
        if (tag.id !== undefined) {
            const started = tag.cssClass + "-started-" + tag.id;
            if (!renderedMetaTagMap.has(started)) {
                renderedMetaTagMap.set(started, true);
                startedMarker = started + " " + tag.cssClass + "-started";
            }
        }
        
        return `<${metaTagElement} id="${tsid}" class="${basicMetaTagCssClass} ${tag.cssClass} ${startedMarker}" data-metatag="${tag.id !== undefined ? tag.id : ''}" ${valAttr} ${renderTooltip(tag)}></${metaTagElement}>`
    }

    function renderTooltip(range) {
        if (range.tooltip)
            return range.tooltip && range.tooltip.length > 0
                ? ` data-tip="${Array.isArray(range.tooltip) ? range.tooltip.join('<br/>') : range.tooltip}" data-for="meta-tag-tooltip" `
                : '';
        // data-event="click focus"
    }

    function addDefaultTooltips(tags) {
        for (let tag of tags) {
            if (tag.tooltip === undefined || tag.tooltip === '') {
                tag['tooltip'] = `${tag.type.toString().toLowerCase()}-id: ${tag.id}`;
            }
        }
        return tags;
    }


    function collectMetaTags(meta) {
        let pairedList   = [],
            unpairedList = [],
            factory      = new MetaTagFactory();

        for (var name in meta) {
            if (meta.hasOwnProperty(name)) {
                let list = meta[name];
                if (MetaTagListDescriptors.hasOwnProperty(name)) {
                    let descriptor = MetaTagListDescriptors[name]
                    for (let element of list) {
                        try {
                            let tag = factory.create(element, descriptor)
                            if (descriptor.kind === MetaTagKind.PAIRED) {
                                pairedList.push(tag)
                            } else {
                                unpairedList.push(tag)
                            }
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        }

        return [pairedList, unpairedList]
    }


    function flattenRanges(ranges) {
        var points = [];
        var flattened = [];

        for (var i in ranges) {
            // re-order this item (start/end)
            if (ranges[i].end < ranges[i].start) {
                // re-order by swapping
                var tmp = ranges[i].end;
                ranges[i].end = ranges[i].start;
                ranges[i].start = tmp;
            }
            points.push(ranges[i].start);
            points.push(ranges[i].end);
        }

        // make sure our list of points is in order
        points.sort(function (a, b) {
            return a - b
        });

        // find the intersecting spans for each pair of points (if any)
        // also merge the attributes of each intersecting span, and increase the count for each intersection
        for (i in points) {
            if (i === 0 || points[i] === points[i - 1]) continue;

            var includedRanges = ranges.filter(function (x) {
                return (Math.max(x.start, points[i - 1]) < Math.min(x.end, points[i]));
            });

            if (includedRanges.length > 0) {
                var flattenedRange = {
                    start: points[i - 1],
                    end: points[i],
                    count: 0
                };

                for (var j in includedRanges) {
                    var includedRange = includedRanges[j];
                    for (var prop in includedRange) {
                        if (prop !== 'start' && prop !== 'end') {
                            if (!flattenedRange[prop]) flattenedRange[prop] = [];
                            flattenedRange[prop].push(includedRange[prop]);
                        }
                    }
                    flattenedRange.count++;
                }

                flattened.push(flattenedRange);
            }
        }
        return flattened;
    }


    function inflateRanges(ranges, length = 0) {
        if (ranges.length === 0)
            return [{
                start: 0,
                end: length - 1,
                count: 0
            }]

        var inflated = [];
        var lastIndex;

        for (var i in ranges) {
            if (i == 0) {
                // if there is empty text in the beginning, create an empty range
                if (ranges[i].start > 0) {
                    inflated.push({
                        start: 0,
                        end: ranges[i].start - 1,
                        count: 0
                    });
                }

                inflated.push(ranges[i]);
            }
            else {
                if (ranges[i].start == ranges[i - 1].end) {
                    ranges[i - 1].end--;
                }

                if (ranges[i].start - ranges[i - 1].end > 1) {
                    inflated.push({
                        start: ranges[i - 1].end + 1,
                        end: ranges[i].start - 1,
                        count: 0
                    });
                }

                inflated.push(ranges[i]);
            }

            lastIndex = ranges[i].end;
        }

        // for simplicity, add any remaining text as an empty range
        if (lastIndex + 1 < length - 1) {
            inflated.push({
                start: lastIndex + 1,
                end: length - 1,
                count: 0
            })
        }
        return inflated;
    }


    function fillRanges(ranges, text) {
        for (var i in ranges) {
            ranges[i].text = text.slice(ranges[i].start, ranges[i].end + 1);
        }
        return ranges;
    }


    function populateUnpairedTags(ranges, unpaired) {
        unpaired.sort(function (a, b) {
            return a.position - b.position
        });

        let lastRangeIndex = ranges.length-1;
        for (let i = unpaired.length - 1; i >= 0; i--) {
            let tag = unpaired[i];
            for (let k = lastRangeIndex; k >= 0; k--) {
                let range = ranges[k];
                if (range.end > tag.position) {
                    lastRangeIndex = k;
                    range.text = range.text.substring(0, tag.position - range.start)
                        + renderUnpairedMetaTag(tag)
                        + range.text.substring(tag.position - range.start, range.text.length)
                }
            }
        }

        return ranges;
    }

    function render(html, meta, selections, addDefaultTooltipsFlag = false) {

        let [paired, unpaired] = collectMetaTags(meta);
        if (addDefaultTooltipsFlag) {
            paired   = addDefaultTooltips(paired);
            unpaired = addDefaultTooltips(unpaired);
        }

        var flatRanges      = flattenRanges(paired);
        var inflatedRanges  = inflateRanges(flatRanges, html.length);
        var filledRanges    = fillRanges(inflatedRanges, html);


        var filledRangesPopupatedWithUnpairedTags = populateUnpairedTags(filledRanges, unpaired);
        let ranges = filledRangesPopupatedWithUnpairedTags;

        var str = "";

        if (ranges.length === 0) {
            return html;
        }

        for (var i in ranges) {
            str += renderPairedMetaTag(ranges[i], selections);
        }

        return str;
    }

    return Object.freeze({
        render: function (html, meta, selections, addDefaultTooltipsFlag = false) {
            return render(html, meta, selections, addDefaultTooltipsFlag);
        }
    });
}

export default {
    render: function (html, meta, selections, addDefaultTooltips = false) {
        let metaRenderer = new MetaRenderer();
        return  metaRenderer.render(html, meta, selections, addDefaultTooltips);
    }
}
