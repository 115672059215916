// react
import React, {Component} from "react";
import ReactDOM from "react-dom";

// styles
import '../css/DocComments.scss';

// positioning stuff
import withContentRect from 'react-measure/lib/with-content-rect';
import windowDimensions from 'react-window-dimensions';
import _debounce from 'lodash.debounce';
import {getContainer, getScroller} from "../utils/docViewerChildrenUtils";
import wrapPortal, {getVisibleSelectionRect} from "../utils/componentPositionUtils";

// redux
import {connect} from "react-redux";

// app stuff
import {DocViewerListenerFactory} from "../utils/DocViewerEventFactory";
import MetaManager from "../meta/MetaManager";
import DocCommentForm from "./DocCommentForm";


class DocCommentDialog extends Component {

    state = {
        open: false,
        containerTop: null,
        elementOffsetTop: 0,
        style: {},
        dimensions: null,
        comment: '',
        highlightId: null,
        selectionText: null,
        container: null
    }


    componentDidMount() {
        let listenerFactory = DocViewerListenerFactory(ReactDOM.findDOMNode(this.props.containerRef.current));
        listenerFactory.createAddCommentListener((e) => {
            this.openDialog(e);
        })

        listenerFactory.createCloseOthersListener((e) => {
            this.closeDialog(e);
        })

        listenerFactory.createCloseCommentDialogListener((e) => {
            this.closeDialog(e);
        })

        let container  = getContainer(this.props.containerRef)
        if (!container) {
            return
        }

        // outer click event listener
        container.addEventListener("click", (e) => {
            this.closeDialog(e);
        });

        // event listener to offset top position on scroll
        getScroller(container).addEventListener("scroll", (e) => {
            if (this.state.containerTop != null) {
                this.setState({
                    elementOffsetTop: this.props.containerRef.current.getBoundingClientRect().top - this.state.containerTop,

                })
            }
        });

        this.setState({
            container: container
        })
    }

    componentWillReceiveProps(prevProps, prevState) {
        return null
    }

    openDialog(e) {
        let state = {
            open: true,
            containerTop:  this.props.containerRef.current.getBoundingClientRect().top,
        }

        if (e.detail !== undefined && e.detail.highlightId !== undefined && e.detail.highlightId !== null) {
            state.highlightId = e.detail.highlightId;

            if (e.detail.rect !== null) {
                state.rect = e.detail.rect;
            }
        }
        else {
            state.selectionText = global.getSelection().toString()
            state.highlightId = null;
        }

        this.setState(state);
    }

    closeDialog(e) {
        this.setState({
            open: false
        })
    }

    getTargetRect() {
        let rect;
        if (this.state.highlightId !== null) {
            let highlights = this.state.container.getElementsByClassName('highlight')

            // find last highlight with ID given
            let highlight = null
            for (let i = 0; i < highlights.length; i++) {
                let _highlight = highlights[i];

                let idList = MetaManager.getElementMetaIdList(_highlight)
                if (idList.indexOf(this.state.highlightId) >= 0) {
                    highlight = _highlight
                }
            }

            if (highlight !== null) {
                rect = highlight.getBoundingClientRect();
            }

            // if highlight is not found then it could probably be annotation
            highlight = MetaManager.findHighlightById(this.props.loadedDoc.meta.annotations, this.state.highlightId)
            if (highlight !== null && this.state.rect !== null && this.state.rect !== undefined) {
                rect = this.state.rect;
            }
        }
        else {
            rect = getVisibleSelectionRect(window);
        }

        return rect;
    }

    printRect(rect) {
        if (rect != null) {
            console.log(`top: ${rect.top}, left: ${rect.left}, width: ${rect.width}, height: ${rect.height}`);
        }
    }

    render() {
        let cssClass = `doc-comment-add-dialog ${this.state.open ? 'open' : ''}`

        var targetRect = this.getTargetRect();
        const {measureRef} = this.props;

        var selection = global.getSelection();

        let style;
        var selectionText = this.state.selectionText
        if (targetRect != null && (!selection.isCollapsed || this.state.highlightId !== null)) {
            var text = "";
            if (window.getSelection) {
                text = window.getSelection().toString();
            } else if (document.selection && document.selection.type !== "Control") {
                text = document.selection.createRange().text;
            }
            if (text !== '') {
                selectionText = text;
            }

            style = this.props.placementStrategy({
                gap: 5,
                frameWidth: this.props.windowWidth,
                frameHeight: this.props.windowHeight,
                frameLeft: 0,
                frameTop: 0,
                boxWidth: this.props.contentRect.bounds.width,
                boxHeight: this.props.contentRect.bounds.height,
                selectionTop: targetRect.top,
                selectionLeft: targetRect.left,
                selectionWidth: targetRect.width,
                selectionHeight: targetRect.height
            });
        }
        else {
            style = this.state.style;
        }

        return(
            <div className={cssClass} style={style} onClick={(e) => {e.stopPropagation();}} ref={measureRef}>
                <DocCommentForm hasCancel={true} containerRef={this.props.containerRef.current} highlightId={this.state.highlightId}
                                projectId={this.props.projectId} selectionText={selectionText}/>
            </div>
        )
    }
}



var wrapped = wrapPortal(withContentRect("bounds", "offset")(windowDimensions({
    take: function take() {
        return {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };
    },
    debounce: function debounce(onResize) {
        return _debounce(onResize, 120);
    }
})(DocCommentDialog)));


function mapStateToProps(state) {
    return {
        loadedDoc: state.projects.loadedDoc,
        savedDocs: state.projects.savedDocs,
    }
}

export default connect(mapStateToProps)(wrapped);


