import React, { Component } from 'react';
import { connect } from 'react-redux'

import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import localConfig from '../config/config'


import '../css/TermsAccept.scss';

import {
    acceptTerms
  } from '../redux/actionsProjects'

  import {
    onShowTerms,
  } from '../redux/actions'
  

class TermsAccept extends Component
{
    state = { 'cbclicked':false }
    render() {    
        if ((this.props.projects && this.props.projects.company && (this.props.projects.company.acceptedTerms === false)) || (this.props.shouldShowTerms))
            return (
                <div className="acceptTerms">
                    <div className="fullSize">
                        <div>
                            <Scrollbar noScrollX={true}>
                                <Container fluid className="stdPadded stdTopPadded">
                                    <Row>
                                        <Col md={12}>
                                            <h3>Welcome to EisphorIA!</h3>
                                            <p>We would like to propose you a perfect tool. In order to use it, you have to reiterate your acceptance on our general <a href="https://www.eisphoria.com/policies/terms/" target="_blank" rel="noreferrer noopener">Terms and Conditions</a> and have to acknowledge the disclaimers of the following sources (from which the Data has been extracted)</p>
                                        </Col>
                                    </Row>
                                    <div className="vspace"></div>
                                    <Row>
                                        {localConfig.SPECIAL_TERMS}
                                    </Row>
                                    <Row>
                                        {this.props.shouldShowTerms ? 
                                            <Col md={12}>
                                                <button onClick={(e)=>{e.stopPropagation(); this.props.dispatch(onShowTerms(false))}} >Close</button>
                                            </Col> :
                                            <Col md={12}>
                                                <label htmlFor="readCB" className="cbcontainer" onClick={(e) => {e.stopPropagation(); this.setState ({cbclicked:!this.state.cbclicked})}}>I have read and accept the above
                                                    <input readOnly={true} type="checkbox" checked={this.state.cbclicked} name="readCB" />
                                                    <span className="checkmark"></span>
                                                </label>
    
                                                <button onClick={(e)=>{
                                                    e.stopPropagation(); 
                                                    this.props.dispatch(acceptTerms())
                                                    }} disabled={!this.state.cbclicked} className={!this.state.cbclicked ? "disabled":""}>Accept</button>
                                            </Col>
                                        }
                                    </Row>
                                </Container>
                            </Scrollbar>  
                        </div>                  
                    </div>
                </div>
            );
        else return null
    }

}

function mapStateToProps(state) {
    return {
      projects: state.projects,
      shouldShowTerms: state.globalInterface.shouldShowTerms
    }
}
  
export default connect(mapStateToProps)(TermsAccept)
