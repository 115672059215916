// react
import React, {Component} from 'react'
import ReactDOM from "react-dom";

// styles
import '../css/DocComments.scss';

// positioning
import {getContainer} from "../utils/docViewerChildrenUtils";
import wrapPortal from "../utils/componentPositionUtils";

// redux
import { connect } from 'react-redux'
import {removeComment, setModifiedHighlightId, setModifiedCommentId} from '../redux/actionsProject'

// components
import Scrollbar from 'react-scrollbars-custom';
import {Dropdown} from 'react-bootstrap';
import Moment from 'react-moment';

// images
import { ReactComponent as DotsImg} from '../img/ic_3dots.svg'
import { ReactComponent as DeleteImg} from '../img/ic_delete.svg'

// app staff
import {DocViewerListenerFactory} from "../utils/DocViewerEventFactory";

// component specific
import Avatar from "react-avatar";
import MetaManager from "../meta/MetaManager";
import DocCommentForm from "./DocCommentForm";



class DocComment extends Component {

    handleClick = e => {
        e.stopPropagation()
    }

    handleDelete = e => {
        this.props.deleteHandler(this.props.comment.id)
    }

    renderDropdown() {
        if (this.props.username === this.props.comment.author/* || true*/) {
            return (
                <Dropdown drop={"down"}>
                    <Dropdown.Toggle variant="light" className="">
                        <DotsImg alt="options" className="dots-alone" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu alignRight="true" flip={false}>
                        <Dropdown.Item onClick={this.handleDelete}><DeleteImg alt="Delete" /> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        }

        return ""
    }

    render() {
        return (
            <div className={'doc-comment'} onClick={this.handleClick}>
                <div className="comment-header">
                    <div className="avatar">
                        <Avatar name={this.props.comment.author.split('@')[0].split('.').join(' ')} size={38} round={true}/>
                    </div>

                    <div className="name-and-date">
                        <div className="name">{this.props.comment.author}</div>
                        <div className="date">
                            <Moment format="DD MMMM YYYY HH:mm">
                                {this.props.comment.saved}
                            </Moment>
                        </div>
                    </div>
                    <div className="menu">
                        {this.renderDropdown()}
                    </div>
                </div>
                <div className="comment-body">
                    {this.props.comment.text}
                </div>
            </div>
        )
    }
}

class DocComments extends Component {

    state = {
        open: false,
        highlightId: null
    }

    componentDidMount() {
        let listenerFactory = DocViewerListenerFactory(ReactDOM.findDOMNode(this.props.containerRef.current));
        listenerFactory.createShowCommentsListener((e) => {
            this.openComments(e);
        })

        listenerFactory.createCloseOthersListener((e) => {
            this.closeComments(e);
        })

        let container  = getContainer(this.props.containerRef)
        if (!container) {
            return
        }

        // outer click event listener
        container.addEventListener("click", (e) => {
            this.closeComments(e);
        });

        this.setState({
            container: container
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ((nextProps.modifiedHighlightId !== undefined) && (nextProps.modifiedHighlightId !== null)) {
            this.setState({
                open: true,
                highlightId: nextProps.modifiedHighlightId
            })
            this.props.dispatch (setModifiedCommentId(null))
            this.props.dispatch (setModifiedHighlightId(null))
        }
    }

    openComments(e) {
        this.setState({
            open: true,
            highlightId: parseInt(e.detail.highlightId)
        })
    }

    closeComments() {
        this.setState({
            open: false
        })
    }

    getComments() {
        return MetaManager.findCommentsInMeta(this.props.loadedDoc.meta, this.state.highlightId)
    }

    deleteComment(id) {
        // this.closeComments();
        this.props.dispatch(removeComment(id))
    }

    render() {
        let cssClass = `doc-comments-container ${(this.state.open && (this.getComments().length > 0)) ? 'open' : ''}`
        return (
            <div className={cssClass} onClick={this.closeComments.bind(this)}>
                <div className="doc-comments-overlay">&nbsp;</div>
                <div className="doc-comments">
                    <Scrollbar noScrollX={true} onClick={(e) => {e.stopPropagation();}}>
                        {this.getComments().map((comment, i) => {
                            return <DocComment username={this.props.username} comment={comment} key={'comment-'+i} deleteHandler={this.deleteComment.bind(this)}/>
                        })}
                        <DocCommentForm hasCancel={false} containerRef={this.props.containerRef.current} highlightId={this.state.highlightId} />
                    </Scrollbar>
                </div>
            </div>
        )
    }
}

var wrapped = wrapPortal(DocComments);
function mapStateToProps(state) {
    return {
        username: state.authParams.username,
        loggedIn: state.authParams.loggedIn,
        loadedDoc: state.projects.loadedDoc,
        modifiedHighlightId: state.projects.modifiedHighlightId
    }
}
export default connect(mapStateToProps)(wrapped)
