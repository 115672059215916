import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Avatar from 'react-avatar';


import '../css/main.scss'


import NavHeader from "../interfaceParts/NavHeader"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import TermsAccept from "../interfaceParts/TermsAccept"

import '../css/CompanyPage.scss'

import {
  checkLoggedIn
} from '../redux/actionsAuth'

import {
  setUser,
  getCompany,
  createUser,
  resetUserPassword
} from '../redux/actionsProjects'

class CompanyPage extends Component {
  componentWillMount() {
    this.props.dispatch(checkLoggedIn())
    this.props.dispatch(getCompany())
  }

  state = {
    newUserName: "",
    newUserEmail: "",
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.dispatch (createUser(this.state.newUserEmail, this.state.newUserEmail))
  };


  render() {
    if (this.props.shouldRedirectToLogin)
        return (<Redirect to='/' />)

    var that = this

    if (this.props.company.company)
    {
        var users_items = this.props.company.users.map(function (d) { 
            return <Row key={d.upid} className="userLine">
                  <Col md={5}><Avatar name={d.login.split('@')[0].split('.').join(' ')} size={31} round={true}/><span>{d.login}</span></Col>
                  <Col md={1}><span>{d.isAdmin ? "Admin" : "User"}</span></Col>
                  {that.props.company.isAdmin ? <Col className="alignRight" md={6}>
                        <button className="lightBtn lowPadding" onClick={() => that.props.dispatch (resetUserPassword (d.login)) }>Reset password</button>
                        {d.isAdmin ? <button className="lightBtn lowPadding" onClick={() => that.props.dispatch (setUser (d.upid, true, false)) }>Revoke admin</button> : <button className="lightBtn lowPadding" onClick={() => that.props.dispatch (setUser (d.upid, true, true)) }>Make admin</button>}
                        <button className="lightBtn lowPadding" onClick={() => that.props.dispatch (setUser (d.upid, false, false)) }>Disable</button>
                    </Col> : null}
                </Row>
        }) 

        var d_users_items = this.props.company.disabledUsers.map(function (d) { 
            return <Row key={d.upid} className="userLine">
                    <Col md={7}><Avatar name={d.login.split('@')[0].split('.').join(' ')} size={31} round={true}/><span>{d.login}</span></Col>
                    <Col md={1}><span>{d.isAdmin ? "Admin" : "User"}</span></Col>
                    {that.props.company.isAdmin ? <Col className="alignRight" md={4}><button  className="lightBtn lowPadding" onClick={() => that.props.dispatch (setUser (d.upid, true, d.isAdmin)) }>Enable</button></Col> : null}
                </Row>
        }) 
    }

    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    
    return (
      <div className="App">
          <NavHeader/>
          {error}
          <div className="companyPanel">
            <div className="fullSize" >
                <Scrollbar noScrollX={true}>
                    
                    {this.props.company.company ? 
                        <Container fluid className="stdPadded stdTopPadded">
                            <Row>
                                <Col md={12}>
                                    <div className="panel nuser">
                                        <h3>Create a new user:</h3>
                                        <form onSubmit={this.handleSubmit}>
                                            <input
                                                type="email"
                                                className="input"
                                                name="newUserEmail"
                                                onChange={this.handleChange}
                                                placeholder="e-mail"
                                                value={this.state.newUserEmail}
                                                required
                                                />  
                                            <button type="submit" className="button is-info">Create</button>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                            { this.props.company.users.length > 0 ? 
                                <div>
                                    <Row><Col md={12}><h3>Users</h3></Col></Row>
                                    {users_items}
                                </div> : null }
                            { this.props.company.disabledUsers.length > 0 ? 
                                <div>
                                    <Row><Col md={12}><h3>Disabled users</h3></Col></Row>
                                    {d_users_items}
                                </div> : null } 
                    </Container>
                    :
                    <Container><Row><Col md={12}><h3>You are an independent user, not participating in any company</h3></Col></Row></Container>}  
                </Scrollbar>
            </div>
          </div>
          
          <TermsAccept/>
          <LoadingSpinner show={this.props.loading} />
          
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.authParams.error,
    loggedIn: state.authParams.loggedIn,
    loading: state.globalInterface.loading,
    shouldRedirectToLogin: state.authParams.shouldRedirectToLogin,
    company: state.projects.company
  }
}


export default connect(mapStateToProps)(CompanyPage)


