import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Dropdown} from "react-bootstrap";


import Avatar from 'react-avatar';

import ProjectShare from './ProjectShare'
import ProjectProperties from './ProjectProperties'
import Helper from './Helper'

import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'

import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'


import {
    onShowTerms,
    setURL
  } from '../redux/actions'

import {
  logout
} from '../redux/actionsAuth'

import {
    setEditingProject
  } from '../redux/actionsProjects'

import {
    onShowQueryForm,
    onShowSavedDocs,
    onShowWatches,
    onShowActivity,
    setLoadedDoc,
    setQuery,
    setBrowsingDoc,
    setReadNotification,
    onProjectParamsLoaded,
    closeDocument
} from '../redux/actionsProject'

import '../css/NavHeader.scss';
import { ReactComponent as Logo} from '../img/ic_logo.svg'
import { ReactComponent as LogoSmall} from '../img/ic_logo_small.svg'
import { ReactComponent as SearchIcon} from '../img/ic_search.svg'
import { ReactComponent as SavedIcon} from '../img/ic_saved.svg'
import { ReactComponent as WatchesIcon} from '../img/ic_watches.svg'
import { ReactComponent as ActivityIcon} from '../img/ic_activity.svg'

import { ReactComponent as BellMenuIcon} from '../img/ic_bellMenu.svg'
import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";

import { ReactComponent as CommentNotificationIcon} from '../img/ic_comment-notification-icon.svg'
import { ReactComponent as WatchNotificationIcon} from '../img/ic_watch-notification-icon.svg'


class NavHeader extends Component {
    state={notificationTab: 'Notifications'}
    constructorName = "NavHeader"
    
    render()
    {
        var that = this
        //var ug = <li><a href="https://eisphoria.com/static/docs/UserGuide.pdf" rel="noopener noreferrer" target="_blank">User guide</a></li>
        //var hsep = <span className="hSeparator"></span>              
        if (!this.props.loggedIn)
            return null

        var showNotifications = this.props.projects && this.props.projects.company && this.props.projects.company.notifications && (this.props.projects.company.notifications.length > 0)
        var tabs = ['Notifications', 'Watch'].map ((d) => { return <button key={"tab"+d} className={"tbutton " + ((that.state.notificationTab === d) ? "active":"")} onClick={(e) => { that.setState ({notificationTab:d})}}>{d}</button>})
        var unreadNotifications = false
        
        var notificationCards = null
        if (showNotifications)
        {
            for (var i = 0; i < this.props.projects.company.notifications.length; i+=1) {
                if (!this.props.projects.company.notifications[i].read)
                    unreadNotifications = true
            }
    
            var shownCards = 0
            notificationCards = this.props.projects.company.notifications.map ((notification) => {
                if ((notification.type === 'watch' && that.state.notificationTab !== 'Watch') || ((notification.type !== 'watch' && that.state.notificationTab === 'Watch')))
                    return null
                shownCards += 1
                var imgSrc = <WatchNotificationIcon className="notificationIcon"/>
                var action    
                if (notification.type === 'watch')
                {
                    action = (e) => { 
                        that.props.dispatch (setLoadedDoc (null))
                        that.props.dispatch (onProjectParamsLoaded(notification.params.watch))
                        that.props.dispatch (setURL ('/project/' + notification.params.watch.project.id))
                        that.props.dispatch (setQuery(notification.params.watch.query));
                        that.props.dispatch (onShowQueryForm())
                        that.props.dispatch (setBrowsingDoc(notification.params.doc.id, null, []));
                        that.props.dispatch (setReadNotification(notification.id))
                    }
                    return <div key={"notificationCard" + notification.id} className={"notificationCard " + (notification.read ? "read ":"unread ")} onClick={action}>
                        <div>
                            {imgSrc}
                            <div className="desc">
                                <Row>
                                    <Col md={7} >{notification.text}</Col>
                                    <Col md={5} className="alignRight">{notification.created}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}>Project</Col>
                                    <Col md={10}>{notification.params.watch.project.name}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}>Document</Col>
                                    <Col md={10}>{notification.params.doc.caption}</Col>
                                </Row>
                                
                            </div>
                        </div>
                    </div>
                }
                else 
                {
                    if (notification.type !== 'watch')
                    {
                        if (!notification.params.color)
                            imgSrc = <CommentNotificationIcon  className="notificationIcon"/>
                        else 
                            imgSrc = <span className="notificationIconContainer" style={{color:notification.params.color}}><ImgFlag /></span>
                    }
                    action = (e) => { 
                        that.props.dispatch (setLoadedDoc (null))
                        that.props.dispatch (onProjectParamsLoaded(notification.params))
                        that.props.dispatch (setURL ('/project/' + notification.params.project.id))
                        that.props.dispatch (setQuery(notification.params.query));
                        that.props.dispatch (onShowQueryForm())
                        that.props.dispatch (setBrowsingDoc(notification.params.docID, null, []));
                        that.props.dispatch (setReadNotification(notification.id))
                    }
                    return <div key={"notificationCard" + notification.id} className={"notificationCard " + (notification.read ? "read ":"unread ")} onClick={action}>
                        <div>
                            {imgSrc}
                            <div className="desc">
                                <Row>
                                    <Col md={7} style={{color:notification.params.color}}>{notification.text}</Col>
                                    <Col md={5} className="alignRight">{notification.created}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}>By</Col>
                                    <Col md={10}>{notification.by}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}>Project</Col>
                                    <Col md={10}>{notification.params.project.name}</Col>
                                </Row>
                                <Row>
                                    <Col md={2}>Document</Col>
                                    <Col md={10}>{notification.params.docName}</Col>
                                </Row>
                                
                            </div>
                        </div>
                    </div>
                }
            })
            if (shownCards === 0)
                notificationCards = <h4>There are no notifications at the moment</h4>
        }

        return (
            <Container fluid className="nheader">
                { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                    <Joyride
                        tooltipComponent={JoyrideTooltip}
                        steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                        styles={{options: globalConfig.JOYRIDE.options}}
                        disableCloseOnEsc={true}
                        disableOverlayClose={true}
                        disableOverlay={true}
                        disableScrolling={true}
                        showSkipButton={true}
                        hideBackButton={true}
                        run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                        locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                        continuous={false}
                        stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                        callback = { data => {
                                const { action, type } = data;
                                if (action === ACTIONS.SKIP)
                                    this.props.dispatch (setTutorialElements(null))
                                if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                {
                                    //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                    //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                    this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                }
                            
                                
                                
                            }
                        }
                    />
                    :null
                }
                <Row>
                    <Col md={12} >
                        <Link to="/">{ (this.props.projects.currentProject && this.props.projectId) ?  <span className="logoSmallContainer"><span className="arrow">◄</span><LogoSmall className="logo logosmall"/></span> : <Logo className="logo"/>}</Link>
                        { (this.props.projects.currentProject && this.props.projectId) ? 
                            <span>
                                <button className="transp projectBtn"  onClick={
                                    (e) => 
                                        { 
                                        this.props.dispatch (setEditingProject(this.props.projects.currentProject))
                                        }}>
                                            <span className="projectName">{this.props.projects.currentProject.name}</span>
                                            
                                </button>
                            </span>
                                            :null }

                        {/* <button onClick={() => this.props.handleGlobalStateChange({selectedProjectId: -1})}>Close project</button> */}
                        
                        { (this.props.projects.currentProject && this.props.projectId) ? 
                            <div className="projectNavigation">
                                <button className={this.props.interface.queryFormVisible ? 'active searchBtn':'searchBtn'} onClick={(e) => {this.props.dispatch(closeDocument());
                                                                                                                                            if (this.props.projects.docs && (this.props.projects.docs.length > 0))
                                                                                                                                            {
                                                                                                                                                var netDocs = [...this.props.projects.docs]
                                                                                                                                                netDocs.sort((a,b) => (a.score > b.score) ? -1 : 1);
                                                                                                                                                var d = netDocs[0]
                                                                                                                                                this.props.dispatch (setBrowsingDoc(d.ref, d.language, []))
                                                                                                                                            } 
                                                                                                                                            this.props.dispatch (onShowQueryForm(true)); }}><SearchIcon/> Search</button>
                                <button className={this.props.interface.watchesVisible ? "active":""} onClick={(e) => {this.props.dispatch (onShowWatches(true)); }}><WatchesIcon/> Watch list <span className="counter">{(this.props.projects.currentProject && this.props.projects.currentProject.watches) ? this.props.projects.currentProject.watches.length  : 0}</span></button>
                                <button className={this.props.interface.savedDocsVisible ? 'active':''} onClick={(e) => {this.props.dispatch (onShowSavedDocs(true));  }}><SavedIcon/> Saved <span className="counter">{ this.props.projects.currentProject.savedDocsNumber}</span></button>
                                <button className={this.props.interface.activityVisible ? 'active':''} onClick={(e) => {this.props.dispatch (onShowActivity(true)); }}><ActivityIcon/> Activity</button>
                                

                            </div>

                            :
                            
                            ((this.props.projects.company && this.props.projects.company.trialEndIn) ?
                                <div className="trialEnd">
                                    <span>Trial ends {this.props.projects.company.trialEndIn < 3600 ? "soon!" : ("in " + Math.floor(this.props.projects.company.trialEndIn / 3600) + " hours")}</span>
                                    <a className="button" target="_blank" href="https://hub.eisphoria.com/" rel="noreferrer noopener">Subscribe now</a>

                                </div> : null)
                            }

                        <div className="rightNav alignRight">
                    
                            {(localConfig.INDEX_BROWSER) ? <Link className="button transp noText " to="/index/">Index</Link> : null}
                            {showNotifications ? 
                            <Dropdown  drop={"down"} alignRight key={"notificationsMenu"} id={"notificationsMenu-container"} className="notificationsMenuContainer">
                                <Dropdown.Toggle variant="light" className="notificationsMenuBtn">
                                    <BellMenuIcon/>
                                    {unreadNotifications ? <span className="unreadNotificationsPin"></span>:null}
                                </Dropdown.Toggle>
                                <Dropdown.Menu flip={false} className="optionsMenu withShadow alignLeft">
                                    <div className="head">
                                        {tabs}
                                    </div>
                                    {notificationCards}
                                </Dropdown.Menu>
                            </Dropdown>
                            :null}
                            <Dropdown drop={"down"} key={"profileMenu"} id={"profileMenu-container"} className="profileMenuContainer" >
                                <Dropdown.Toggle variant="light" className="profileMenuBtn" >
                                    <Avatar name={this.props.username.split('@')[0].split('.').join(' ')} size={29} round={true}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu flip={false} className="optionsMenu withShadow alignLeft">
                                    <button onClick={(e) => { this.props.dispatch(onShowTerms(true));}}>Terms and Conditions</button>
                                    <a className="button" href="https://eisphoria.com/contact/" target="_blank" rel="noreferrer noopener">Contact us</a>
                                    <a className="button" href="https://hub.eisphoria.com" target="_blank" rel="noreferrer noopener">Data Hub</a>
                                    <button onClick={(e) => { e.stopPropagation(); this.props.dispatch(logout())}}>Logout</button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        
                    </Col>
                </Row>
                { this.props.projects.sharingProject !== null ? <ProjectShare /> : null }
                { this.props.projects.editingProject !== null ? <ProjectProperties /> : null }
                <Helper />
            </Container>
            
        );
    }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authParams.loggedIn,
    username: state.authParams.username,
    projects: state.projects,
    interface: state.globalInterface,
    query: state.query
  }
}

export default connect(mapStateToProps)(NavHeader)
