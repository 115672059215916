import React, { Component } from 'react';
import { connect } from 'react-redux'

import helpImg from '../img/img_help.svg'
import contactImg from '../img/img_contact.svg'

import {
    setTutorialElements
} from '../redux/actions'



import '../css/GHelper.scss';

class GHelper extends Component
{
    render() {    
        var hParams = this.props.hParams ? this.props.hParams : {'ProjectSelector':0, "ProjectProperties":0, "ProjectShare":0, "QueryForm":0, "BrowserApp":0, "DocViewer":0, "SavedDocsBrowser":0, "ActivityBrowser":0}
    
        return <div className="GHelper">
                    <div className="cont">
                        <button onClick={(e) => { e.stopPropagation(); this.props.dispatch(setTutorialElements(hParams, false));}}><img src={helpImg} alt="helpImg"/></button>
                        <span className="hsep"></span>
                        <a href="https://eisphoria.com/contact/" target="_blank" rel="noreferrer noopener"><img src={contactImg} alt="contactImg"/></a>
                    </div>
            </div>
        
    }

}

function mapStateToProps(state) {
    return {
      
    }
}
  
export default connect(mapStateToProps)(GHelper)
