import Cookies from 'universal-cookie'
import localConfig from '../../config/config'


import { combineReducers } from 'redux'
import {
    LOADING,
    ON_ERROR,
    SHOW_QS,
    SET_TUTORIAL_ELEMENTS,
    SET_URL,
    SHOW_TERMS,
    SET_HELPER,
    SHOW_MI_HELPER
} from '../actions'

import {
    LOGGED_IN,
    LOGIN_FAILED,
    LOG_OUT,
    CHECK_LOGGED_IN,
    REFRESH_TOKEN,
    REGISTER_DEMAND_SENT,
    SHOULD_REDIRECT_TO_HOME
} from '../actionsAuth'

import {
    PROJECTS_LOADED,
    COMPANY_LOADED,
    SET_SHARING_PROJECT,
    SET_EDITING_PROJECT,
    SHOW_ONBOARDING,
    SHOULD_SHOW_MUST_INCLUDE_POPUP
} from '../actionsProjects'

import {
    CODES_TREE_LOADED,
    CODE_LOADED,
    SHOW_INDEX,
    FLAT_INDEX_LOADED,
    SET_FLAT_INDEX_READ_TYPE
} from '../actionsCodes'

import {
    SET_QUERY,
    SET_QUERY_LANGUAGE,
    CHANGE_QUERY,
    SET_CHANGED_FILTERS,
    SET_DATES_FILTER,
    SET_CDATES_FILTER,
    SET_GROUP_VERSIONS,
    ON_SEARCH_RESULTS_LOADED,
    ON_TIMELINE_OVERVIEW_LOADED,
    ON_QSSEARCH_RESULTS_LOADED,
    ON_QSSEARCH_QUERY_CHANGED,
    RESULTS_SHOULD_REDRAW,
    SHOW_QUERY_FORM,
    ON_PROJECT_PARAMS_LOADED,
    SHOW_SAVED_DOCS,
    SHOW_WATCHES,
    SET_BROWSING_DOC_ID,
    SET_BROWSING_DOC_ANCHOR,
    SHOW_RESULTS_MODE,
    DOC_LOADING,
    SET_LOADED_DOC,
    ADD_SELECTION,
    REMOVE_SELECTION,
    SET_TRANSLATION_STATE,
    SHOW_ACTIVITY,
    SET_MODIFIED_HIGHLIGHT_ID,
    SET_MODIFIED_COMMENT_ID,
    SHOULD_POST_SEARCH,
    ALTER_SAVED_DOC_ORDER_LOCALLY,
    SET_START_POSITION,
    SET_CURRENT_DOC_READ,
    ALTER_CURRENT_PROJECT_EDITOR,
    CLOSE_DOCUMENT,
    EXPAND_TIMELINE
} from '../actionsProject'

var editDistance = require ('edit-distance')
var insertED, removeED, updateED;
insertED = removeED = function(node) { return 1; };
updateED = function(stringA, stringB) { return stringA !== stringB ? 1 : 0; };
 

function safeArray (candidate)
{
    if (candidate)
        return candidate
    else
        return []
}

function safeDict (candidate)
{
    if (candidate)
        return candidate
    else
        return {}
}

function query(
    state = {
        query: '',
        selectionMust: [],
        selectionMustPositions: [],
        selectionExclude: [],
        selectionExcludePositions: [],
        withTranslation: 0,
        changedFilters: {},
        groupVersions: false,
        minDate: null,
        maxDate: null,
        permitEmptyDate: true,
        minCDate: null,
        maxCDate: null,
        permitEmptyCDate: true,
        shouldPostSearch: false,
        treatmentLanguage: null
    },
    action
) {
    var vstate
    switch (action.type) {
        case SHOULD_POST_SEARCH:
            return Object.assign({}, state, { shouldPostSearch: action.should })
        case SET_QUERY_LANGUAGE:
            return Object.assign({}, state, {treatmentLanguage:action.queryLanguage})
        case SET_QUERY:
            return Object.assign({}, state, {
                query: action.query.query,
                selectionMust: safeArray (action.query.selectionMust),
                selectionMustPositions: safeArray (action.query.selectionMustPositions),
                selectionExclude: safeArray (action.query.selectionExclude),
                selectionExcludePositions: safeArray (action.query.selectionExcludePositions),
                changedFilters: safeDict (action.query.changedFilters),
                //changedFilters: {},
                minDate: action.query.minDate,
                maxDate: action.query.maxDate,
                permitEmptyDate: action.query.permitEmptyDate,
                minCDate: action.query.minCDate,
                maxCDate: action.query.maxCDate,
                permitEmptyCDate: action.query.permitEmptyCDate,
                shouldPostSearch: false,
                treatmentLanguage: action.query.treatmentLanguage
            })
        case CHANGE_QUERY:
            vstate = Object.assign({}, state, {})
            
            var lev = editDistance.levenshtein(state.query, action.query, insertED, removeED, updateED);

            if (lev.distance > 0.9 * state.query.length)
                return Object.assign({}, state, {
                    query: action.query,
                    selectionMust: [],
                    selectionMustPositions: [],
                    selectionExclude: [],
                    selectionExcludePositions: [],     
                    shouldPostSearch: false       
                })


            var align = lev.alignment()

            
            align = [align.alignmentA, align.alignmentB]
            for (var i = 0; i < align[0].length; i += 1)
            {
                //console.log (i, '11111', pairs[i])
                if (align[0][i] === align[1][i])
                    continue
                if (align[0][i] === null)
                {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (var si = 0; si < vstate[selType].length; si += 1)
                        {
                            if (vstate[selType + 'Positions'][si] >= i)
                                vstate[selType + 'Positions'][si] += 1
                            else if (vstate[selType + 'Positions'][si] + vstate[selType][si].length > i)
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + align[1][i] + vstate[selType][si].slice(i - vstate[selType + 'Positions'][si])
                        } 
                }
                else if (align[1][i] !== null) {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (si = 0; si < vstate[selType].length; si += 1)
                        {
                            if ((vstate[selType + 'Positions'][si] <= i) && (vstate[selType + 'Positions'][si] + vstate[selType][si].length > i))
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + align[1][i] + vstate[selType][si].slice(1 + i - vstate[selType + 'Positions'][si])
                        } 
                }
            }
            for (i = align[0].length - 1; i >= 0; i -= 1)
            {
                if (align[0][i] === align[1][i])
                    continue
                if (align[1][i] === null)
                {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (si = 0; si < vstate[selType].length; si += 1)
                        {
                            if (vstate[selType + 'Positions'][si] > i)
                                vstate[selType + 'Positions'][si] -= 1
                            else if (vstate[selType + 'Positions'][si] + vstate[selType][si].length >= i)
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + vstate[selType][si].slice(1 + i - vstate[selType + 'Positions'][si])
                        } 
                }
            }

            for (const selType of ['selectionMust', 'selectionExclude'])
                for (si = vstate[selType].length - 1; si >= 0 ; si -= 1)
                    if (vstate[selType][si].trim().length === 0)
                    {
                        vstate[selType].splice (si, 1)
                        vstate[selType + 'Positions'].splice (si, 1)
                    }
                
            vstate['query'] = action.query
            return vstate
        case SET_CHANGED_FILTERS:
            return Object.assign({}, state, {
                changedFilters: safeDict (action.changedFilters),
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_DATES_FILTER:
            return Object.assign({}, state, {
                minDate: action.datesParams.minDate,
                maxDate: action.datesParams.maxDate,
                permitEmptyDate: action.datesParams.permitEmptyDate,
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_CDATES_FILTER:
            return Object.assign({}, state, {
                minCDate: action.datesParams.minCDate,
                maxCDate: action.datesParams.maxCDate,
                permitEmptyCDate: action.datesParams.permitEmptyCDate,
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_GROUP_VERSIONS:
            return Object.assign({}, state, {
                groupVersions: action.groupVersions
            })
        case ADD_SELECTION:
            vstate = Object.assign({}, state, {})
            vstate[action.selectionType].push (action.value)
            vstate[action.selectionType + 'Positions'].push (action.position)
            return vstate 
        case REMOVE_SELECTION:
            vstate = Object.assign({}, state, {})
            vstate[action.selectionType].splice (action.index, 1)
            vstate[action.selectionType + 'Positions'].splice (action.index, 1)
            return vstate 
        case SET_TRANSLATION_STATE:
            return Object.assign({}, state, {
                withTranslation: action.value
            })
        default:
            return state
    }
}

function codes (
    state = { codesTree: null, code: null, flatIndex:null, flatIndexReadType:'All' }, action
) {
    switch (action.type) {
        case CODES_TREE_LOADED:
            return Object.assign({}, state, { codesTree: action.codesTree })
        case CODE_LOADED:
                return Object.assign({}, state, { code: action.code })
        case FLAT_INDEX_LOADED:
            return Object.assign({}, state, { flatIndex: action.index })
        case SET_FLAT_INDEX_READ_TYPE:
            return Object.assign({}, state, { flatIndexReadType: action.readType })
        default:
            return state
    }
}


function projects(
    state = {
        company: { company:null, shouldShowMustIncludePopup:0 },
        list: [],
        docs: [],
        qsdocs: [],
        detectedLang: null,
        net: [],
        params: null,
        lastQueries: [],
        savedDocs: [],
        browsingDocId: null,
        browsingDocAnchor: null,
        loadedDoc: null,
        sharingProject: null,
        editingProject: null,
        browsingDocLang: null,
        refsCollection: [],
        qsquery: "",
        modifiedHighlightId: null,
        sessionSeenDocs: [],
        startPosition: 0,
    },
    action
) {
    var vstate;
    switch (action.type) {
        case SET_CURRENT_DOC_READ:
            vstate = Object.assign({}, state, {})
            if (vstate.loadedDoc)
                vstate.loadedDoc.read = action.read
            return vstate
        case ALTER_SAVED_DOC_ORDER_LOCALLY:
            vstate = Object.assign({}, state, {})
            vstate.savedDocs[action.savedDocIndex].order = action.newOrder
            return vstate
        case SHOULD_SHOW_MUST_INCLUDE_POPUP:
            return Object.assign({}, state, {
                company: Object.assign({}, state.company, {
                        shouldShowMustIncludePopup: action.val
                    })})
        case SET_START_POSITION:
            return Object.assign({}, state, {
                startPosition: action.startPosition
            })
        case SET_SHARING_PROJECT:
            return Object.assign({}, state, {
                sharingProject: action.sharingProject
            })
        case SET_EDITING_PROJECT:
            return Object.assign({}, state, {
                editingProject: action.project
            })
        case SET_LOADED_DOC:
            vstate = Object.assign({}, state, {})
            vstate.loadedDoc = action.doc
            if (action.doc)
            {
                for (var i = 0; i < vstate.docs.length; i++)
                {
                    if (vstate.docs[i].ref === action.doc.ref)
                        vstate.docs[i] = Object.assign({}, vstate.docs[i], action.doc)
                }
                vstate.sessionSeenDocs = [action.doc.ref].concat(vstate.sessionSeenDocs)
            
            }
            return vstate
        case COMPANY_LOADED:
            return Object.assign({}, state, {
                company: action.company
            })
        case PROJECTS_LOADED:
            var nSharingProject = null
            if ((state.sharingProject !== null) && ((state.sharingProject !== undefined)))
                nSharingProject = action.projects.find(x => x.id === state.sharingProject.id)
            
            return Object.assign({}, state, {
                list: action.projects,
                sharingProject: nSharingProject
            })
        case SET_BROWSING_DOC_ID:
            return Object.assign({}, state, {
                browsingDocId: action.docId,
                browsingDocLang: action.lang,
                refsCollection: action.allRefs,
                loadedDoc: action.docId ? state.loadedDoc : null,
                browsingDocAnchor: action.anchor
            })
        case SET_BROWSING_DOC_ANCHOR:
            return Object.assign({}, state, {
                browsingDocAnchor: action.anchor
            })
        case SHOW_SAVED_DOCS:
            return Object.assign({}, state, {
                browsingDocId: null,
                browsingDocLang: null,
                refsCollection: [],
                loadedDoc: null
            })
        case CLOSE_DOCUMENT:
            return Object.assign({}, state, {
                browsingDocId: null,
                browsingDocLang: null,
                refsCollection: [],
                loadedDoc: null
            })
        case SET_MODIFIED_HIGHLIGHT_ID:
            return Object.assign({}, state, {
                modifiedHighlightId: action.modifiedHighlightId
            })
        case SET_MODIFIED_COMMENT_ID:
            return Object.assign({}, state, {
                modifiedCommentId: action.modifiedCommentId
            })
        case ON_SEARCH_RESULTS_LOADED:
            return Object.assign({}, state, {
                docs: action.data.docs,
                net: action.data.net,
                params: action.data.params,
                
            })
        case ON_TIMELINE_OVERVIEW_LOADED:
            return Object.assign({}, state, {
                params: Object.assign({}, state.params ? state.params : {}, { overviewContDates: action.data.overview})
            })
        case ON_QSSEARCH_RESULTS_LOADED:
            return Object.assign({}, state, {
                qsdocs: action.data.qsdocs,
                detectedLang: action.data.lang,
                
            })
        case ON_QSSEARCH_QUERY_CHANGED:
            return Object.assign({}, state, {
                qsquery: action.query,
                
            })
        case ON_PROJECT_PARAMS_LOADED:
            var nstate = Object.assign({}, state, {
                currentProject: action.result.project
                
            })
            if (action.result.savedDocs)
                nstate.savedDocs = action.result.savedDocs
            if (action.result.lastQueries)
                nstate.lastQueries = action.result.lastQueries
            return nstate
        case ALTER_CURRENT_PROJECT_EDITOR:
            var nstate = Object.assign({}, state, { })
            if (action.isEditor)
                nstate.currentProject.editors.push ({login: action.login, upid: action.upid})
            else
                nstate.currentProject.editors = nstate.currentProject.editors.filter (function(editor) { return editor.upid !== action.upid})
            return nstate
                    
        default:
            return state
    }
}


function authParams(
  state = {
    auth: null,
    username: "",
    loggedIn: false,
    error: "",
    shouldRedirectToLogin: false,
    registrationRequestSent: false
  },
  action
) {
    const cookies = new Cookies();
    switch (action.type) {
        case SHOULD_REDIRECT_TO_HOME:
            return Object.assign({}, state, {
                shouldRedirectToLogin: true
            })    
        case CHECK_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("auth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                  loggedIn = true
            }
                
            return Object.assign({}, state, {
                shouldRedirectToLogin: !loggedIn,
                loggedIn: loggedIn,
                username: cookies.get("username"),
                //auth: vauth,
                error: ""
            })

        case REGISTER_DEMAND_SENT:
            return Object.assign({}, state, {
                registrationRequestSent: true
            })
        case LOGGED_IN:
            cookies.set('auth', action.auth, { path: '/' })
            cookies.set('username', action.username, { path: '/' })
            
            return Object.assign({}, state, {
                loggedIn: true,
                username: action.username,
                auth: action.auth,
                error: "",
                shouldRedirectToLogin: false
            })
        case REFRESH_TOKEN:
            cookies.set('auth', action.auth, { path: '/' })
            
            return Object.assign({}, state, {
                auth: action.auth,
                error: "",
                shouldRedirectToLogin: false
            })
        
        case LOGIN_FAILED:
            cookies.set('auth', null, { path: '/' })
            cookies.set('username', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                error: action.error,
                shouldRedirectToLogin: true
            })
        case LOG_OUT:
            cookies.set('auth', null, { path: '/' })
            cookies.set('username', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                error: "",
                shouldRedirectToLogin: true
            })
        case ON_ERROR:
            return Object.assign({}, state, {
                error: action.error,
            })
        case LOADING:
            if (action.isLoading)
                return Object.assign({}, state, {
                    error: '',
                })
            else
                return state

        default:
            return state
    }
}

function globalInterface(
    state = {
        helper: null,
        loading: false,
        queryFormVisible: true,
        watchesVisible: false,
        savedDocsVisible: false,
        activityVisible: false,
        activityTab: 'All',
        indexVisible: false,

        resultsShouldRedraw: false,
        showResultsMode: localConfig.defaultResultsMode ? localConfig.defaultResultsMode : 'list',
        timelineExpanded: false,
        docIsLoading: false,
        showQS: false,
        onboardingDismissed: true,
        tutorialActiveElements: {},
        toSetURL: null,
        shouldShowTerms: false,
        MIHelperVisible: false
    },
    action
) {
    switch (action.type) {
        case SET_BROWSING_DOC_ID:
            return Object.assign({}, state, {
                timelineExpanded: false
            })
        
        case EXPAND_TIMELINE:
            return Object.assign({}, state, {
                timelineExpanded: action.value
            })
        case SHOW_MI_HELPER:
            return Object.assign({}, state, {
                MIHelperVisible: action.visible
            })
        case SET_HELPER:
            return Object.assign({}, state, {
                helper: action.helper
            })
        case SET_URL:
            return Object.assign({}, state, {
                toSetURL: action.url
            })
        case SET_TUTORIAL_ELEMENTS:
            if (!action.elements)
                return Object.assign({}, state, {
                    tutorialActiveElements: {}
                })
            var cte = Object.assign({}, state.tutorialActiveElements,action.elements)
            console.log (action, cte)
            if (action.update)
            {
                cte = Object.assign({}, state.tutorialActiveElements,{})
                Object.keys(action.elements).forEach(function(key) {
                    if (cte.hasOwnProperty(key))
                        cte[key] = action.elements[key];
                    
                });
            }
            console.log (action, cte)
            
            return Object.assign({}, state, {
                tutorialActiveElements: cte
            })
        case SHOW_ONBOARDING:
            return Object.assign({}, state, {
                onboardingDismissed: !action.visible
            })
        case DOC_LOADING:
            return Object.assign({}, state, {
                docIsLoading: action.isLoading
            })
        case LOADING:
            return Object.assign({}, state, {
                loading: action.isLoading
            })
        case ON_SEARCH_RESULTS_LOADED:
            return Object.assign({}, state, {
                queryFormVisible: false,
                resultsShouldRedraw: true
            })
        case RESULTS_SHOULD_REDRAW:
            return Object.assign({}, state, {
                //queryFormVisible: false,
                resultsShouldRedraw: action.shouldRedraw
            })
        case SHOW_QUERY_FORM:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: action.visible,
            })
        case SHOW_ACTIVITY:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: action.visible,
                activityTab: action.tab,
                queryFormVisible: false,
            })
        case SHOW_INDEX:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: false,
                indexVisible: action.visible,
                activityVisible: false,
                queryFormVisible: false
            })
        case SHOW_SAVED_DOCS:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: action.visible,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: false
            })
        case SHOW_WATCHES:
            return Object.assign({}, state, {
                watchesVisible: action.visible,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: false
            })
        case SHOW_RESULTS_MODE:
            return Object.assign({}, state, {
                showResultsMode: action.mode,
                timelineExpanded: false
            })
        case SHOW_QS:
            return Object.assign({}, state, {
                showQS: action.visible,
            })
        case SHOW_TERMS:
            return Object.assign({}, state, {
                shouldShowTerms: action.visible,
            })
        default:
        return state
  }

}

export default combineReducers({
    authParams,
    globalInterface,
    projects,
    query,
    codes
  
})