import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'


import NavHeader from "../interfaceParts/NavHeader"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import '../css/HomePage.scss'

import {
  checkLoggedIn
} from '../redux/actionsAuth'

class ProjectsPage extends Component {
  componentWillMount() {
    this.props.dispatch(checkLoggedIn())
  }


  render() {
    if (this.props.shouldRedirectToLogin)
      return (<Redirect to='/' />)
    
    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    return (
      <div className="App">
          <NavHeader/>
          {error}
            
          
          <LoadingSpinner show={this.props.loading} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.authParams.error,
    loggedIn: state.authParams.loggedIn,
    loading: state.globalInterface.loading,
    shouldRedirectToLogin: state.authParams.shouldRedirectToLogin
  }
}


export default connect(mapStateToProps)(ProjectsPage)


