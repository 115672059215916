import React from "react";

export const localConfig = {
    API_URL: 'https://tax.api.eisphoria.com/',
    DEBUG: false,
    TRANSLATION_ENABLED: false,
    CODES_BUTTON_ENABLED: false,
    LOGIN_WINDOW_IMAGE: '/img/placeholder.png',
    LOGIN_HEADER: 'Welcome to EisphorIA BE Tax library',
    LOGIN_SUBHEADER: 'Our product is available only for subscribed customers',
    REGISTER_INVITATION_HTML: '<h4>Don\'t have login yet and want to know more?<br/>Enter your e-mail to get in touch:</h4>',
    QUICK_SEARCH_PAGE_ENABLED: false,
    DOCUMENTS_COPYRIGHT_BANNER: false,
    INDEX_BROWSER: false,
    timelineEnabled: true,
    ONBOARDING: [
            {'header':'EisphorIA', 'text':"Welcome to EisphorIA!<br/>EisphorIA wants to assist you in searching for the critical information.<br/>Relevance of the results and speed are our commitment to you<br/>  Please take a few seconds to discover key functions"},
            {'header':'Contextual search', 'text': 'Search with keywords or with natural language<br/>Type or Copy-Paste in the search box keywords, sentences, paragraphs or even full documents'},
            {'header':'Parameter your search', 'text': 'Select text in the search box and parameter it as "must include" or "important part"<br/>Must include - documents displayed as results must include selected text<br/>Important part - the importance of selected text will be over weighted by our algorithms'},
            {'header':'Document review', 'text': "Within a document, select text to see additional functions<br/>Search will automatically populate the search box with the selected text<br/>Highlight/Comment the relevant information. These will be marked and markers will be visible from the heatmap (right column with blue colors indicating relevance of passages)"},
            {'header':'More information', 'text': 'Check our <a target="_blank" href="https://www.eisphoria.com/static/docs/UserGuide.pdf">User Guide</a>.<br/><br/>Or contact us directly at <a href="support@eisphoria.com">support@eisphoria.com</a>.<br/><br/>It is always a pleasure to help you.'},
        
        ],

    SPECIAL_TERMS:
      <div className="col-md-12">
        <h4>Sources-Disclaimers</h4>
        <ul>
          <li><a target="_blank" rel="norefferer" href=" https://eservices.minfin.fgov.be/myminfin-web/pages/fisconet#!/conditions">Fisconet</a></li>
          <li><a target="_blank" rel="norefferer" href=" https://www.lachambre.be/kvvcr/showpage.cfm?section=/searchlist&language=nl&html=/site/wwwroot/searchlist/faqN.html#item0-juridinfo">LaChambre/DeKamer</a></li>
          <li><a target="_blank" rel="norefferer" href=" https://belastingen.vlaanderen.be/disclaimer">Vlabel</a></li>
          <li><a target="_blank" rel="norefferer" href="https://juportal.be/home/disclaimer">Jurportal</a></li>
          <li><a target="_blank" rel="norefferer" href="https://www.cbn-cnc.be/nl/adviezen/inleiding">CBN/CNC</a></li>
          <li><a target="_blank" rel="norefferer" href="https://www.const-court.be/fr/disclaimer">Constitutional Court</a></li>  
          <li><a target="_blank" rel="norefferer" href="https://curia.europa.eu/jcms/jcms/T5_5135/en/">Curia</a></li>  
          <li><a target="_blank" rel="norefferer" href="https://eur-lex.europa.eu/content/legal-notice/legal-notice.html">Eur-Lex</a></li>
        </ul>
      </div>,

    LANGUAGE_INVITATION: "in French or Dutch",
    PRIMARY_LANGUAGES: "Primary languages for this platform are French and Dutch"
};

export default localConfig