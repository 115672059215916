import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'

import '../css/HighlightMenu.scss';
import wrapPortal from "../utils/componentPositionUtils";

import {DocViewerEventFactory, DocViewerEventType, DocViewerListenerFactory} from '../utils/DocViewerEventFactory'
import {getContainer, getScroller} from "../utils/docViewerChildrenUtils";

import {
    removeDocHighlight,
} from '../redux/actionsProject'
import MetaManager from "../meta/MetaManager";


class HighlightMenu extends Component {

    state = {
        open: false,
        highlightId: null,
        highlights: [],
        highlightRect: null,
        element: null,
        container: null,
        containerTop: null,
        elementOffsetTop: 0,
        eventFactory: null
    }


    getHighlightList(container) {

        let highlights = null
        if (container != null) {
            highlights = Array.prototype.slice.call(ReactDOM.findDOMNode(this.props.containerRef.current).getElementsByClassName('highlight'))
            highlights.push (...Array.prototype.slice.call(ReactDOM.findDOMNode(this.props.containerRef.current).getElementsByClassName('highlightControlElement')))
        }
        return highlights
    }

    addListeners(container) {
        if (container != null) {
            // event listener to close on outer click
            container.addEventListener("click", this.handleOuterClick);

            // event listener to offset top position on scroll
            getScroller(container).addEventListener("scroll", this.handleOuterScroll);

            // add highlight click listeners
            let highlights = this.getHighlightList(container);
            if (highlights != null && highlights.length > 0) {
                for (let highlight of highlights) {
                    highlight.addEventListener("click", this.handleHighlightClick);
                }
            }
        }
    }

    componentDidMount() {
        let container  = getContainer(this.props.containerRef)
        if (!container) {
            return
        }

        this.addListeners(container);

        let eventFactory = DocViewerEventFactory(container)
        let listenerFactory = DocViewerListenerFactory(container);

        listenerFactory.createListener(DocViewerEventType.HIGHLIGHT_MENU_INVALIDATE, e => {
            this.addListeners(this.state.container);
        })

        this.setState({
            container: container,
            element:   ReactDOM.findDOMNode(this),
            eventFactory: eventFactory,
            listenerFactory: listenerFactory
        })
    }

    componentWillReceiveProps(prevProps, prevState) {
        this.addListeners(this.state.container);
        return null
    }

    componentWillUnmount() {
        let container  = getContainer(this.props.containerRef)
        if (!container) {
            return
        }

        container.removeEventListener("click", this.handleOuterClick);
        container.parentNode.parentNode.removeEventListener("scroll", this.handleOuterScroll);

        let highlights = this.getHighlightList(container);
        if (highlights != null && highlights.length > 0) {
            for (let highlight of highlights) {
                highlight.removeEventListener("click", this.handleHighlightClick);
            }
        }
    }

    closeMenu() {
        this.setState({
            open:        false,
            highlights:  [],
            highlightId: null
        })
    }

    handleOuterScroll = e => {
        if (this.state.containerTop != null) {
            this.setState({
                elementOffsetTop: this.state.container.getBoundingClientRect().top - this.state.containerTop
            })
        }
    }

    handleOuterClick = e => {
        this.closeMenu();
        this.state.eventFactory.createEvent(DocViewerEventType.HIGHLIGHT_CLEAR)
    }

    handleHighlightClick = e => {
        let highlights  = e.currentTarget.getAttribute('data-metatag').split(",")
        let highlightId = highlights.length === 1 ? highlights[0] : null
        let comments    = highlightId !== null ? MetaManager.findCommentsInMeta(this.props.loadedDoc.meta, highlightId) : []
        this.setState({
            open:           true,
            highlights:     highlights,
            highlightId:    highlightId,
            comments:       comments,
            highlightRect:  e.target.getBoundingClientRect(),
            containerTop:   this.state.container.getBoundingClientRect().top,
            elementOffsetTop: 0,
        })
        e.stopPropagation()


        if (this.state.eventFactory != null) {
            this.state.eventFactory.createCloseOthersEvent(ReactDOM.findDOMNode(this));
        }

        if (highlights.length === 1) {
            if (this.state.eventFactory != null) {
                if (comments.length > 0) {
                    this.state.eventFactory.createShowCommentsEvent(highlightId);
                }
                this.state.eventFactory.createHighlightMarkEvent(this.state.highlightId);
            }
        }
    }


    handleButtonClickShowComments = e => {
        if (this.state.eventFactory != null) {
            this.state.eventFactory.createShowCommentsEvent(this.state.highlightId);
        }
        this.closeMenu();
    }

    handleButtonClickAddComment = e => {
        if (this.state.eventFactory != null) {
            this.state.eventFactory.createAddCommentEvent(this.state.highlightId);
        }
        this.closeMenu();
    }

    handleButtonClickDeleteHighlight = e => {
        this.closeMenu();
        if (window.confirm('Are you sure you wish to delete this highlight? This cannot be undone'))
            this.props.dispatch(removeDocHighlight(this.state.highlightId, this.props.project.id, this.props.query))
    }


    handleHighlightSelectionMenuOut = e => {
        this.state.eventFactory.createEvent(DocViewerEventType.HIGHLIGHT_CLEAR)
    }

    handleHighlightSelectionButtonOver (highlightId) {
        this.state.eventFactory.createEvent(DocViewerEventType.HIGHLIGHT_PAINT, {highlightId: highlightId})
    }




    selectHighlight (highlightId) {
        let comments    = highlightId !== null ? MetaManager.findCommentsInMeta(this.props.loadedDoc.meta, highlightId) : []
        this.setState({
            highlightId: highlightId,
            comments: comments
        })
        if (this.state.eventFactory != null) {
            if (comments.length > 0) {
                this.state.eventFactory.createShowCommentsEvent(highlightId);
            }
            this.state.eventFactory.createHighlightMarkEvent(this.state.highlightId);
        }
    }



    renderHighlightSelectionMenu() {
        return (
            <div key="highlight-menu-highlight-selection" className="quickEditPopup" onMouseLeave={this.handleHighlightSelectionMenuOut}>
                {this.state.highlights.map((highlight, index) => {
                    return ([
                        <button key={index} onClick={() => this.selectHighlight(highlight)} onMouseOver={() => this.handleHighlightSelectionButtonOver(highlight)}>
                            Select highlight # {highlight}
                        </button>
                    ])
                })}
            </div>
        )
    }

    renderHighlightMenu() {
        //let highlightId = this.state.highlightId
        return (
            <div key="highlight-menu-highlight" className="quickEditPopup">
                {/*<button onClick={this.handleButtonClickShowComments}>Show comments/!*for {highlightId}*!/</button>*/}
                {this.state.comments.length === 0 &&
                    <button onClick={this.handleButtonClickAddComment}>Add comment{/*for {highlightId}*/}</button>
                }
                <button onClick={this.handleButtonClickDeleteHighlight}>Delete highlight{/*for {highlightId}*/}</button>
            </div>
        )
    }

    getPositionStyle() {
        if (this.state.highlightRect == null) {
            return {}
        }

        let hlRect = this.state.highlightRect
        let selfRect = this.state.element.getBoundingClientRect()

        return {
            top: hlRect.top + 24 + this.state.elementOffsetTop,
            left: hlRect.left + selfRect.width > window.innerWidth ? window.innerWidth - selfRect.width : hlRect.left
        }
    }


    render() {
        let cssClass = `highlight-menu ${this.state.open ? 'open' : ''}`
        let style = this.getPositionStyle()

        return (
            <div className={cssClass} style={style}>
                {this.state.highlightId != null ? this.renderHighlightMenu(this.state.highlightId) : this.renderHighlightSelectionMenu()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loadedDoc: state.projects.loadedDoc,
        query: state.query,
        project: state.projects.currentProject
    }
}

export default connect(mapStateToProps)(wrapPortal(HighlightMenu))
