import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Scrollbar from 'react-scrollbars-custom';

import userDelActImg from '../img/ic_act_user_delete.svg'
import userAddActImg from '../img/ic_act_user_add.svg'
import hlDelActImg from '../img/ic_act_hl_delete.svg'
import hlAddActImg from '../img/ic_act_hl_add.svg'
import docDelActImg from '../img/ic_act_doc_delete.svg'
import docAddActImg from '../img/ic_act_doc_add.svg'
import searchActImg from '../img/ic_act_search.svg'
import docOpenActImg from '../img/ic_act_doc_open.svg'

import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'
  
import globalConfig from '../configGlobal/config'



import '../css/ActivityBrowser.scss';


import {
  setBrowsingDoc,
  onShowActivity,
  onShowQueryForm,
  setQuery,
  getProjectParams
} from '../redux/actionsProject'

import {
    setSharingProject
  } from '../redux/actionsProjects'

class ActivityBrowser extends Component {
    constructorName = 'ActivityBrowser'

    componentWillMount() {
        this.props.dispatch (getProjectParams(this.props.projectId, false, !this.props.project || this.props.project.fullLog))
      }

    render() {
        var that = this
        var cDate = null
        var entriesNum = 0
        const maxChars = 10000

        var logs = this.props.project.logs.map(function (d) { 

            var isShown = ((that.props.interface.activityTab === 'All') && (["alterDoc", "alterProjectShare", "detailed_query", "detailedQuery", "getDoc","removeDocHighlight","saveDocHighlight"].includes(d.endpoint))) ||
                            ((that.props.interface.activityTab === 'Search') && ((d.endpoint === "detailed_query")||(d.endpoint === "detailedQuery"))) ||
                            ((that.props.interface.activityTab === 'Documents management') && (["alterDoc", "removeDocHighlight","saveDocHighlight"].includes(d.endpoint))) ||
                            ((that.props.interface.activityTab === 'Browsing') && ("getDoc" === d.endpoint)) ||
                            ((that.props.interface.activityTab === 'Team changes') && ("alterProjectShare" === d.endpoint))
    
            var actUser = d.user
            var actName, actDetails, actIcon, actAction
            switch(d.endpoint) {
                case "getDoc":
                    actName = "Browse document"
                    actDetails = <div><p>{d.params.docName ? d.params.docName : d.params.docID}</p></div>
                    actIcon = docOpenActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(d.params.docID)); that.props.dispatch (onShowActivity(false)); }
                    break;
                case "removeDocHighlight":
                    actName = "Highlight removed"
                    actDetails = <div><p>{d.params.docName ? d.params.docName : d.params.docID}</p><p>{d.params.textToSave && (d.params.textToSave.length > maxChars) ? d.params.textToSave.substring(0,maxChars) + '...' : d.params.textToSave}</p></div>
                    actIcon = hlDelActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(d.params.docID)); that.props.dispatch (onShowActivity(false)); }
                    break;
                case "saveDocHighlight":
                    if (d.params.textToSave)
                    {
                        actName = "Highlight added"
                        actDetails = <div><p>{d.params.docName ? d.params.docName : d.params.docID}</p><p>{d.params.textToSave.length > maxChars ? d.params.textToSave.substring(0,maxChars) + '...' : d.params.textToSave}</p></div>
                        actIcon = hlAddActImg   
                    }
                    else {
                        actName = "Annotation added"
                        actDetails = <div><p>{d.params.docName ? d.params.docName : d.params.docID}</p></div>
                        actIcon = hlAddActImg
                    }
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(d.params.docID)); that.props.dispatch (onShowActivity(false)); }
                    break;
                case "detailed_query":
                    actName = "Search request"
                    if ((d.params.searchQuery) && (d.params.searchQuery.selectionMust) && (d.params.searchQuery.selectionMust.length > 0)) 
                        var miPanels = d.params.searchQuery.selectionMust.map ((dd)=>{return <span key={"mi" + d + dd} className="panelMI selecitonPanel">{dd}</span>})
                    if ((d.params.searchQuery) && (d.params.searchQuery.selectionExclude) && (d.params.searchQuery.selectionExclude.length > 0)) 
                        var mePanels = d.params.searchQuery.selectionExclude.map ((dd)=>{return <span key={"mi" + d + dd} className="panelME selecitonPanel">{dd}</span>})
                    actDetails = <div>
                                    <p>
                                        {((d.params.searchQuery) && (d.params.searchQuery.query)) ? (d.params.searchQuery.query.length > maxChars ? d.params.searchQuery.query.substring(0,maxChars) + '...' : d.params.searchQuery.query) : ( '')}
                                    </p>
                                    {((d.params.searchQuery) && (d.params.searchQuery.selectionMust) && (d.params.searchQuery.selectionMust.length > 0)) ? <p>Must include: {miPanels}</p> : null}
                                    {((d.params.searchQuery) && (d.params.searchQuery.selectionExclude) && (d.params.searchQuery.selectionExclude.length > 0)) ? <p>Must exclude: {mePanels}</p> : null}
                                </div>
                    actIcon = searchActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setQuery(d.params.searchQuery)); that.props.dispatch (onShowQueryForm(true));}
                    break;
                case "detailedQuery":
                    actName = "Search request"
                    if ((d.params.searchQuery) && (d.params.searchQuery.selectionMust) && (d.params.searchQuery.selectionMust.length > 0)) 
                        var miPanels = d.params.searchQuery.selectionMust.map ((dd)=>{return <span key={"mi" + d + dd} className="panelMI selecitonPanel">{dd}</span>})
                    if ((d.params.searchQuery) && (d.params.searchQuery.selectionExclude) && (d.params.searchQuery.selectionExclude.length > 0)) 
                        var mePanels = d.params.searchQuery.selectionExclude.map ((dd)=>{return <span key={"mi" + d + dd} className="panelME selecitonPanel">{dd}</span>})
                    actDetails = <div>
                                    <p>
                                        {((d.params.searchQuery) && (d.params.searchQuery.query)) ? (d.params.searchQuery.query.length > maxChars ? d.params.searchQuery.query.substring(0,maxChars) + '...' : d.params.searchQuery.query) : ( '')}
                                    </p>
                                    {((d.params.searchQuery) && (d.params.searchQuery.selectionMust) && (d.params.searchQuery.selectionMust.length > 0)) ? <p>Must include: {miPanels}</p> : null}
                                    {((d.params.searchQuery) && (d.params.searchQuery.selectionExclude) && (d.params.searchQuery.selectionExclude.length > 0)) ? <p>Must exclude: {mePanels}</p> : null}
                                </div>
                    actIcon = searchActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setQuery(d.params.searchQuery)); that.props.dispatch (onShowQueryForm(true));}
                    break;
                case "alterDoc":
                    actName = d.params.toSave ? "Document saved":"Document deleted"
                    actDetails = <div><p>{d.params.docName ? d.params.docName : d.params.docID}</p></div>
                    actIcon = d.params.toSave ? docAddActImg:docDelActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(d.params.docID)); that.props.dispatch (onShowActivity(false)); }
                    break;
                case "alterProjectShare":
                    actName = d.params.isShared ? "User added":"User removed"
                    actDetails = <div><p>{d.params.username ? d.params.username : d.params.upid}</p></div>
                    actIcon = d.params.isShared ? userAddActImg:userDelActImg
                    actAction = (e) => { e.stopPropagation(); that.props.dispatch (setSharingProject(that.props.project))}
                    break;
                
                default:
                    
                }

            var datetime = new Date(d.datetime);
            var date = datetime.toLocaleDateString()
            var time = datetime.toLocaleTimeString()
            if (isShown) {
                if (date !== cDate)
                    cDate = date
                else
                    date = null      
                entriesNum += 1    
            }  
    
            return <Row key={'log_' + d.id} className={isShown ? "logCard" : "logCard hidden"}>
                    {date === null ? null : <div className="hSep"></div>}
                    <Col md={2} className="dateCol"><h4>{date}</h4></Col>
                    <Col md={10}>
                        <Row className="contents"  onClick={actAction}>

                            <Col md={1}><span className="timeSpan">{time}</span></Col>
                            <Col md={1}><img src={actIcon} alt={actName} /></Col>
                            <Col md={10}>
                                <h4 className="clickable">{actName} {that.props.username !== actUser ? <span className="activityUser">by {actUser}</span> : null}</h4>
                                {actDetails}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            })  
    
        
            return (
                <div className="ActivityBrowser">
                    { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                        <Joyride
                            tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                            styles={{options: globalConfig.JOYRIDE.options}}
                            disableCloseOnEsc={true}
                            disableOverlayClose={true}
                            disableOverlay={true}
                            disableScrolling={true}
                            showSkipButton={true}
                            hideBackButton={true}
                            run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                            locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                            continuous={false}
                            stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                            callback = { data => {
                                    const { action, type } = data;
                                    if (action === ACTIONS.SKIP)
                                        this.props.dispatch (setTutorialElements(null))
                                    if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                    {
                                        //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                        //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                        this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                    }
                                
                                    
                                    
                                }
                            }
                        />
                        :null
                    }
                  <div className="subheader stdPadded">
                      <div className="alignRight" md={10}>
                          {((this.props.project.isPersonal || (this.props.company.company === null)) ? ['All', 'Search', 'Documents management', 'Browsing'] : ['All', 'Search', 'Documents management', 'Browsing', 'Team changes'] ).map(function (d) { return <button key={'filter_entries' + d} className={that.props.interface.activityTab === d ? 'active' : ''} onClick={(e) => { e.stopPropagation(); that.props.dispatch (onShowActivity(true, d))}}>{d}</button> }) }
                      </div>
                  </div>
                  <div className="fullSize" >
                      <Scrollbar noScrollX={true}>
                          <Container fluid className="stdPadded stdTopPadded">
                            <div className="panel">
                                <Row className="headerRow">
                                    <Col md={8}>
                                        <h3>Activities in your project</h3>
                                    </Col>
                                    <Col md={4} className="alignRight">
                                        <span className="entriesCounter">{!this.props.project || this.props.project.fullLog ? "":"Last"} {entriesNum} {entriesNum % 10 === 1 ? 'event':'events'}</span>
                                        <button className="refreshBtn realTransparent lowPadding" onClick={(e)=>{this.props.dispatch (getProjectParams(this.props.projectId, false, !this.props.project || this.props.project.fullLog, false))}}>Refresh</button>
                                    </Col>
                                </Row>
                                {logs}

                                {!this.props.project || this.props.project.fullLog ?
                                            null
                                            :
                                            <div className="alignCenter">
                                                <button className="showMoreBtn lightBtn" onClick={(e)=>{this.props.dispatch (getProjectParams(this.props.projectId, false, true, false))}}>Show all</button>
                                            </div>
                                        }
                              </div>
                          </Container>                  
                      </Scrollbar>
                  </div>
          
                </div>
              );
        


    }
}


function mapStateToProps(state) {
  return {
    project: state.projects.currentProject,
    username: state.authParams.username,
    interface: state.globalInterface,
    company: state.projects.company,
  }
}

export default connect(mapStateToProps)(ActivityBrowser)



