import {onShowSavedDocs, setBrowsingDoc} from "../redux/actionsProject";

export default class ProjectPageDispatcher {

    static dispatch(props) {
        let mode = props.match.params.mode;
        if (mode !== undefined) {
            switch (mode) {

                // dispatch to saved doc
                case "saved":
                    props.dispatch(onShowSavedDocs(true));
                    if (props.match.params.docRef)
                        props.dispatch(setBrowsingDoc(props.match.params.docRef));
                    break;
                case "doc":
                    props.dispatch(setBrowsingDoc(props.match.params.docRef));
                    break;
                default:
                    break
            }
        }
    }
}
