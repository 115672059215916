import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Scrollbar from 'react-scrollbars-custom';
import DocViewer from "./DocViewer"
import { Link } from "react-router-dom";


import folderIcon from '../img/ic_folder.svg'
import fileIcon from '../img/ic_file.svg'


import '../css/FlatIndexBrowser.scss';

import {
  getFlatIndex,
  setFlatIndexReadType
} from '../redux/actionsCodes'

import {
    setBrowsingDoc,
    onProjectParamsLoaded,
    closeDocument
  } from '../redux/actionsProject'
  

class FlatIndexBrowser extends Component {
    state={redirect:null}

    componentDidMount()
    {
        this.props.dispatch (onProjectParamsLoaded ({project:null}))
        this.props.dispatch (closeDocument())
        this.props.dispatch (getFlatIndex(this.props.path, this.props.flatIndexReadType))
    }

    componentWillReceiveProps(nextProps)
    {   
        if (((nextProps.path !== null) && (nextProps.path !== this.props.path)) || (nextProps.flatIndexReadType != this.props.flatIndexReadType))
            this.props.dispatch (getFlatIndex(nextProps.path, nextProps.flatIndexReadType))
            
    }
    
    render(show) {
        var that = this

        if (this.state.redirect)
        {
            var nPath = this.state.redirect
            this.setState ({redirect:null})
            return <Redirect push to={nPath} />;
        }

        var pathComponents = this.props.path.split ('/')
        var bcLinks = pathComponents.map(function (d, ind) { 
            return ind < pathComponents.length - 2 ? 
                <span key={'bc' + ind}><Link  to={pathComponents.slice(0,ind+1).join('/') + '/'}>{d}</Link><span className="bcSep">/</span></span>
                :
                <span key={'bc' + ind}>{d}</span>
        }) 

        var folderCards = this.props.flatIndex && this.props.flatIndex.folders ? 
            this.props.flatIndex.folders.map(function (d, ind) { 
                return <Row key={d.name} className="folderCard indexCard" 
                        onClick={(e)=>{
                            e.stopPropagation(); that.setState ({redirect:that.props.path + d.name + '/'})
                              }}>
                    <Col md={1}><img src={folderIcon} alt="folder" /></Col>
                    <Col md={9}><h4 className="clickable">{d.name}</h4></Col>
                    <Col md={2} className="alignRight"><h4 className="counter">{d.size}</h4></Col>
                </Row> 
            })    
            : null

        var allFilesRefs = this.props.flatIndex && this.props.flatIndex.files ? this.props.flatIndex.files.map(function (d, ind) { 
                return [d.ref] 
            })    
            : null

        var filesCards = this.props.flatIndex && this.props.flatIndex.files ? 
            this.props.flatIndex.files.map(function (d, ind) { 
                return <Row key={d.name} className="fileCard indexCard" onClick={(e) => { e.stopPropagation(); that.props.dispatch (setBrowsingDoc(d.ref, null,  allFilesRefs)); }}>
                    <Col md={1}><img src={fileIcon} alt="file" /></Col>
                    <Col md={11}><h4 className="clickable">{d.name}</h4></Col>
                </Row> 
            })    
            : null

        return (
            <div className="BrowserApp flatIndexPage">
                <div className="Browser">
                    <div className="flatIndexViewer">
                        <div className="subheader">
                            <Container fluid>
                                <Row>
                                    <Col md={12}>
                                        <span>
                                            {['All', 'Not viewed', 'Viewed'].map (function (rt) {
                                                return <button key={'readTypeBtn' + rt} className={that.props.flatIndexReadType === rt ? 'resultsViewTypeBtn active':'resultsViewTypeBtn'} onClick={(e) => {that.props.dispatch (setFlatIndexReadType(rt));}}>{rt}</button>
                                            })}
                                        </span>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="breadcrumb">
                            {bcLinks}
                        </div>
                        <div className="cardsContainer">
                            <Scrollbar noScrollX={true}>
                                <Container fluid>
                                    {folderCards}
                                    {filesCards}
                                </Container>
                            </Scrollbar>
                        </div>

                        

                    </div>
                    <DocViewer projectId={null} topButtons={{close:true, save:false, print:true, searchWith:false, previous:false, next:false}} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    loading: state.globalInterface.loading,
    flatIndex: state.codes.flatIndex,
    flatIndexReadType: state.codes.flatIndexReadType,
    interface: state.globalInterface,
  }
}

export default connect(mapStateToProps)(FlatIndexBrowser)


