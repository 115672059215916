import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import '../css/main.scss'


import NavHeader from "../interfaceParts/NavHeader"
import LoadingSpinner from "../interfaceParts/LoadingSpinner"
import Scrollbar from 'react-scrollbars-custom';

import '../css/DocViewer.scss';


import {
  checkLoggedIn
} from '../redux/actionsAuth'

import {
  loadDoc,
  getSrcDoc
} from '../redux/actionsProject'

class DocPage extends Component {
  componentWillMount() {
    this.props.dispatch(checkLoggedIn())
    var params = this.props.location.pathname.split('/')
    if (params[params.length - 1].length === 2)
      this.props.dispatch (loadDoc(params[params.length - 2] + '/' + params[params.length - 1], null, params[params.length - 3]))
    else
      this.props.dispatch (loadDoc(params[params.length - 1], null, params[params.length - 2]))
  }


  render() {
    if (this.props.shouldRedirectToLogin)
      return (<Redirect to='/' />)
    
    const componentClasses = ['docViewer', 'show', 'fullscreen'];
    var contents = <div className={componentClasses.join(' ')}> 
                <div className="topMenu"></div>
                <div className="textContent"></div>
                <div className="spinner">
                    <LoadingSpinner show={this.props.isLoading} />
                </div>
            </div>

    if (this.props.loadedDoc != null) {
      var cardFields = []
      if (this.props.loadedDoc.caption)
          cardFields.push (<div key="idCaption" className="idField"><h3>{ this.props.loadedDoc.caption}</h3></div>)
      if (this.props.loadedDoc.link)
        if (this.props.loadedDoc.link.includes ("://"))
            cardFields.push (<div key="idLink" className="idField">Source: <a target="_blank" rel="noopener noreferrer" href={ this.props.loadedDoc.link}>{this.props.loadedDoc.source.name}</a></div>)
        else
            cardFields.push (<div key="idLink" className="idField">Source: <button className="linkLikeBtnGreenInline" onClick={e => {this.props.dispatch(getSrcDoc(this.props.loadedDoc.ref))}}>Download</button></div>)
      if (this.props.loadedDoc.dateString && this.props.loadedDoc.dateString.length > 1)
          cardFields.push (<div key="idDate" className="idField">Date: {this.props.loadedDoc.dateString}</div>)
      if (this.props.loadedDoc.summary)
          cardFields.push (<div key="idSummary" className="idField">Summary: {this.props.loadedDoc.summary}</div>)
      //if (this.props.loadedDoc.kw)
      //    cardFields.push (<div key="idKW" className="idField">Keywords: {this.props.loadedDoc.kw}</div>)

      
      contents = <div className={componentClasses.join(' ')}> 

                <Scrollbar
                            trackYProps={{
                                renderer: props => {
                                  const { elementRef, ...restProps } = props;
                                  return <span {...restProps} ref={elementRef} className="trackY" style={{ "background":'#eee'}} />;
                                }                              }}
                        >
                        <div ref={this.popupAreaRef} className="textContent">
                            <div className="idCard">{cardFields}</div>
                            <div className="contents" dangerouslySetInnerHTML={{ __html: this.props.loadedDoc.html }} />    
                            
                        </div>
                    </Scrollbar>
                        <div className="spinner">
                            <LoadingSpinner show={this.props.isLoading} />
                        </div>
                    </div>
                
    }
    
    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    return (
      <div className="App">
          <NavHeader/>
          {error}
            { contents }
          <LoadingSpinner show={this.props.loading} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.authParams.error,
    loggedIn: state.authParams.loggedIn,
    loading: state.globalInterface.docIsLoading,
    loadedDoc: state.projects.loadedDoc,
    shouldRedirectToLogin: state.authParams.shouldRedirectToLogin
  }
}


export default connect(mapStateToProps)(DocPage)


