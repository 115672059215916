// react
import React, {Component} from "react";
import ReactDOM from "react-dom";

// app stuff
import {DocViewerListenerFactory, DocViewerEventType, DocViewerEventFactory} from "../utils/DocViewerEventFactory";
import metaRenderer from "../meta/MetaRenderer";

export default class DocContentRenderer extends Component {

    state = {
        highlightId: null, // id of highlight to be painted with different color
        container: null
    }

    componentDidMount() {
        let container = ReactDOM.findDOMNode(this).parentNode
        let listenerFactory = DocViewerListenerFactory(container);

        // paint highlight with different color
        listenerFactory.createListener(DocViewerEventType.HIGHLIGHT_PAINT, (e) => {
            if (e.detail !== undefined && e.detail.highlightId !== undefined && e.detail.highlightId !== null) {
                this.setState({
                    highlightId: e.detail.highlightId
                })
            }
        })

        //  change highlight color back to default
        listenerFactory.createListener(DocViewerEventType.HIGHLIGHT_CLEAR, (e) => {
            this.setState({
                highlightId: null,
                container: container
            })
        })
    }

    sendEventMenuInvalidate() {
        if (this.state.container != null) {
            DocViewerEventFactory(this.state.container).createEvent(DocViewerEventType.HIGHLIGHT_MENU_INVALIDATE)
        }
    }

    render() {
        this.sendEventMenuInvalidate()
        return (
            <div className="contents" onClick={this.props.onGeneralClick}
                 dangerouslySetInnerHTML={{ __html: metaRenderer.render(this.props.html, this.props.meta, {
                     highlightToPaint: this.state.highlightId
                     }, true) }} />
        )
    }
}
