import React, { Component } from 'react'
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Scrollbar from 'react-scrollbars-custom';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import StringUtils from "../utils/StringUtils";


import '../css/SavedDocsBrowser.scss';

import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements
} from '../redux/actions'
  
import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'

import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";
import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";
import { ReactComponent as ReadListItem} from '../img/ic_card-viewed.svg'

import {
  setBrowsingDoc,
  getProjectDoc,
  alterSavedDocOrder
} from '../redux/actionsProject'

const SortableItem = SortableElement(({value}) => <div>{value}</div>);
 
const SortableList = SortableContainer(({items}) => {
    return (
    <div>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.key}`} index={index} value={value} />
      ))}
    </div>
  );
});

class SavedDocsBrowser extends Component {
    constructorName="SavedDocsBrowser"
    state = {
        exportPanelVisible: false,
        excludedExportDocs: [],
        cdocs: []
    };

    componentDidMount(){
        if (this.props.savedDocs){
            this.props.savedDocs.sort((a,b) => (a.order > b.order) ? 1 : -1);
            this.setState ({cdocs: this.props.savedDocs})
        }
        else this.setState ({cdocs: []})
    }
    componentWillReceiveProps(nextProps){   
        if (nextProps.savedDocs){
            nextProps.savedDocs.sort((a,b) => (a.order > b.order) ? 1 : -1);
            this.setState ({cdocs: nextProps.savedDocs})
        }
        else this.setState ({cdocs: []})
    }

    handleExportDocChange = (did) =>
    {
        var vstate = [...this.state.excludedExportDocs]
        
        if (vstate.includes (did))
        {
            for( var i = 0; i < vstate.length; i++)
               if ( vstate[i] === did) {
                 vstate.splice(i, 1); 
                 i--;
               }
        }
        else 
            vstate.push (did)

        this.setState ({ excludedExportDocs: vstate })


    }

    onSortEnd = ({oldIndex, newIndex}) => {
        var newOrder = 0
        if (newIndex === 0) 
            newOrder = this.props.savedDocs[0] - 1
        else if (newIndex === this.props.savedDocs.length - 1)
            newOrder = this.props.savedDocs[newIndex].order + 1
        else {
            if (newIndex >= oldIndex)
                newOrder = (this.props.savedDocs[newIndex + 1].order + this.props.savedDocs[newIndex].order) / 2
            else
                newOrder = (this.props.savedDocs[newIndex - 1].order + this.props.savedDocs[newIndex].order) / 2
        }
        this.props.dispatch (alterSavedDocOrder(this.props.projectId, this.props.savedDocs[oldIndex].id, oldIndex, newOrder))
        var ndocs = [...this.state.cdocs]
        ndocs[oldIndex].order = newOrder
        ndocs.sort ((a,b) => (a.order > b.order) ? 1 : -1);
        this.setState ({cdocs:ndocs})
      };
    
    render() {
        var that = this
        
        var savedDocsCards = <h4>You will see your saved docs here</h4>

        if ((this.state.cdocs !== null) && (this.state.cdocs.length !== 0))
        {
            savedDocsCards = <SortableList items={this.state.cdocs.map(function (d) { 
                const cClasses = ['docCard', 'savedDocCard'];
                if (that.props.openDoc === d.ref) cClasses.push('opened'); 
                else if (that.props.browsingDocId !== null) { cClasses.push('notopened'); }


                return (
                    <Col className={cClasses.join(' ')}  md={12} key={d.id} id={"doc-" + d.id} onClick={(e) => { that.props.dispatch (setBrowsingDoc(d.ref, d.language)); }}>
                        <div >
                            <div className="contents">
                                <Row>
                                    <Col md={12}>
                                        <div className="flags">        
                                            {
                                                (d.annotations && (d.annotations.length > 0)) ?
                                                    <span>
                                                        { d.annotations.map(function (ann) 
                                                            { 
                                                                return <span key={'annitem' + ann.id} className={"annItem flag" + StringUtils.capitalize(ann.color)}><ImgFlag /> {ann.comments.length > 1 ? ("" + ann.comments.length):null} </span>
                                                            }) 
                                                        }
                                                    </span>
                                                    
                                                    :
                                                    null
                                            }
                                            { d.commentsNum > 0 ? <span className="annItem flagNeutral"><ImgComment />{(d.commentsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                            { d.highlightsNum > 0 ? <span className="annItem flagNeutral"><ImgHighlight />{(d.highlightsNum > 1) ? ("" + d.highlightsNum):""} </span>:null}
                                            
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <h4 className="projectTitle">{ (d.read && localConfig.VIEWED_ENABLED) ? <ReadListItem className="listItemReadIcon"/> : null} {d.caption}</h4>
                                    </Col>
                                </Row>
                                <Row className="fields">
                                        <Col md={12}>
                                            <span className="value">{d.source.name}</span>
                                            <span className="value">{d.dateString}</span>
                                            <span className="value">{d.language}</span>
                                        </Col>
                                </Row>
                                {(d.summary) && (d.summary.length > 3) ? <p className="description">{d.summary}</p> : null}
                            </div>
                        </div>
                    </Col>);
            })
        }  lockAxis='y' onSortEnd={this.onSortEnd} pressDelay={200}/>
        }
        

        var exportDocsSelectors = this.props.savedDocs.map(function (d) { 
                return <li key={d.id}>
                        <label htmlFor={"eDoc" + d.id} className="cbcontainer" onClick={(e) => {e.stopPropagation(); that.handleExportDocChange (d.id) }}>{d.caption}
                            <input type="checkbox" className="exportDocSelector" name={"eDoc" + d.id} checked={!that.state.excludedExportDocs.includes (d.id)} value={d.id} readOnly={true}></input>
                            <span className="checkmark"></span>
                        </label>
                    </li>
            })

        var exportPanelClasses = ['exportDocsSelectorsPanel', 'withShadow']
        if (this.state.exportPanelVisible) { exportPanelClasses.push ('show') }

        
        return (
                <div className="savedDocsViewer">
                    { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                        <Joyride
                            tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                            styles={{options: globalConfig.JOYRIDE.options}}
                            disableCloseOnEsc={true}
                            disableOverlayClose={true}
                            disableOverlay={true}
                            disableScrolling={true}
                            showSkipButton={true}
                            hideBackButton={true}
                            run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                            locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                            continuous={false}
                            stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                            callback = { data => {
                                    const { action, type } = data;
                                    if (action === ACTIONS.SKIP)
                                        this.props.dispatch (setTutorialElements(null))
                                    if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                    {
                                        //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                        //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                        this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
                                    }
                                
                                    
                                    
                                }
                            }
                        />
                        :null
                    }
                    <div className="subheader">
                        <Container fluid>
                            <Row>
                                <Col md={6}></Col>
                                <Col md={6} className="alignRight">
                                    <button className="downloadProjectBtn" onClick={() => {this.setState ({exportPanelVisible: true}); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===0)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}>Download project</button>
                                </Col>
                                
                            </Row>
                        </Container>
                    </div>
                    
                    <div className="cardsContainer">
                        <Scrollbar noScrollX={true}>
                            <Container fluid>
                                <Row className="row docCardsInternalContainer">
                                    {savedDocsCards}
                                </Row>
                            </Container>
                        </Scrollbar>
                    </div>

                    <div className={exportPanelClasses.join(' ')} onClick={(e) => { e.stopPropagation();  this.setState ({exportPanelVisible: false})}}>
                        <div className="panel" onClick={(e) => { e.stopPropagation();}}>
                                <h4>Please select which documents you want to export</h4>
                                <ul>
                                    {exportDocsSelectors}
                                </ul>
                                <button className="text" onClick={() => { this.props.dispatch(getProjectDoc(this.props.projectId, this.props.projectName, this.state.excludedExportDocs)) }}>Export docx</button>
                                {localConfig.canDownloadSources ? <button className="text" onClick={() => { this.props.dispatch(getProjectDoc(this.props.projectId, this.props.projectName, this.state.excludedExportDocs, true)) }}>Export sources</button> : null}
                        </div>
                    </div>    
                </div>

                
            );
        
            


    }
}


function mapStateToProps(state) {
  return {
    savedDocs: state.projects.savedDocs,
    openDoc: state.projects.browsingDocId,
    projectName: state.projects.currentProject ? state.projects.currentProject.name : "project",
    projectId: state.projects.currentProject ? state.projects.currentProject.id : null,
    interface: state.globalInterface

  }
}

export default connect(mapStateToProps)(SavedDocsBrowser)



