import React from "react";
import styled from 'styled-components'

const TooltipBody = styled.div`
  background-color: #215fca;
  color: #fff;
  min-width: 290px;
  max-width: 420px;
  padding-bottom: 14px;
`;

const TooltipContent = styled.div`
  color: #fff;
  padding: 20px;
`;

const TooltipTitle = styled.h2`
  color: #fff;
  padding: 20px;
  margin: 0;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 14px;
  margin-left: 20px;

  * + * {
    margin-left: 0.5rem;
  }
`;

const Button = styled.button`
  background-color: #104ca1;
  color: #fff;
`;

const JoyrideTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    skipProps,
    tooltipProps,
    size
  }) => (
    <TooltipBody {...tooltipProps} className="tooltipBody">
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>{step.content}</TooltipContent>
      <TooltipFooter>
        <span className="counter">{index+1}/{size}</span>
         
        {false && index > 0 && (
          <Button {...backProps}>
            Back
          </Button>
        )}
          <button {...skipProps} className="skipBtn">
            Exit helper
          </button>
        { step.isAction ? <Button {...closeProps} disabled={true} className="disabled">Waiting for action</Button> :
          <Button {...closeProps}>Got it</Button>}
          
        
      </TooltipFooter>
    </TooltipBody>
  );


export default (JoyrideTooltip)