import Cookies from 'universal-cookie'
import config from 'react-global-configuration'

import {
    authRefresh,
} from './actionsAuth'

export const LOADING = 'LOADING'
export const ON_ERROR = 'ON_ERROR'
export const SHOW_QS = 'SHOW_QS'
export const SET_TUTORIAL_ELEMENTS = 'SET_TUTORIAL_ELEMENTS'
export const SET_URL = "SET_URL"
export const SHOW_TERMS = 'SHOW_TERMS'
export const SET_HELPER = 'SET_HELPER'
export const SHOW_MI_HELPER = 'SHOW_MI_HELPER'


export function aaa (a,b,c) {
    console.log (a+b,c)
}

export function setMIHelper (visible) {
    return {
        type: SHOW_MI_HELPER,
        visible
    }
}

export function setURL (url) {
    return {
        type: SET_URL,
        url
    }
}

export function setTutorialElements (elements, update=true) {
    return {
        type: SET_TUTORIAL_ELEMENTS,
        elements,
        update
    }
}


export function getAccessToken () {
    var auth = (new Cookies()).get("auth")
    if ((auth === undefined) || (auth === null))
        return undefined
    else return auth.access_token
}

export function setLoading (isLoading) {
    return {
        type: LOADING,
        isLoading
    }
}

export function setHelper (helper) {
    return {
        type: SET_HELPER,
        helper
    }
}

export function setError (error) {
    return {
        type: ON_ERROR,
        error
    }
}

export function onShowQS (visible) {
    return {
        type: SHOW_QS,
        visible
    }
}

export function onShowTerms (visible) {
    return {
        type: SHOW_TERMS,
        visible
    }
}

export function dismissHelper (helper) {
    const conf = {
      method: "post",
      body: JSON.stringify({ "hid": helper.id, 'endpoint': 'dismissHelper' }),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };

    return dispatch => {
        dispatch(setHelper(null))
        return fetch(config.get('globalUrl') + "api/api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({name:'dismissHelper', p1:helper}))
                return res.json()})
            .then(
                (result) => {
                },
                (error) => {
                }
            )
    }
    
}
