import React, { Component } from 'react';
import { connect } from 'react-redux'

import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import hintImg from '../img/ic_hint.svg'


import '../css/Helper.scss';


  import {
    setHelper,
    dismissHelper
  } from '../redux/actions'
  

class Helper extends Component
{
    render() {    
        return this.props.helper ? (
            <div className="Helper">
                <div className="visual">
                    <img src={hintImg} alt="helper"/>
                </div>
                <div className="cont">
                    <Scrollbar noScrollX={true} className="helperContents">
                        <Container fluid className="">
                            <Row>
                                <Col md={12}>
                                    <h3>{this.props.helper.caption}</h3>
                                    <p>{this.props.helper.description}</p>
                                    <button className="stdBtn ml-0" onClick={(e)=>{this.props.dispatch(setHelper(null));}}>Close</button>
                                    <button className="lightBtn" onClick={(e)=>{this.props.dispatch(dismissHelper(this.props.helper));}}>Ok, got it!</button>
                                </Col>
                            </Row>
                        </Container>
                    </Scrollbar>  
                </div>
            </div>
        ): null
    }

}

function mapStateToProps(state) {
    return {
      helper: state.globalInterface.helper
    }
}
  
export default connect(mapStateToProps)(Helper)
