export default (function MetaManager() {
    function getElementMetaIdList(element) {
        return element.getAttribute('data-metatag').split(",")
    }

    function findCommentsInMeta(meta, highlightId) {
        if (!meta) {
            return []
        }
        return findComments(meta.highlights, highlightId)
    }

    function findComments(highlights, highlightId) {
        if (!highlights) {
            return []
        }
        highlightId = Number(highlightId);
        for (let i = 0; i < highlights.length; i++) {
            const highlight = highlights[i];
            if (highlight.id === highlightId) {
                return highlight.comments;
            }
        }
        return []
    }

    function findHighlightById(highlights, highlightId) {
        if (highlights === null || highlights === undefined) {
            return null;
        }
        let found = highlights.filter(higlight => higlight.id === highlightId);
        if (found.length > 0) {
            return found[0];
        }
        return null;
    }


    return Object.freeze({
        getElementMetaIdList,
        findComments,
        findCommentsInMeta,
        findHighlightById
    })
})()
