import React, { Component } from 'react';
import { connect } from 'react-redux'

import Scrollbar from 'react-scrollbars-custom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import mustHintImg from '../img/img_selMustImage.png'


import '../css/MIHelper.scss';


  import {
    dismissHelper,
    setMIHelper
  } from '../redux/actions'

  import {
    setShouldShowMustIncludePopup
  } from '../redux/actionsProjects'
  

class MIHelper extends Component
{
    render() {    
        return this.props.helper ? (
            <div className="MIHelper">
                <div className="cont">
                    <Scrollbar noScrollX={true} className="helperContents">
                        <Container fluid className="">
                            <Row>
                                    <Col md={12}>
                                        <h3>Parameter your search</h3>
                                        <img src={mustHintImg} alt="hintImg"/>
                                        <h3>Must Include</h3>
                                        <p>Find precisely numbers, names or technical concepts in the results by selecting them in your request and indicating them as "Must include".<br/>Be careful as only documents containing exactly these selections will be displayed in the results</p>
                                        <div className="buttons">
                                            <button className="stdBtn mr-0" onClick={(e)=>{this.props.dispatch(dismissHelper({id:-1})); this.props.dispatch(setShouldShowMustIncludePopup(-1)); this.props.dispatch (setMIHelper(false)) }}>Ok, got it!</button>
                                        </div>
                                    </Col>
                                
                            </Row>
                        </Container>
                    </Scrollbar>  
                </div>
            </div>
        ): null
    }

}

function mapStateToProps(state) {
    return {
      helper: state.globalInterface.MIHelperVisible
    }
}
  
export default connect(mapStateToProps)(MIHelper)
