import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom';
import history from '../utils/history';


import LoadingSpinner from "./LoadingSpinner"
import Popover from 'react-text-selection-popover';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown'
import Scrollbar from 'react-scrollbars-custom';
import { GlobalHotKeys } from "react-hotkeys";
import ReactTooltip from "react-tooltip"
//import highlightRenderer from '../HighlightsRenderer'
import {DocViewerEventFactory} from '../utils/DocViewerEventFactory'
import HighlightMenu from "./HighlightMenu";
import DocCommentDialog from "./DocCommentDialog";
import DocComments from "./DocComments";
import DocContentRenderer from "./DocContentRenderer";
import ProjectSelector from "./ProjectSelector"


import placeRightBelow from './DocViewerPopoverPositionStrategy'

import '../css/DocViewer.scss';

import { ReactComponent as DotsIcon} from '../img/ic_3_dots_un.svg'

import { ReactComponent as AddDocIcon} from '../img/ic_save.svg'
import { ReactComponent as DeleteIcon} from '../img/ic_delete_file.svg'
import { ReactComponent as PrintIcon} from '../img/ic_print.svg'

import { ReactComponent as HighlightIcon} from '../img/ic_marker.svg'
import { ReactComponent as SearchIcon} from '../img/ic_loupe_dots.svg'
import { ReactComponent as ChatIcon} from '../img/ic_chat.svg'
import { ReactComponent as CopyIcon} from '../img/ic_copy.svg'

import { ReactComponent as ImgHighlight} from "../img/ic_card-highlight.svg";
import { ReactComponent as ImgComment} from "../img/ic_card-comment.svg";


import { ReactComponent as TriangleIcon} from '../img/ic_triangle.svg'
//import { ReactComponent as TriangleIconHover} from '../img/nicon_triangle_active.svg'

import { ReactComponent as LinkIcon } from '../img/ic_out.svg'

import DocAnnotation from "./DocAnnotation";

import localConfig from '../config/config'
import globalConfig from '../configGlobal/config'

import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import GHelper from './GHelper'
import JoyrideTooltip from './JoyrideTooltip';

import {
    setTutorialElements,
    setURL
} from '../redux/actions'


import {
  onChangeDocState,
  loadDoc,
  setQuery,
  onShowQueryForm,
  setBrowsingDoc,
  setBrowsingDocAnchor,
  saveDocHighlight,
  removeDocHighlight,
  getSrcDoc,
  setTranslationState,
  getProjectParams
} from '../redux/actionsProject'

class DocViewer extends Component {
    constructorName = "DocViewer"
    state = {
        popoverVisible: false,
        scrollHeatmap: '#7c8a9c',
        versionsVisible: false,
        caseVisible: false,
        saveMenuOpen: false,
        projectSelectorVisible: false,
        shouldRedirectToSelectedProject: false,
        shouldRedirectToMyProject:false
    };

    hotKeysMap = {
        SAVE_HIGHLIGHT: ["ctrl+h","command+h"],
        PRINT: ["ctrl+p","command+p"],
        SEARCH_SELECTION: ["ctrl+e","command+e"],
        ADD_COMMENT: ["ctrl+m","command+m"],
        NEXT_DOC: ["right"],
        PREV_DOC: ["left"],
    }
    

    hotKeysHandlers = {
        SAVE_HIGHLIGHT: (e) => { e.preventDefault(); this.handleSelectionSave() },
        PRINT: (e) => { e.preventDefault(); this.printDoc() },
        SEARCH_SELECTION: (e) => { e.preventDefault(); this.handleSelectionSearch() },
        ADD_COMENT: (e) => { e.preventDefault(); this.handleShowCommentDialog()},
        NEXT_DOC: (e) => { e.preventDefault(); this.navigateList(true) },
        PREV_DOC: (e) => { e.preventDefault(); this.navigateList(false) },
    }

    checkPopoverVisibility () {
        var textComponent = document.getElementById('docViewerContents');
        var selectedText = "";

        if (textComponent.selectionStart !== undefined)
        {// Standards Compliant Version
            var startPos = textComponent.selectionStart;
            var endPos = textComponent.selectionEnd;
            selectedText = textComponent.value.substring(startPos, endPos);
        }
        else if (window.getSelection())
        {// IE Version
            textComponent.focus();
            var sel = window.getSelection().toString();
            selectedText = sel;
        }
        var isVisible = (selectedText.length > 0)// && (! this.getSelectionBoundaryElement(true).className.includes ('comment'))
        this.setState ({popoverVisible:isVisible})
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && ((this.props.interface.tutorialActiveElements[this.constructorName]===6) || (this.props.interface.tutorialActiveElements[this.constructorName]===8)) && isVisible) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))


        /*
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }

        this.setState ({popoverVisible:(text.length > 0)})*/
    }

    componentWillMount() {
        this.popupAreaRef = React.createRef()
        this.scrollAreaRef = React.createRef()
        this.commentDialogRef = React.createRef()
        this.menuRef = React.createRef()
        this.componentDidUpdate ()
    }

    componentDidUpdate() {
        var that = this
        if ((this.props.browsingDocId !== null) && ((!this.props.loadedDoc) || (this.props.browsingDocId !== this.props.loadedDoc.ref)))
            if (!this.props.isLoading)
            {
                if (this.scrollAreaRef.current)
                    this.scrollAreaRef.current.scrollToTop()
                this.props.dispatch (loadDoc(this.props.browsingDocId, this.props.projectId, this.props.browsingDocLang, this.props.query))
            }

        if (this.props.interface.toSetURL)
        {
            history.push(this.props.interface.toSetURL);
            this.props.dispatch (setURL (null))
        }
        ReactTooltip.rebuild()

        if (ReactDOM.findDOMNode(this))
        {
            var heatmapMarkers = Array.prototype.slice.call(ReactDOM.findDOMNode(this).getElementsByClassName('heatmap-marker'))
            var heatmapMarkersDesc = heatmapMarkers.map (function (d) { return parseFloat(d.attributes['data-metatag-value'].value).toFixed(2) + "-" + d.offsetTop / d.offsetParent.scrollHeight}).join()
            if (this.state.heatmapMarkersDesc !== heatmapMarkersDesc)
            {
                var pHMValue = -1
                var pHMCoord = 0
                var pHMCoordIT = 0
                var pHMInd = 0
                var nhi = []
                var nhit = []
                for (var ind = 0; ind < heatmapMarkers.length; ind+=1)
                {
                    var d = heatmapMarkers[ind]
                    const val = parseFloat(d.attributes['data-metatag-value'].value).toFixed(2)
                    if ((pHMValue === val) && (pHMInd > 1) && (ind < heatmapMarkers.length - 1))
                        continue
                    
                    const coord = 100 * d.offsetTop / d.offsetParent.scrollHeight
                    const coordIT = (ind < heatmapMarkers.length - 1) ? d.offsetTop : (d.offsetTop + 20)
                
                    const col = 'rgba(' + (255 - Math.floor(169 * pHMValue)).toString() + ',' + (255 - Math.floor(80 * pHMValue)).toString() + ',' + (255 + Math.floor(0 * pHMValue)).toString() + ',' + (1 + 0.*pHMValue).toString() + ')'
                    
                    if (pHMInd > 0)
                    {
                        nhi.push (<rect key={"hmRect" + pHMInd} x="0" width="8" y={pHMCoord.toString()}  height={(coord-pHMCoord).toString() + "%"} style={{"fill": col}} />)
                        nhit.push (<div className="heatmapInTextRect" key={"hmRectInText" + pHMInd} style={{top:pHMCoordIT + "px", height:(coordIT-pHMCoordIT) + "px", background: col}}></div>)
                    }
                        
                    pHMValue = val
                    pHMCoord = coord
                    pHMCoordIT = coordIT
                    pHMInd += 1    
                }
                this.setState ({heatmapIntervals:nhi, heatmapIntervalsInText: nhit, heatmapMarkersDesc:heatmapMarkersDesc})
            }

            var highlights = Array.prototype.slice.call(ReactDOM.findDOMNode(this).getElementsByClassName('highlight-started'))
            var highlightsDesc = highlights.map (function (d) { return d.dataset.metatag + "-" + d.offsetTop / d.offsetParent.scrollHeight}).join()
            if (this.state.highlightsDesc !== highlightsDesc)
            {
                var highlightPositions = {}
                var highlightScrollMarkers = (highlights.length > 0) ?
                    highlights.map(function (d, ind) {
                        const coord = 100 * d.offsetTop / d.offsetParent.scrollHeight
                        var height = 100 * d.offsetHeight / d.offsetParent.scrollHeight
                        highlightPositions[d.dataset.metatag] = d.offsetTop
                        //return <line vectorEffect="non-scaling-stroke" key={"highlightScrollMarker" + ind} x1="0" y1={coord.toString()} x2="10" y2={coord.toString()}></line>
                        return <rect className="highlightScrollMarker" key={"highlightScrollMarker" + ind} x="10" y={coord.toString()} width="4" height={height.toString()}></rect>
                    })
                    : null

                var previousPosition = -1000
                var hlButtonHeight = 40
                var highlightButtons = this.props.loadedDoc ? this.props.loadedDoc.meta.highlights.map (function (hl) {
                    var pos = (highlightPositions && (hl.id in highlightPositions)) ? highlightPositions[hl.id] : -1000
                    if ((pos >= 0) && (previousPosition >= 0) && (pos - previousPosition < hlButtonHeight))
                        pos = previousPosition + hlButtonHeight
                    previousPosition = pos
                    return <div key={"hlBtn" + hl.id} className="highlightControlElement" style={{top:pos + "px"}} data-metatag={hl.id} data-tip={"highlight-id: " + hl.id} data-for="meta-tag-tooltip"
                            onMouseEnter={(e)=>{
                                var highlights = Array.prototype.slice.call(ReactDOM.findDOMNode(that).getElementsByClassName('meta-' + hl.id))
                                for (var i = 0; i < highlights.length; i+=1)
                                    highlights[i].style.opacity = 0.7
                            }}
                            onMouseLeave={(e)=>{
                                var highlights = Array.prototype.slice.call(ReactDOM.findDOMNode(that).getElementsByClassName('meta-' + hl.id))
                                for (var i = 0; i < highlights.length; i+=1)
                                    highlights[i].style.opacity = 1
                            }}
                        >
                            {(hl.comments && (hl.comments.length > 0)) ? <ImgComment /> : <ImgHighlight />}
                            <span>{hl.author} {(hl.comments && (hl.comments.length > 1)) ? (" + " + (hl.comments.length - 1))  : null} <span className="date">{hl.saved}</span></span>
                        </div>
                }) : null

                this.setState ({highlightsDesc:highlightsDesc,  highlightScrollMarkers: highlightScrollMarkers, highlightButtons: highlightButtons})
            }
            
            



            var mi = Array.prototype.slice.call(ReactDOM.findDOMNode(this).getElementsByClassName('must-include-started'))
            var mustIncludeDesc = mi.map (function (d) { return d.offsetTop / d.offsetParent.scrollHeight}).join()
            if (this.state.mustIncludeDesc !== mustIncludeDesc)
            {
                this.setState ({mustIncludeDesc:mustIncludeDesc,  mustIncludeScrollMarkers: (mi.length > 0) ?
                        mi.map(function (d, ind) {
                            const coord = 100 * d.offsetTop / d.offsetParent.scrollHeight
                            //return <line vectorEffect="non-scaling-stroke" key={"mustIncludeScrollMarker" + ind} x1="10" y1={coord.toString()} x2="20" y2={coord.toString()}></line>
                            var height = 100 * d.offsetHeight / d.offsetParent.scrollHeight
                            return <rect vectorEffect="non-scaling-stroke" className="mustIncludeScrollMarker" key={"mustIncludeScrollMarker" + ind} x="15" y={coord.toString()} width="4" height={height.toString()}></rect>
                        }) 
                        : null })
            }


            
            if (this.props.browsingDocAnchor)
            {
                var cEl = document.getElementById(this.props.browsingDocAnchor[0])
                if ((cEl !== undefined) && (cEl !== null))
                {
                    this.props.dispatch (setBrowsingDocAnchor (null))
                    this.scrollAreaRef.current.scrollTo (0, (cEl.offsetTop > 50) ? cEl.offsetTop-50 : 0)
                }
                
            }

        }
        else {
            if (this.state.heatmapMarkersDesc || this.state.mustIncludeDesc || this.state.highlightsDesc)
                this.setState ({heatmapIntervals:null, heatmapIntervalsInText: null, heatmapMarkersDesc:null, mustIncludeDesc:null,  mustIncludeScrollMarkers: null, highlightsDesc:null,  highlightScrollMarkers: null, highlightButtons: null})
        }
        
    }


    printDoc () {
        var pri = document.getElementById("contentstoprint");
        pri.innerHTML = this.popupAreaRef.current.innerHTML;
        window.print()
    }

    isDocSaved () {
        var savedDocsIds = []
        if (this.props.savedDocs)
            savedDocsIds = this.props.savedDocs.map(function (d) { return d.id })
        return savedDocsIds.includes(this.props.loadedDoc.id)
    }

    handleSelectedProject = projectId => {
        this.saveDocToProject (projectId, this.state.shouldRedirectToSelectedProject ? projectId : this.props.project.id, true)
        this.setState ({saveMenuOpen: false, projectSelectorVisible: false})
    }

    closeProjectSelector = e => {
        this.setState ({projectSelectorVisible: false})
    }

    saveDocToProject (projectId, showingProjectId, saveQuery) {
        this.props.dispatch(onChangeDocState(this.props.loadedDoc, true, projectId, this.props.query, { showingProjectId: showingProjectId, saveQuery: saveQuery }))
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName] < 2) && (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]])) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
        this.setState ({saveMenuOpen: false})
    }

    handleRemoveDoc = e => {
        if (window.confirm('Are you sure you wish to remove this document from saved? This will delete all the highlights, comments and annotations and cannot be undone')) 
            this.props.dispatch(onChangeDocState(this.props.loadedDoc, false, this.props.project.id, this.props.query, { showingProjectId: this.props.project.id }))
    };

    getSelectionBoundaryElement(isStart) {
        var range, sel, container;
        if (document.selection) {
            range = document.selection.createRange();
            range.collapse(isStart);
            return range.parentElement();
        } else {
            sel = window.getSelection();
            if (sel.getRangeAt) {
                if (sel.rangeCount > 0) {
                    range = sel.getRangeAt(0);
                }
            } else {
                // Old WebKit
                range = document.createRange();
                range.setStart(sel.anchorNode, sel.anchorOffset);
                range.setEnd(sel.focusNode, sel.focusOffset);
    
                // Handle the case when the selection was selected backwards (from the end to the start in the document)
                if (range.collapsed !== sel.isCollapsed) {
                    range.setStart(sel.focusNode, sel.focusOffset);
                    range.setEnd(sel.anchorNode, sel.anchorOffset);
                }
           }
    
            if (range) {
               container = range[isStart ? "startContainer" : "endContainer"];
    
               // Check if the container is a text node and return its parent if so
               return container.nodeType === 3 ? container.parentNode : container;
            }   
        }
    }

    handleSelectionSearch = e => {
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        if (text.length < 1) {
            alert ('Please select some text to use it for your search request');
            return;
        }

        this.setState ({popoverVisible:false})

        var shouldRedirectToMyProject = false
        if (!this.props.project) 
        {
            this.props.dispatch (getProjectParams("my", false, false, true))
            //history.push("/project/my");
            shouldRedirectToMyProject = true
            
        }

        this.props.dispatch (setTranslationState(0)); 
        this.props.dispatch (setQuery({query:text.replace(/["“”]+/g, '')}))
        this.props.dispatch (onShowQueryForm(true))
        
        
        if (window.getSelection) {
            if (window.getSelection().empty) // Chrome
                window.getSelection().empty();
            else if (window.getSelection().removeAllRanges) // Firefox
                window.getSelection().removeAllRanges();
        } 
        else if (document.selection) // IE?
            document.selection.empty();

        if (shouldRedirectToMyProject)
            this.setState ({shouldRedirectToMyProject:true})

        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===7)) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
    };

    handleSelectionCopy = e => {
        document.execCommand('copy');
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===9)) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
    };

    handleSelectionSave = e => {
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        if (text.length < 1) {
            alert ('Please select the text to highlight');
            return;
        }

        this.setState ({popoverVisible:false})

        
        var withSave = 1
        if ((this.props.loadedDoc) && (this.isDocSaved()))
            withSave = 0
        
        this.props.dispatch (saveDocHighlight (text, this.props.loadedDoc, this.props.project.id, withSave, this.selectedTextPosition(), this.props.query))
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===10)) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
    };

    handleDocSearch = e => {
        this.props.dispatch (setQuery({query:this.props.loadedDoc.text.replace(/["“”]+/g, '')}))
        this.props.dispatch (onShowQueryForm(true))
    };

    handleShowCommentDialog = e => {
        this.setState ({popoverVisible:false})
        e.stopPropagation();
        DocViewerEventFactory(this.popupAreaRef.current).createAddCommentEvent();
        if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===11)) 
            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))
    }

    onGeneralClick = e => {
        ReactTooltip.hide()
    };


    getPrevCurrentNext () {
        var prev = null
        var next = null
        var currentIndex = -1
        var links = this.props.refsCollection
        for (var i = 0; i < links.length; i++)
        {
            if (links[i][0] === this.props.loadedDoc.ref)
            {
                if (i > 0)
                    prev = links[i - 1][0]
                if (i + 1 < links.length)
                    next = links[i + 1][0]
                currentIndex = i + 1
                break
            }
        }
        return [prev, currentIndex, next]
    }

    navigateList (toNext) {
        var [prev, currentIndex, next] = this.getPrevCurrentNext()
        if (currentIndex > 0)
        {
            if (toNext)
                this.props.dispatch (setBrowsingDoc(next, null, this.props.refsCollection))
            else
                this.props.dispatch (setBrowsingDoc(prev, null, this.props.refsCollection))
        }

    }

    selectedTextPosition () {
        if (window.getSelection() && (window.getSelection().rangeCount > 0) && window.getSelection().getRangeAt(0) && window.getSelection().anchorNode)
        {
            var pText = window.getSelection().anchorNode.textContent.substring(0,window.getSelection().getRangeAt(0).startOffset)
            var prevNode = window.getSelection().anchorNode.previousSibling
            while (prevNode)
            {
                pText = prevNode.textContent + pText
                prevNode = prevNode.previousSibling
            }

            return pText.replace(/(\r\n|\n|\r| )/gm, "").length
        }
        return 0
    }

    
    render() {
        var that = this
        if (this.state.shouldRedirectToMyProject)
            return <Redirect to={"/project/my"} />
        this.selectedTextPosition ()
        if (this.props.loadedDoc != null) {
            var [prev, currentIndex, next] = this.getPrevCurrentNext()

            var isMac = window.navigator.platform.toLowerCase().includes('mac')

            this.props.loadedDoc.similarDocs.sort((a,b) => (a[1] < b[1]) ? -1 : 1);
            var versions = this.props.loadedDoc.similarDocs.map(function (dd) {
                    return <div className={(dd[0] === that.props.loadedDoc.ref) ? "docVersion open" : "docVersion"} key={"docr" + dd[0]} onClick={(e) => {e.stopPropagation(); that.props.dispatch (setBrowsingDoc(dd[0], null, that.props.refsCollection)); that.setState({versionsVisible:false}) }} >
                        <span>{dd[1]}</span>  
                        </div>
            })
            var caseVersions = this.props.loadedDoc.sameCaseDocs.map(function (dd) {
                return <div className={(dd[0] === that.props.loadedDoc.ref) ? "docVersion open" : "docVersion"} key={"docr" + dd[0]} onClick={(e) => {e.stopPropagation(); that.props.dispatch (setBrowsingDoc(dd[0], null, that.props.refsCollection)); that.setState({caseVisible:false}) }} >
                    <span>{dd[1]}</span>  
                    </div>
            })

            var selectionBoundaryElement = this.getSelectionBoundaryElement(true)
            var selectionBoundaryIsInText = false
            if (selectionBoundaryElement && (typeof(selectionBoundaryElement.className) === 'string') && (selectionBoundaryElement.className.split(' ').filter(value => ['contents', 'parH4', 'meta-tag', 'highlight', 'must-include'].includes(value)).length > 0))
                selectionBoundaryIsInText = true

            var saveMenu = <div>
                <Row className="entry">
                    <Col md={12}>
                        <div className="caption"  onClick={(e) => {e.stopPropagation(); this.setState ({projectSelectorVisible:true, shouldRedirectToSelectedProject:true});}}>
                            <h4>Save to a different project and work from there</h4>
                        </div>
                    </Col>
                </Row>
                <Row className="entry">
                    <Col md={12}>
                        <div className="caption"  onClick={(e) => {e.stopPropagation(); this.setState ({projectSelectorVisible:true, shouldRedirectToSelectedProject:false});}}>
                            <h4>Save to a different project and stay working here</h4>
                        </div>
                    </Col>
                </Row>
                {this.isDocSaved() ? null :
                    <Row className="entry tmp" onClick={(e) => {e.stopPropagation(); this.saveDocToProject (this.props.project.id, this.props.project.id, false);}}>
                        <Col md={12}>
                            <div className="caption">
                                <h4>Save to {(this.props.project && this.props.project.isPersonal) ? "my folder":"this project"} and stay working here</h4>
                            </div>
                        </Col>
                    </Row>
                }
            </div>

            var closeDocs = (this.props.loadedDoc.egoNet && (this.props.loadedDoc.egoNet.refs.length > 0) ) ? 
                <div className="closeDocs">
                    <h4>Closest documents: <span className="qm">?<span className="withShadow">Here you can see the closest documents in the database, based on their textual contents</span></span></h4>
                    {
                        this.props.loadedDoc.egoNet.refs.map (function (cref) {
                            if (cref === that.props.loadedDoc.ref)
                                return null
                            return <div key={"closeDoc" + cref} className="closeDoc" onClick={(e) => { that.props.dispatch (setBrowsingDoc(cref, that.props.loadedDoc.egoNet.cards[cref].language, [])); }}>{that.props.loadedDoc.egoNet.cards[cref].caption}</div>
                        })
                    }
                </div>:null

            
            return (<GlobalHotKeys  keyMap={this.hotKeysMap} handlers={this.hotKeysHandlers}>
                        <div ref="docViewer" id="docViewer" className="docViewer" onMouseUp={(e) => { this.checkPopoverVisibility()}} onMouseDown={(e) => { this.checkPopoverVisibility()}}  onClick={(e) => { e.stopPropagation();}}> 
                        { (this.constructorName in this.props.interface.tutorialActiveElements) ?
                            <Joyride
                                tooltipComponent={JoyrideTooltip}
                            steps={globalConfig.JOYRIDE.tutorialSteps[this.constructorName]} 
                                styles={{options: Object.assign({}, globalConfig.JOYRIDE.options, { zIndex:100000 })}}
                                disableCloseOnEsc={true}
                                disableOverlayClose={true}
                                disableOverlay={true}
                                disableScrolling={true}
                                showSkipButton={true}
                                hideBackButton={true}
                                run={(globalConfig.JOYRIDE.tutorialSteps[this.constructorName] && this.props.interface.tutorialActiveElements[this.constructorName] >= 0)}
                                locale={{ back: 'Back', close: 'Got it', last: 'Last', next: 'Got it', skip: 'Hide these tips' }}
                                continuous={false}
                                stepIndex = {this.props.interface.tutorialActiveElements[this.constructorName]}
                                callback = { data => {
                                        const { action, type } = data;
                                        if (action === ACTIONS.SKIP)
                                            this.props.dispatch (setTutorialElements(null))
                                        if (([EVENTS.STEP_AFTER].includes(type)) && (action === ACTIONS.CLOSE))
                                        {
                                            //if (this.props.interface.tutorialActiveElements[this.constructorName] === 0)
                                            //    this.props.dispatch (changeQuery (globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].param))
                                            var cEl = this.props.interface.tutorialActiveElements[this.constructorName]
                                            if (this.props.project && this.props.project.isPersonal && (cEl === 0))
                                                cEl = 1 
                                            this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][cEl].nextStepRule))
                                        }
                                    
                                        
                                        
                                    }
                                }
                            />
                            :null
                        }
                            <div className="subheader">
                                <Container fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div className="alignLeft">
                                                {this.props.topButtons.save ? 
                                                    <span>
                                                        { this.isDocSaved() ?
                                                            <button key="save" className="text saveDeleteBtn" onClick={this.handleRemoveDoc}><DeleteIcon className="iconBtn"/> Remove</button>:
                                                            (
                                                                (this.props.project && this.props.project.isPersonal) ? 
                                                                <Dropdown drop={"down"} className="filterDropdown">
                                                                    <Dropdown.Toggle variant="light" className="saveDeleteBtn text noArrow">
                                                                        <AddDocIcon className="iconBtn"/> Save
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu alignRight="true" flip={false} className="optionsMenu withShadow saveMenu">
                                                                        {saveMenu}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                : 
                                                                <button key="save" className="text saveDeleteBtn" onClick={(e)=>{this.saveDocToProject (this.props.project.id, this.props.project.id, false)}}><AddDocIcon className="iconBtn"/> Save</button>
            
                                                            )
                                                            
                                                        } 
                                                        {this.props.project ?
                                                        ((!this.props.project.isPersonal || this.isDocSaved()) ? 
                                                            <Dropdown drop={"down"} className="filterDropdown">
                                                                <Dropdown.Toggle variant="light" className="saveDeleteBtn saveAsBtn text noArrow">
                                                                    <DotsIcon className="iconBtn" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu alignRight="true" flip={false} className="optionsMenu withShadow saveMenu">
                                                                    {saveMenu}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            :null) : null
                                                        }
                                                         
                                                        
                                                    </span>
                                                    : null }
                                                    
                                                    
                                                {this.props.topButtons.print ? <button key="print" className="text" onClick={() => {this.printDoc ()}}><PrintIcon className="iconBtn" alt="print doc" /> Print</button> : null }
                                                {this.props.topButtons.searchWith ? <button key="searchWithDoc" className="text" onClick={this.handleDocSearch}><SearchIcon className="iconBtn" alt="search with doc" /> Search with this document</button> : null}
                                            </div>
                                            <div className="alignRight">
                                                {currentIndex > 0 ? <span className="docCounter">{currentIndex} <span>of {this.props.refsCollection.length}</span></span>:null}
                                                <span>
                                                    <button key="prev" className={prev !== null ? "noText pnButton":"noText pnButton disabled"} disabled={(prev === null)} onClick={() => {this.props.dispatch (setBrowsingDoc(prev, null, this.props.refsCollection)  ); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===5)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}><TriangleIcon/></button>
                                                    <button key="next" className={next !== null ? "noText pnButton":"noText pnButton disabled"} disabled={(next === null)} onClick={() => {this.props.dispatch (setBrowsingDoc(next, null, this.props.refsCollection)  ); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===5)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}><TriangleIcon className="flipH"/></button>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Container fluid className="contentsContainer" id="docViewerContents">
                                <Row >
                                    <Col md={12}>
                                        <Scrollbar ref={this.scrollAreaRef} noScrollX={true}
                                                    trackYProps={{
                                                        renderer: props => {
                                                        const { elementRef, ...restProps } = props;
                                                        return <div className="trackYContainer">
                                                            <div className="eScrollContainer">
                                                                <svg className="eScroll"  viewBox="0 0 20 100" preserveAspectRatio="none">
                                                                    <g className="heatmap">{this.state.heatmapIntervals}</g>
                                                                    <g className="highlightMarkers">{this.state.highlightScrollMarkers}</g>
                                                                    <g className="mustIncludeMarkers">{this.state.mustIncludeScrollMarkers}</g>
                                                                </svg>
                                                            </div>
                                                            <span {...restProps} ref={elementRef} className="trackY" style={{ "background":"none"}}></span>
                                                        </div>;  
                                                    }}}>
                                            <div ref={this.popupAreaRef} className={"textContent stdTopPadded " + ((this.state.highlightButtons && (this.state.highlightButtons.length > 0)) ? " withHighlightButtons":"")}>
                                                {localConfig.DOCUMENTS_COPYRIGHT_BANNER ? <div className="disclaimerBanner">{localConfig.DOCUMENTS_COPYRIGHT_BANNER}</div> : null}
                                                <h4 className="projectTitle">{this.props.loadedDoc.caption}</h4>
                                                <div className="idCard">
                                                    <Row className="fields">
                                                        <Col md={12}>
                                                            {this.props.loadedDoc.link ? 
                                                                (this.props.loadedDoc.link.includes ("://") ?  
                                                                    <span className="value"><a target="_blank" className="button lightBtn lowPadding srcBtn" rel="noopener noreferrer" href={ this.props.loadedDoc.link} onClick={e=>{if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===2)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}>{this.props.loadedDoc.source.name}<LinkIcon/></a></span> 
                                                                        : 
                                                                    <span className="value"><button className="button lightBtn lowPadding srcBtn" onClick={e => {this.props.dispatch(getSrcDoc(this.props.loadedDoc.ref)); if ((this.constructorName in this.props.interface.tutorialActiveElements) && (this.props.interface.tutorialActiveElements[this.constructorName]===2)) {this.props.dispatch (setTutorialElements(globalConfig.JOYRIDE.tutorialSteps[this.constructorName][this.props.interface.tutorialActiveElements[this.constructorName]].nextStepRule))}}}>Download<LinkIcon/></button></span>) 
                                                                : null}
                                                            {(this.props.loadedDoc.similarDocs.length > 1) ? <span className="value"><button className="button lightBtn  lowPadding srcBtn" onClick={e=>{e.stopPropagation();this.setState({versionsVisible:true})}}>Similar</button></span>: null}
                                                            {(this.props.loadedDoc.sameCaseDocs.length > 1) ? 
                                                                <span className="value"><button className="button lightBtn  lowPadding srcBtn" onClick={e=>{e.stopPropagation();this.setState({caseVisible:true})}}>Case</button></span>
                                                                : (this.props.loadedDoc.caseURL ? 
                                                                    <span className="value"><a target="_blank" className="button lightBtn lowPadding srcBtn" rel="noopener noreferrer" href={ this.props.loadedDoc.caseURL} >Case<LinkIcon/></a></span> :null )
                                                            }
                                                            <span className="value sep">·</span>
                                                            <span className="value">{this.props.loadedDoc.language}</span>
                                                            {this.props.loadedDoc.dateString && this.props.loadedDoc.dateString.length > 1 ? <span className="value">{this.props.loadedDoc.dateString}</span> : null}
                                                            
                                                        </Col>                                                            
                                                    </Row>
                                                    
                                                </div>
                                                {(this.props.loadedDoc.summary) && (this.props.loadedDoc.summary.length > 3) ? <div><p className="description">{this.props.loadedDoc.summary}</p></div> : null}
                                                {this.props.project ? <DocAnnotation containerRef={this.popupAreaRef} projectId={this.props.project.id} /> : null}
                                                {/*<div className="contents" onClick={this.onGeneralClick} dangerouslySetInnerHTML={{ __html: metaRenderer.render(this.props.loadedDoc.html, this.props.loadedDoc.meta, null, true) }} />*/}
                                                <div className="heatMapInText">
                                                    {this.state.heatmapIntervalsInText}
                                                </div>
                                                <div className="highlightControlsContainer">{this.state.highlightButtons}</div>
                                                <DocContentRenderer html={this.props.loadedDoc.html} meta={this.props.loadedDoc.meta} addDefaultTooltips={true} containerRef={this.popupAreaRef} click={this.onGeneralClick} />
                                                <Popover className="popover" isOpen={this.state.popoverVisible} onTextSelect={() => {this.setState({ popoverVisible: true })}} onTextUnselect={() => this.setState({ popoverVisible: false })} placementStrategy={placeRightBelow} selectionRef={this.popupAreaRef} scrollRef={this.popupAreaRef}>
                                                    <div className="quickEditPopup withShadow">
                                                        <button className="searchWithBtn" onClick={this.handleSelectionSearch}><SearchIcon/>Search<span className="kbHint">{isMac ? "Command":"Ctrl"}+e</span></button>
                                                        <button className="copyBtn" onClick={this.handleSelectionCopy}><CopyIcon/>Copy<span className="kbHint">{isMac ? "Command":"Ctrl"}+c</span></button>
                                                        {this.props.project && this.props.project.id && selectionBoundaryIsInText ? <button className="highlightBtn" onClick={this.handleSelectionSave}><HighlightIcon/>Highlight<span className="kbHint">{isMac ? "Command":"Ctrl"}+h</span></button>:null}
                                                        {this.props.project && this.props.project.id && selectionBoundaryIsInText ? <button className="commentBtn" onClick={this.handleShowCommentDialog}><ChatIcon/>Comment<span className="kbHint">{isMac ? "Command":"Ctrl"}+m</span></button>:null}
                                                    </div>
                                                </Popover>
                                                {closeDocs}
                                            </div>
                                            
                                        </Scrollbar>
                                    </Col>
                                </Row>
                            </Container>

                            {this.state.versionsVisible ? 
                                <div className="versionsContainer" onClick={e=>{e.stopPropagation(); that.setState({versionsVisible:false})}}>
                                    <div className="scrollContainer"  onClick={e=>{e.stopPropagation();}}>
                                        <Scrollbar noScrollX={true}>
                                            {versions}
                                        </Scrollbar>
                                    </div>
                                </div>
                                : null}

                            {this.state.caseVisible ? 
                                <div className="versionsContainer" onClick={e=>{e.stopPropagation(); that.setState({caseVisible:false})}}>
                                    <div className="scrollContainer" onClick={e=>{e.stopPropagation();}}>
                                        <Scrollbar noScrollX={true}>
                                            {caseVersions}
                                            <span className="value"><a target="_blank" className="button lightBtn lowPadding srcBtn" rel="noopener noreferrer" href={ this.props.loadedDoc.caseURL} ><LinkIcon/>Case</a></span> :null )
                                        </Scrollbar>
                                    </div>
                                </div>
                                : null}

                            <HighlightMenu containerRef={this.popupAreaRef} />
                            
                            {this.props.project ? <DocCommentDialog containerRef={this.popupAreaRef}
                                            projectId={this.props.project.id}
                                            placementStrategy={placeRightBelow}
                                            selectionRef={this.popupAreaRef}
                                            scrollRef={this.popupAreaRef}/> : null}
                            {this.props.project ? <DocComments containerRef={this.popupAreaRef} /> : null}
                            <div className="spinner">
                                <LoadingSpinner show={this.props.isLoading} />
                            </div>
                            <GHelper hParams={{'DocViewer':0, 'QueryForm':-1}}/>
                        </div>
                        {this.state.saveMenuOpen ? 
                            <div className="saveMenuContainer" onClick={(e) => {e.stopPropagation(); this.setState ({saveMenuOpen:false})}}>
                                
                            </div>:null
                        }
                        {this.state.projectSelectorVisible ? <ProjectSelector popupMode={true} captions={{caption:this.state.shouldRedirectToSelectedProject ? "Add to and continue to work in a project":"Add to a project and continue in the current one", docName:this.props.loadedDoc.caption}} projectSelectedRoutine={this.handleSelectedProject.bind(this)} closeRoutine={this.closeProjectSelector.bind(this)} />:null}
                        
                    </GlobalHotKeys>
                );
        }
        else {
            return (
                    <div ref="docViewer" className="docViewer"> 
                        <div className="spinner">
                            <LoadingSpinner show={this.props.isLoading} />
                        </div>
                    </div>
                );
        }
    }

}

function mapStateToProps(state) {
  return {
    loadedDoc: state.projects.loadedDoc,
    browsingDocId: state.projects.browsingDocId,
    browsingDocAnchor: state.projects.browsingDocAnchor,
    browsingDocLang: state.projects.browsingDocLang,
    savedDocs: state.projects.savedDocs,
    isLoading: state.globalInterface.docIsLoading,
    query: state.query,
    refsCollection: state.projects.refsCollection,
    interface: state.globalInterface,
    username: state.authParams.username,
    project: state.projects.currentProject

  }
}

export default connect(mapStateToProps)(DocViewer)

