// react
import React, {Component} from "react";
import ReactDOM from "react-dom";

// styles
import '../css/DocComments.scss';
import '../css/DocAnnotation.scss';

// redux
import {connect} from "react-redux";

// images
import { ReactComponent as DotsImg} from "../img/ic_3dots.svg";
import { ReactComponent as EditImg} from '../img/ic_edit.svg'
import { ReactComponent as DeleteImg} from "../img/ic_delete.svg";
import { ReactComponent as ImgEye} from "../img/ic_eye-active.svg";

import { ReactComponent as ImgFlag} from "../img/ic_card-flag.svg";

// app stuff
import {DocViewerListenerFactory} from "../utils/DocViewerEventFactory";
import DocCommentForm from "./DocCommentForm";
import Avatar from "react-avatar";
import Moment from "react-moment";
import {Dropdown} from "react-bootstrap";
import {removeDocHighlight, onChangeDocReadState, removeComment, setCurrentDocRead, setModifiedCommentId, setModifiedHighlightId} from "../redux/actionsProject";
import StringUtils from "../utils/StringUtils";

import localConfig from '../config/config'

class DocAnnotation extends Component {

    colors = ["green", "yellow", "red"];

    state = {
        container: null,

        open: false,
        isEditMode: false,
        color: null,
        text: '',

        annotation: null,
        commentId: null,
        parentCommentId: null,
    }

    componentDidMount() {
        let container = ReactDOM.findDOMNode(this).parentNode

        let listenerFactory = DocViewerListenerFactory(container);
        listenerFactory.createCloseCommentDialogListener((e) => {
            this.closeForm(e);
        })

        this.setState({
            container: container
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let annotation = null;

        if (nextProps.loadedDoc != null) {
            this.setState({
                open: false,
                isEditMode: false,
                color: null,
                text: ''
            })
        }

        // todo: REMOVE this block in case we keep showing annotations on doc load without need to press buttons
        // annotation has been created
        if ((nextProps.modifiedHighlightId !== undefined) && (nextProps.modifiedHighlightId !== null)) {
            annotation = this.getAnnotationById(nextProps.modifiedHighlightId);
        }
        // annotation has been updated
        else if ((nextProps.modifiedCommentId !== undefined) && (nextProps.modifiedCommentId !== null)) {
            annotation = this.getAnnotationByCommentId(nextProps.modifiedCommentId);
        }

        if (annotation != null) {
            this.setState({
                annotation: annotation,
                modifiedCommentId: null,
                modifiedHighlightId: null
            })
            this.props.dispatch (setModifiedCommentId(null))
            this.props.dispatch (setModifiedHighlightId(null))
        }
        // END of block to remove
    }

    getAnnotations() {
        let annotations = this.props.loadedDoc.meta.annotations;
        return annotations !== undefined ? annotations : [];
    }

    getAnnotationByColor(color) {
        let found = this.getAnnotations().filter(a => a.color === color);
        return found.length > 0 ? found[0] : null;
    }

    getAnnotationById(id) {
        let found = this.getAnnotations().filter(a => a.id === id);
        return found.length > 0 ? found[0] : null;
    }

    getAnnotationByCommentId(id) {
        let found = this.getAnnotations().filter(a => a.comments[0].id === id);
        return found.length > 0 ? found[0] : null;
    }

    getCommentById(annotation, commentId) {
        let found = annotation.comments.filter(a => a.id === commentId);
        return found.length > 0 ? found[0] : null;
    }



    closeForm(e) {
        this.setState({
            isEditMode: false,
            open: false
        })
    }

    handlePickColor = e => {
        let el = e.target || e.relatedTarget || e.currentTarget;
        el = el.closest("button");
        let color = el.className.split(" ")[0];

        if (this.state.open && this.state.color === color) {
            this.setState({
                open: false
            })
            return;
        }

        let a = this.getAnnotationByColor(color)

        this.setState({
            open: true,
            color: color,
            annotation: a,
            isEditMode: a == null
        })
    }

    addAnnotationComment(annotationId, parentCommentId, e) {
        let annotation = this.getAnnotationById(annotationId);
        this.setState({
            isEditMode: true,
            open: true,
            color: annotation.color,
            annotation,
            commentId: null,
            parentCommentId
        })
    }

    isShowAddComment() {
        return this.state.isEditMode && this.state.open && this.state.annotation != null && this.state.commentId == null && this.state.parentCommentId != null;
    }

    editAnnotation(annotationId, commentId) {
        let annotation = this.getAnnotationById(annotationId);
        this.setState({
            isEditMode: true,
            open: true,
            color: annotation.color,
            annotation,
            commentId: commentId,
            parentCommentId: null
        })
    }

    isShowEditComment() {
        return this.state.isEditMode && this.state.open && this.state.annotation != null && this.state.commentId != null && this.state.parentCommentId == null;
    }

    deleteAnnotation(annotationId, commentId) {
        this.props.dispatch(removeComment(commentId, this.props.query))

        let annotation = this.getAnnotationById(annotationId);
        if (annotation.comments.length <= 1) {
            this.props.dispatch(removeDocHighlight(annotationId, this.props.project.id,this.props.query))
            annotation = null;
        }

        this.setState({
            isEditMode: false,
            annotation: annotation,
            color: null,
            open: false
        })
    }

    handleChangeRead = e => {
        this.props.dispatch(onChangeDocReadState(this.props.loadedDoc, !this.props.loadedDoc.read, this.props.projectId))
        this.props.dispatch(setCurrentDocRead(!this.props.loadedDoc.read))
    }

    renderDropdown(annotation, comment) {
        return (
            <Dropdown drop={"down"}>
                <Dropdown.Toggle variant="light" className="">
                    <DotsImg className="dots-alone"/>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight="true" flip={false}>
                    <Dropdown.Item onClick={(e) => {this.addAnnotationComment(annotation.id, comment.id, e)}}><EditImg/> Add comment </Dropdown.Item>
                    {(this.props.username === comment.author) ? <Dropdown.Item onClick={() => {this.editAnnotation(annotation.id, comment.id)}}><EditImg/> Edit </Dropdown.Item> : null}
                    {(this.props.username === comment.author) ? <Dropdown.Item onClick={() => {this.deleteAnnotation(annotation.id, comment.id)}}><DeleteImg/> Delete </Dropdown.Item> : null}
                </Dropdown.Menu>
            </Dropdown>
        )
        
    }

    renderAnnotationComment(annotation, comment) {
        let textContainerClass = "annotation-text-container color-" + annotation.color;
        return (
            <div key={"annotation-" + annotation.id} className={textContainerClass}>
                <div className="annotation-header">
                    <div className="avatar">
                        <Avatar name={comment.author.split('@')[0].split('.').join(' ')} size={38} round={true}/>
                    </div>
                    <div className="name-and-date">
                        <div className="name">{comment.author}</div>
                        <div className="date">
                            <Moment format="DD MMMM YYYY HH:mm">
                                {comment.saved}
                            </Moment>
                        </div>
                    </div>

                    <div className="menu">
                        {this.renderDropdown(annotation, comment)}
                    </div>
                </div>
                <div className="annotation-text">
                    {comment.text}
                </div>
            </div>
        );
    }

    renderAnnotations() {
        /*let textContainerClass = "annotation-text-container color-" + this.state.color;
        if (this.state.isEditMode) {
            textContainerClass += " d-none";
        }
        */
        return (
            <div>
                {this.colors.map((color) => {
                    let annotation = this.getAnnotationByColor(color);
                    if (annotation != null && annotation.comments != null && annotation.comments.length > 0) {
                        return (
                            annotation.comments.map((comment, i) => {
                                let res = [];
                                if (this.isShowEditComment() && this.state.commentId === comment.id) {
                                    // add comment edit form to render
                                    res.push(this.renderForm(annotation.id, comment, comment.text));
                                }
                                else {
                                    // add comment show block to render
                                    res.push(this.renderAnnotationComment(annotation, comment));

                                }
                                // add comment add form to render if we are at the appropriate state
                                if (this.isShowAddComment() && this.state.parentCommentId === comment.id) {
                                    res.push(this.renderForm(annotation.id, null, ''));
                                }
                                return res;
                            })
                        );
                    }
                    else {
                        return ('');
                    }
                })}
            </div>
        );
    }


    isActive(color) {
        return this.state.color === color;
    }

    getButtonStateClass(color) {
        return this.isActive(color) ? " active" : "";
    }

    
    renderForm(highlightId, comment, text) {
        return (
            <div className={"annotation-form color-" + this.state.color}>
                <DocCommentForm hasCancel={true} containerRef={this.state.container} highlightId={highlightId}
                                projectId={this.props.projectId} selectionText={null}
                                color={this.state.color} isAnnotation={true} isVisible={this.state.isEditMode && this.state.open} text={text} comment={comment} />
            </div>
        );
    }

    render() {
        let text = this.state.text;
        let comment = null;
        let highlightId = null;

        if (this.state.annotation != null) {
            highlightId = this.state.annotation.id;
            if (this.state.commentId != null) {
                comment = this.getCommentById(this.state.annotation, this.state.commentId);
                if (comment)
                    text = comment.text;
            }
            /*else {
                comment = this.state.annotation.comments[0];
            }
            text = comment.text;*/
        }

        let hasViewed = this.props.loadedDoc.read

        // if you'd like to hide button for existing annotations then uncomment next line and use buttonsToShow instead of this.colors
        // let buttonsToShow = this.colors.filter((color) => this.getAnnotationByColor(color) === null);

        return (
            <div className="doc-annotation">
                <div className="annotation-buttons">
                    {localConfig.VIEWED_ENABLED ?
                        <button onClick={this.handleChangeRead}  className={"grey" + (hasViewed ? " viewed" : "") + this.getButtonStateClass("grey")}>
                            <span className="viewed">
                                <ImgEye/>
                                Viewed</span>
                            <span className="not-viewed">
                                <ImgEye/>
                                Not viewed</span>
                        </button>
                        :
                        null
                    }


                    {this.colors.map((color) => {
                        return (
                            <button key={"btn"+color} onClick={this.handlePickColor} className={color + this.getButtonStateClass(color)} disabled={this.getAnnotationByColor(color) !== null}>
                                <ImgFlag />
                                {StringUtils.capitalize(color)}</button>
                        );
                    })}
                </div>

                {highlightId == null && comment == null ? this.renderForm(highlightId, comment, text) : ''}

                {this.renderAnnotations()}
            </div>
        )
    }
}




function mapStateToProps(state) {
    return {
        username: state.authParams.username,
        loadedDoc: state.projects.loadedDoc,
        savedDocs: state.projects.savedDocs,
        modifiedCommentId: state.projects.modifiedCommentId,
        modifiedHighlightId: state.projects.modifiedHighlightId,
        query: state.query,
        project: state.projects.currentProject
    }
}

export default connect(mapStateToProps)(DocAnnotation);
